export default {
    "Revert": "Ongedaan",
    "Retry": "Opnieuw",
    "Face crop":"Verwijder achtergrond",
    "Remove background":"Remove background",
    "Loading":"Loading",
    "Background not recognized":"Background not recognized",
    "Background already removed":"Background already removed",
    "settings": "Instellingen",
    "gridlines": "Richtlijnen",
    "layers": "Lagen",
    "image": "Foto",
    "Text": "Tekst",
    "shape": "Elementen",
    "emoji": "Emoji",
    "done": "Klaar",
    "view": "Voorkant",
    "Custom Text": "Tekst",
    "Close": "Sluiten",
    "Position": "Positie",
    "Rotate": "Draai",
    "Change Image": "Afbeelding wijzigen",
    "Crop Image": "Bijsnijden",
    "Upload Images": "Afbeeldingen uploaden",
    "Add Text": "Voeg tekst toe",
    "Cancel": "Annuleren",
    "Start creating your masterpiece by browsing our new content in the panels on the left": "Klik op lets in het design om het aan te passen",
    "Shapes": "Configureerbare vormen",
    "Add a line": "Voeg lijn toe",
    "Add a rectangle": "Voeg rechthoek toe",
    "Add a circle": "Voeg Ovaal toe",
    "Add a triangle": "Voeg driehoek toe",
    "Color": "Kleur",
    "Edit Text": "Tekst wijzigen",
    "Font type": "Font",
    "Font Style": "Style",
    "Size": "Grootte",
    "Justification": "Uitlijning",
    "Letter spacing": "Letterafstand",
    "Flip": "Omdraaien",
    "Save" : "Opslaan",
    "Preview": "Preview",
    "Editor": "Editor",
    "Uploads": "Uploads",
    "Letter limit": "Maximum aantal karakters",
    "Recently Used Colors": "Recentelijk gebruikte kleuren",
    "You placed an element outside the printable area. Please confirm, that you understood that this will not be completely or correctly printed" : "Du har placeret et objekt uden for det printbare område. Bekræft venligst, at du er indforstået med, at det kan printes delvist eller ukorrekt.",
    "There was error during getting files!": "Er liep iets fout bij het uploaden van je afbeeldingen. Gelieve de pagina te vernieuwen en het nogmaals te proberen.",
    "Your Image is too big to upload! The max file size is 8 MB": "Je afbeelding is te groot om te uploaden! De maximale bestandsgrootte is 8 MB.",
    "Your image is too small": "Je afbeelding is te klein om geprint te worden in hoge kwaliteit. Gelieve een andere te selecteren.",
    "Reset changes": "Reset",
	"Stroke": "omtrek",
	"Stroke color": "omtrek kleur",
	"Delete": "Verwijderen",
    "BackgroundsTools": "Achtergrond",
    "ColorsTools": "Kleuren",
    "ImagesTools": "Afbeeldingen",
    "Shadow color": "Schaduw kleur",
    "Shadow": "Schaduw",
    "Information before ordering": "Kijk alles alsjeblieft nog een keer heel goed na. Het wordt afgedrukt zoals getoond in deze preview. Iets nog onduidelijk? Ga dan naar onze FAQ voor meer informatie en uitleg.",
    "Add to cart": "In winkelwagentje",
    "More": "Meer",
    "edit Image": "Aanpassen",
    "Add_Image": "Extra foto",
    "Cut": "Bijsnijden",
    "Change": "Upload een foto",
    "Drag and drop your images here!": "Drag en drop je foto's hier",
    "Edit": "Tekst wijzigen",
    "Font": "Font",
    "Font Size": "Grootte",
    "Letter Space": "Spatiëring",
    "Text Color": "Kleur",
    "New text": "Voeg tekst toe",
    "Align": "Uitlijning",
    "Flip X": "Omdraaien",
    "Oops, didn't you forget something?": "Whoeps, ben je niet iets vergeten?Het lijkt erop dat je niet alles hebt aangepast en gepersonaliseerd. Kijk daarom het product na en vervang de standaard afbeeldingen (tenzij je ze mooi vindt natuurlijk!).",
    "Use original image": "Gebruik origineel beeld",
    "Continue": "Ga verder",
    "Wird freigestellt": "Verwijder achtergrond...",
}






