import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import stl from "./FaceCropPopup.module.scss"

import close_btn from '../../assets/images/iconfinder_Close_6351931 2.png'
import back_btn from '../../assets/images/expand-more.png'

import {Button} from "shards-react";

import Translate from "react-translate-component";
import Text from "../Tools/ToolsViews/Text";


class BackgroundChangePopup extends Component {
    state = {

    };


    componentDidMount() {
        this.generatePreview();

    }

    generatePreview() {
        if (this.props.stage) {
            console.log(this.props.isItemDownloading);
            if (this.props.isItemDownloading > 0) {
                setTimeout(() => {
                    this.generatePreview()
                }, 1000);
            } else {
                let stage = this.props.stage.clone();
                this.props.generatePreview({
                    pages: this.props.pages,
                    activePage: this.props.activePage,
                    stage: stage,
                    previewUrl: this.props.previewUrl
                });
                this.props.setPreviewUpdateStatus({previewShouldBeChanged: false})
            }
        }
    }


    render() {

        const {previews: previewsProps} = this.props;
        let previews = previewsProps.map((item) => {
            return (
                <img key={item.name} src={item.image} alt={''}/>
            )
        });

        return (
            <div className="text-form-popup-content">
                <div className="text-form-popup-overlay"
                     onClick={this.props.hidePopup}/>
                <div
                    className={'text-form-popup-content-inner'}>
                    <div className="text-form-popup-content-title__wrap">
                        <div className="text-form-popup-back"
                             onClick={() => {
                                 this.props.hidePopup();
                                 this.props.showFaceCropPopup();
                                 this.props.setPreviewUpdateStatus({previewShouldBeChanged: true})
                             }}>
                            <img src={back_btn} alt=""/>
                        </div>
                        <div className="text-form-popup-content-title">
                            Texts
                        </div>
                        <div className="text-form-popup-close"
                             onClick={() => {
                                 window.parent.postMessage({datatype: "hide_designer"}, "*")
                                 this.props.setPreviewUpdateStatus({previewShouldBeChanged: true})
                                 window.onbeforeunload = null;
                             }}>
                            <img src={close_btn} alt=""/>
                        </div>
                    </div>

                    <div className={'bgChangePopup'}>
                        <div className={'bgChangePopup__preview'}>
                            {this.props?.pages[this.props.activePage]?.renderers?.length > 0 ? (previews?.length > 0 && !this.props.previewShouldBeChanged ? previews :
                                <Translate content={"Loading"}/>) : "preview not fount"}
                        </div>
                        <div className={'textChangePopup'}>
                            <Text/>
                        </div>
                    </div>
                    <Button outline theme="secondary"
                            style={{
                                margin: '30px auto',
                                display: 'flex'
                            }}
                            onClick={() => {
                                setTimeout(() => {
                                    this.props.showOrderPreviewPopup();
                                }, 0)
                            }}
                    >
                        <Translate content={"done"}/>
                    </Button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        activePage: state.glb.activePage,
        stage: state.glb.stage,
        pages: state.glb.pages,
        previewUrl: state.glb.previewUrl,
        previews: state.prv.previews,
        isItemDownloading: state.glb.isItemDownloading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditImageElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data));
        },

        setItemDownloadingStatus: (data) => dispatch(actionCreators.setItemDownloadingStatus(data)),
        generatePreview: (previewData) => dispatch(actionCreators.generatePreview(previewData)),
        showOrderPreviewPopup: () => dispatch(actionCreators.showOrderPreviewPopup()),
        hidePopup: () => dispatch(actionCreators.hidePopup()),
        showFaceCropPopup: (data) => dispatch(actionCreators.showFaceCropPopup({data: data})),
        addElementToPage: (canvasElement) => dispatch(actionCreators.addElementToPage(canvasElement)),
        setPreviewUpdateStatus: (data) => {
            dispatch(actionCreators.setPreviewUpdateStatus(data));
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundChangePopup);
