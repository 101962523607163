import React, {Component} from 'react';
import stl from "./ToolsViews.module.scss";
import {connect} from 'react-redux';
import * as actionCreators from '../../../store/actions/index';
import Translate from "react-translate-component";
import {Button} from "shards-react";

class Backgrounds extends Component {

    addBackground(elem) {
        let page = this.props.pages[this.props.activePage];
        let pageElements = page.canvasElements;
        let stage = this.props.stage;
        let stageWidth = stage.attrs.width;
        let stageHeight = stage.attrs.height;

        // Check if background element already exist and if yes then remove
        let backgroundExist = false;
        pageElements.forEach((item) => {
            if (item.name === 'Background') {
                let backgroundItem = item;
                backgroundItem.data = elem.background_image;
                this.props.onEditImageElement({
                    backgroundItem,
                    data: backgroundItem,
                    bgColor: false,
                    smallBackground_image: elem.smallBackground_image,
                    historyMode: true,
                    element: item.name,
                    activePage: this.props.activePage,
                });
                this.props.hideToolsMenu();
                backgroundExist = true;
            }
        });

        if (!backgroundExist) {
            this.props.addElementToPage({
                type: 'image',
                bgColor: false,
                data: elem.background_image,
                imgSize: {width: stageWidth, height: stageHeight},
                width: stageWidth,
                height: stageHeight,
                smallBackground_image: elem.smallBackground_image,
                activePage: this.props.activePage,
                x: (stageWidth / 2),
                y: (stageHeight / 2),
                name: 'Background'
            });
        }
    }

    addBackgroundColor(elem) {

        let page = this.props.pages[this.props.activePage];
        let pageElements = page.canvasElements;
        let stage = this.props.stage;
        let stageWidth = stage.attrs.width;
        let stageHeight = stage.attrs.height;

        // Check if background element already exist and if yes then remove
        let backgroundExist = false;

        pageElements.forEach((item) => {
            if (item.name === 'Background') {
                this.props.onEditImageElement({
                    // backgroundItem : '',
                    data: undefined,
                    bgColor: '#' + elem,
                    historyMode: true,
                    element: item.name,
                    activePage: this.props.activePage,
                });

                // this.props.hideToolsMenu();
                backgroundExist = true;
            }
        });

        if (!backgroundExist) {
            this.props.addElementToPage({
                type: 'image',
                data: undefined,
                bgColor: '#' + elem,
                imgSize: {width: stageWidth, height: stageHeight},
                width: stageWidth,
                height: stageHeight,
                activePage: this.props.activePage,
                x: (stageWidth / 2),
                y: (stageHeight / 2),
                name: 'Background'
            });
        }

    }

    render() {

        let activePage = this.props.pages[this.props.activePage];
        let backgrounds = [];

        let pageBackgrounds = activePage.designs;
        // console.log(activePage)

        for (let key in pageBackgrounds) {
            if (!pageBackgrounds.hasOwnProperty(key)) continue;
            let bck = pageBackgrounds[key];
            backgrounds.push({key: key, background_image: bck.image, smallBackground_image: bck.small_image });
        }


        return (this.props.showDesignsForm ? (
                <div className="designer-tools-uploader">
                    {/*TODO translate*/}
                    <div className="designer-tools-backgrounds-list">
                        {backgrounds.length > 0 ? (
                        <div className={stl.uploader}>
                            <span className={stl.uploader__title} style={{"padding": "10px"}}>
                                {/*Design*/}
                            </span>
                        </div>
                        ) : null}

                        {backgrounds.map((image, i) => {
                            return (
                                <img key={i}
                                     className="background-image"
                                     src={image.smallBackground_image || image.background_image}
                                     alt="alt" width="100"
                                     onClick={this.addBackground.bind(this, image)}/>
                            );
                        })}
                    </div>
                    {/*<div className={stl.tools__btn}>*/}
                    {/*    <button className="btn btn-dark btn-sm" onClick={this.props.hideToolsMenu}>*/}
                    {/*        <Translate content={"Close"}/>*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </div>
            ) : null
        )
    }
}

const mapStateToProps = state => {
    return {
        activePage: state.glb.activePage,
        pages: state.glb.pages,
        designerMode: state.glb.designerMode,
        sessionId: state.glb.sessionId,
        mode: state.glb.mode,
        showBackgroundsForm: state.tol.showBackgroundsForm,
        showDesignsForm: state.tol.showDesignsForm,
        isShowImageChangePopup: state.tol.showImageChangePopup,
        activeElement: state.glb.activeElement,
        stage: state.glb.stage,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addElementToPage: (canvasElement) => dispatch(actionCreators.addElementToPage(canvasElement)),
        setItemDownloadingStatus: (data) => dispatch(actionCreators.setItemDownloadingStatus(data)),
        refreshUploadedImages: (params) => dispatch(actionCreators.refreshUploadedImages(params)),
        deleteSelectedElement: (params) => dispatch(actionCreators.deleteSelectedElement(params)),
        onEditImageElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data));
            dispatch(actionCreators.hidePopup());
        },
        onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
        hideToolsMenu: () => dispatch(actionCreators.hideToolsMenu())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Backgrounds);
