import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import * as actionCreators from "../../../store/actions/index";
import clsx from "clsx";
import colorIcon from '../../../assets/images/color.svg';
import Translate from "react-translate-component";
import {DESKTOP_WIDTH} from "../../../config";
import { Button } from "shards-react";
import expand_more from '../../../assets/images/expand-more.png';
class PositionProperty extends Component {
    colorValue = React.createRef();

    state = {
        textColorsListRecently: [],
        colorDropdown: false,
        currentColor: '000000',
        newColor: ''
    };

    componentDidMount() {
        const { attrs } = this.props.activeElement;
        this.setProperties(attrs);
        if (this.colorValue.current) {
            const color = attrs.fill || attrs.stroke
            this.setState( {
                currentColor: color === 'black' ? '000000' : color,
            })
        }
    }

    componentDidUpdate() {
        // const { attrs } = this.props.activeElement;
        //
        // this.setProperties(attrs);
        //
        // let parentWrapper = document.querySelectorAll(".properties-column")[0];
        // let parentWrapperActive = document.querySelectorAll(".properties-column.active");
        // if ( !parentWrapperActive.length) {
        //     if (this.state.colorDropdown) {
        //         parentWrapper.scrollTo(0, 0);
        //         parentWrapper.classList.add("active")
        //     } else {
        //         parentWrapper.classList.remove("active")
        //     }
        // }
    }
    componentWillUnmount() {
        if(document.querySelectorAll(".properties-column")[0]) document.querySelectorAll(".properties-column")[0].classList.remove("active")
    }

    setProperties(attrs) {
        if (this.colorValue.current) this.colorValue.current.style.backgroundColor = attrs.fill || attrs.stroke;
    }

    addColorToRecantlyList(color) {
        let colorsList = this.state.textColorsListRecently;
        if (colorsList.indexOf(color) === -1) {
            this.setState({
                textColorsListRecently: [color, ...colorsList.length >= 8 ? colorsList.slice(0, -1) : colorsList]
            });
        }
    }

    handleColorChange(color) {
        // this.props.selectedElements.forEach(name => {
        //     console.log(name);
        //     this.props.onEditElement({
        //         color: "#" + color,
        //         element: name,
        //         activePage: this.props.activePage
        //     });
        // });

        this.props.pages[0].canvasElements.forEach((item) => {
            if ((item.elementId === this.props.activeElement.attrs.elementId
                && this.props.activeElement.attrs.elementId !== 0
                && this.props.activeElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.name)) {
                this.props.onEditElement({
                    color: "#" + color,
                    element: item.name,
                    activePage: this.props.activePage
                });
            }
        });


        this.addColorToRecantlyList(color);
        this.colorValue.current.style.backgroundColor = "#" + color;
        this.setState( {
            newColor: color
        })
    }


    saveColor = () => {
        if (window.innerWidth <= DESKTOP_WIDTH) {
            let name = this.props.activeElement.attrs.name;
            this.props.onSelectElement("");
            setTimeout(() => {
                this.props.onSelectElement(name);
            }, 0)
        }
    }

    closeColor = () => {
        const { currentColor } = this.state;
        this.props.onEditElement({
            color: currentColor,
            element: this.props.activeElement.attrs.name,
            activePage: this.props.activePage
        });
        this.colorValue.current.style.backgroundColor = currentColor;
        if(window.innerWidth <=DESKTOP_WIDTH){
            let name = this.props.activeElement.attrs.name;
            this.props.onSelectElement("");
            setTimeout(()=>{
                this.props.onSelectElement(name);
            }, 0)
        }
    }

    render() {
        const { newColor, currentColor } = this.state;
        let activePage = this.props.pages[this.props.activePage];
        let colors = [];
        for (let key in activePage.colors) {
            if (!activePage.colors.hasOwnProperty(key)) continue;
            let color = activePage.colors[key];
            colors.push(color.color_code);
        }
    // || !this.props.propertyTextColorAllowed
        if ((this.props.activeElement.attrs.quick_edit_color === false ) && this.props.mode !== 'ADMIN') {
            return null
        }
        const replaceCurrentColor = currentColor ? currentColor.replace('#', '') : currentColor;
        const activeColor = newColor || replaceCurrentColor;

        return (
            <div
                className={clsx("designer-right-panel-item", "designer-right-panel-item__colorFont", this.state.colorDropdown &&"active")}>
                <div className={"designer-right-panel-item__color"} onClick={()=>{
                    this.setState({
                        colorDropdown:!this.state.colorDropdown
                    })
                }}>
                    <div className={"designer-right-panel-item__titleFlex"}>
                        {/*<img src={colorIcon} alt=""/>*/}
                        <span className={"text"}>
                            <Translate content={"Color"}/>
                            <img className={clsx("arrow", this.state.colorDropdown && "active")} src={expand_more} alt=""/>
                        </span>
                    </div>
                    <div className={"designer-right-panel-colorBox"}
                         ref={this.colorValue}/>
                </div>
                <div className={"dropdownContent"}>
                    {/*<div className="dropdownContentBtn">*/}
                    {/*    <Button outline theme="secondary" onClick={this.closeColor}>*/}
                    {/*        <Translate content={"Cancel"}/>*/}
                    {/*    </Button>*/}
                    {/*    <Button theme="secondary" onClick={this.saveColor}>*/}
                    {/*        OK*/}
                    {/*    </Button>*/}
                    {/*</div>*/}

                    <div className={"colorSwitchList"}>
                        {colors.map((item) => {
                            return (
                              <Button  key={item}
                                       onClick={this.handleColorChange.bind(this, item)}
                                       className={`colorSwitchBtn ${ activeColor && activeColor.toUpperCase() === item.toUpperCase() ? 'colorSwitchBtnActive' : ''}`}
                                       style={{'backgroundColor': '#' + item}}>

                              </Button>
                            )
                        })}
                    </div>
                    {this.state.textColorsListRecently.length > 0 ?
                        <div className={"colorSwitchList__recently"}>
                            <div>
                                <Translate content={"Recently Used Colors"}/>
                            </div>
                            <div className={"colorSwitchList"}>
                                {this.state.textColorsListRecently.map((item) => {
                                    return (
                                      <Button key={item}
                                              onClick={this.handleColorChange.bind(this, item)}
                                              className="colorSwitchBtn" data-color={"#" + item}
                                              style={{'backgroundColor': '#' + item}}>
                                      </Button>
                                    )
                                })}
                            </div>
                        </div> : null
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
        pages: state.glb.pages,
        mode: state.glb.mode,
        propertyTextColorAllowed: state.tol.propertyTextColorAllowed,
        selectedElements: state.glb.selectedElements,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onEditElement: (data) => dispatch(actionCreators.editElementAttrs(data)),
        onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PositionProperty);
