import * as actionTypes from './actionTypes';

export const showTextForm = () => {
    return {
        type: actionTypes.SHOW_TEXT_FORM
    }
};

export const showEmojiForm = () => {
    return {
        type: actionTypes.SHOW_EMOJI_FORM
    }
};

export const hideToolsMenu = () => {
    return {
        type: actionTypes.HIDE_TOOLS_MENU
    }
};

export const showTextPopup = () => {
    return {
        type: actionTypes.SHOW_TEXT_POPUP
    }
};

export const toolsMenuVisibleToggle = (data) => {
    return {
        type: actionTypes.TOOLS_MENU_SHOW,
        mode: data.mode
    }
};

export const userAllowedTools = (data) => {
    const {toolImagesAllowed, toolShapesAllowed, toolEmojiAllowed, toolTextAllowed} = data;
    return {
        type: actionTypes.TOOLS_USER_ALLOWED,
        toolImagesAllowed,
        toolShapesAllowed,
        toolEmojiAllowed,
        toolTextAllowed,
    }
};

export const userAllowedProperties = (data) => {
    const {
        propertyTextStrokeAllowed,
        propertyTextStrokeColorAllowed,
        propertyTextShadowColorAllowed,
        propertyTextShadowAllowed,
        propertyTextColorAllowed,
        propertyTextFontAllowed,
        propertyFaceCropRotateAllowed,
        propertyFaceCropZoomAllowed,
        propertyFaceCropSizeAllowed
    } = data;
    return {
        type: actionTypes.TOOLS_PROPERTIES_USER_ALLOWED,
        propertyFaceCropRotateAllowed,
        propertyFaceCropZoomAllowed,
        propertyFaceCropSizeAllowed,
        propertyTextStrokeAllowed,
        propertyTextStrokeColorAllowed,
        propertyTextShadowColorAllowed,
        propertyTextShadowAllowed,
        propertyTextColorAllowed,
        propertyTextFontAllowed
    }
};

export const showQuickEditLabelsPopup = () => {
    return {
        type: actionTypes.SHOW_QUICK_EDIT_LABELS_POPUP
    }
};

export const showTextEditPopup = () => {
    return {
        type: actionTypes.SHOW_TEXT_EDIT_POPUP
    }
};

export const showImageCropPopup = () => {
    return {
        type: actionTypes.SHOW_IMAGE_CROP_POPUP
    }
};

export const showFaceCropPopup = (data) => {
    return {
        type: actionTypes.SHOW_FACE_CROP_POPUP,
        data
    }
};
export const showBackgroundChangePopup = (data) => {
    return {
        type: actionTypes.SHOW_BACKGROUND_CHANGE_POPUP,
        data,
        displaytype: data?.displaytype,
    }
};
export const showTextChangePopup = (data) => {
    return {
        type: actionTypes.SHOW_TEXT_CHANGE_POPUP,
        data
    }
};

export const showOrderPreviewPopup = (data) => {
    return {
        type: actionTypes.SHOW_ORDER_PREVIEW_POPUP,
        data
    }
};

export const showImageChangePopup = () => {
    return {
        type: actionTypes.SHOW_IMAGE_CHANGE_POPUP
    }
};

export const showUploadForm = () => {
    return {
        type: actionTypes.SHOW_UPLOAD_FORM
    }
};

export const showBackgroundsForm = () => {
    return {
        type: actionTypes.SHOW_BACKGROUNDS_FORM
    }
};

export const showDesignsForm = () => {
    return {
        type: actionTypes.SHOW_DESIGNS_FORM
    }
};

export const showElementsList = () => {
    return {
        type: actionTypes.SHOW_ELEMENTS_LIST
    }
};

export const showShapesForm = () => {
    return {
        type: actionTypes.SHOW_SHAPES_FORM
    }
};

export const hidePopup = () => {
    return {
        type: actionTypes.HIDE_POPUP
    }
};