import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import AvatarEditor from 'react-avatar-editor'
import stl from "./FaceCropPopup.module.scss"

import rotation_icon from '../../assets/images/rotatetion_icon.svg'
import plus_icon from '../../assets/images/faceCropPlus_icon.svg'
import minus_icon from '../../assets/images/faceCropMinus_icon.svg'
import close_btn from '../../assets/images/iconfinder_Close_6351931 2.png'
import back_btn from '../../assets/images/expand-more.png'
import Translate from "react-translate-component";
import { post } from "axios";
import fullScreenIcon from '../../assets/images/faceCropZoom.svg';
import { Button } from "shards-react";
import loadingImage from "../../assets/images/loading.gif";
import clsx from "clsx";
import queryString from "query-string";

const params = queryString.parse(window.location.search);

class ImageCropPopup extends Component {
    state = {
        imgSource: "",
        border: 50,
        scale: 1.2,
        rotate: 0,
        elementScaling: false,
        touchEventAdded: false,
        borderRadius: 0,
        preview: { img: undefined },
        isNewImgDownloaded: false,
        loading: false,
        loadingFailed: false,
        loadingSuccess: false,
        showLoading: false,
        previewChecked: false
    };

    sliderStyles = {
        track: {
            backgroundColor: "#A7AAA8",
            height: 1,
            width: 200,
        },
        active: {
            backgroundColor: '#222C28'
        },
        thumb: {
            width: 21,
            height: 21
        },
        disabled: {
            opacity: 0.5
        }
    }

    editor = React.createRef();
    editorWrap = React.createRef();

    getDistance(p1, p2) {
        return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    }

    setMultitouchListener() {
        if (this.editorWrap.current !== undefined && !this.state.touchEventAdded) {
            let lastDist = 0;
            this.editorWrap.current.addEventListener(
                'touchmove',
                (evt) => {
                    let touch1 = evt.touches[0];
                    let touch2 = evt.touches[1];

                    if (touch1 && touch2) {
                        let dist = this.getDistance(
                            {
                                x: touch1.clientX,
                                y: touch1.clientY,
                            },
                            {
                                x: touch2.clientX,
                                y: touch2.clientY,
                            }
                        );

                        if (!lastDist) {
                            lastDist = dist;
                        }

                        let scale = ((this.state.scale * dist) / lastDist);
                        var rotation = evt.rotation;

                        // This isn't a fun browser!
                        if (!rotation) {
                            rotation = Math.atan2(evt.touches[0].pageY - evt.touches[1].pageY,
                                evt.touches[0].pageX - evt.touches[1].pageX) * 180 / Math.PI;
                        }
                        this.changeValue("scale", scale)
                        this.changeValue("rotate", rotation)
                        if (!this.state.elementScaling) {
                            this.setState({
                                elementScaling: true
                            });
                        }
                        lastDist = dist;
                    }
                },
                false
            );

            this.editorWrap.current.addEventListener('touchend', () => {
                lastDist = 0;
                if (this.state.elementScaling) {
                    this.setState({
                        elementScaling: false
                    })
                }
            });
            if (!this.state.touchEventAdded) this.setState({ touchEventAdded: true })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setMultitouchListener();
    }


    getImageDataToFaceCropArea(onload = false) {
        let url = this.props.activeElement?.attrs?.image?.src || this.props.faceCropPopupData.data
        if (url) {
            fetch(url)
                .then(response => {
                    return response.blob();
                })
                .then((response) => {
                    this.setState({
                        imgSource: URL.createObjectURL(response)
                    });
                });
            if (this.props.pages[this.props.activePage].multiface_elements_admin_only === 1 && onload) {
                this.removeBackground();
            }

            if (!onload) {
                this.setState({
                    loading: false,
                    loadingFailed: false,
                    loadingSuccess: false,
                })
            }
        } else {
            this.props.hidePopup()
        }
    }

    componentDidMount() {
        this.getImageDataToFaceCropArea(true);
    }

    removeBackground() {
        if (this.props.activeElement.attrs.imageWithoutBg === 'empty') {
            this.setState({
                loading: true
            });
            if (this.props.activeElement?.attrs?.image?.src) {
                let config = { headers: { 'content-type': 'multipart/form-data' } };
                let data = JSON.stringify({ imageUrl: this.props.activeElement?.attrs?.image?.src });
                post(
                    'https://uploader.radbag.de/removeBackground', data, config).then(response => {
                        if (response.data.error) {
                            /* Show error */
                            console.error(response.data.data);
                        } else {
                            this.props.onEditElement({
                                imageWithoutBg: response.data.imageUrl,
                                element: this.props.activeElement.attrs.name,
                                activePage: this.props.activePage,
                            });
                            fetch(response.data.imageUrl)
                                .then(response => {
                                    return response.blob();
                                })
                                .then((response) => {
                                    this.setState({
                                        imgSource: URL.createObjectURL(response)
                                    });
                                });
                        }
                        this.setState({
                            loading: false,
                            loadingSuccess: true
                        });
                    }).catch(() => {
                        this.props.onEditElement({
                            imageWithoutBg: false,
                            element: this.props.activeElement.attrs.name,
                            activePage: this.props.activePage,
                        });
                        this.setState({
                            loading: false,
                            loadingFailed: true
                        });
                    });
            }
        } else if (this.props.activeElement.attrs.imageWithoutBg && this.props.activeElement.attrs.imageWithoutBg?.length > 0) {
            this.setState({
                loading: true
            });
            fetch(this.props.activeElement.attrs.imageWithoutBg)
                .then(response => {
                    return response.blob();
                })
                .then((response) => {
                    this.setState({
                        imgSource: URL.createObjectURL(response),
                        loading: false,
                        loadingSuccess: true
                    });
                });

        }
    }

    changeValue(prop, e) {
        this.setState({
            [prop]: (e && e.target && e.target.value) || e
        });
    }

    handleSave = () => {
        if (this.editor.current) {
            this.setState({
                preview: {
                    img: this.editor.current.getImageScaledToCanvas().toDataURL()
                },
            })
        }
    };

    generatePreview() {
        if (this.props.stage) {
            let stage = this.props.stage.clone();
            this.props.generatePreview({
                pages: this.props.pages,
                activePage: this.props.activePage,
                stage: stage,
                previewUrl: this.props.previewUrl
            });
        }
    }

    prepareData() {
        this.setState({
            showLoading: true
        })
        let config = { headers: { 'content-type': 'multipart/form-data' } };
        let url = this.state?.preview?.img || this.editor.current.getImageScaledToCanvas().toDataURL();
        let data = JSON.stringify({ imgCropedData: url });

        post('https://uploader.radbag.de/uploadCropFile', data, config).then(response => {
            url = response.data.imgCropedData
            this.submitChanges(url);
        }).catch(() => {
            this.setState({
                showLoading: false
            })
            console.warn('Error during cropped image saving.')
        });
    }

    submitChanges(url) {

        this.props.pages[0].canvasElements.map((item, index) => {
            // console.log("item", item);


            if (this.props.activeElement.attrs.name === item?.name || ((item?.elementRepeat || item?.elementRepeatOval) && item?.type === "image" && this.props.activeElement.attrs.faceId === item.faceId && this.props.activeElement.attrs.faceId !== undefined && (!item?.user_adminAccessOnly || parseInt(params.robot) === 1))) {
                try {
                    this.props.onEditElement({
                        historyMode: false,
                        newImgData: {
                            imageUrl: url,
                            faceCropped: url,
                            width: (item.height * item.scaleX) / (this.props?.activeElement?.attrs.elementRepeatOval ? 1.208791208791209 : 1.331719128329298),
                            height: item.height * item.scaleY,
                        },
                        scaleX: 1,
                        scaleY: 1,
                        element: item.name,
                        activePage: this.props.activePage,
                    });
                } catch (err) {
                    console.log(err);
                }
            }
            return null
        });

        if (this.props.pages[this.props.activePage].multiface_elements_admin_only === 1) {
            this.props.hidePopup();

            if (this.props.mode !== "ADMIN") { //&& this.props.mode !== "PRODUCTION"
                let activePage = this.props.pages[this.props.activePage];
                let colors = [];
                for (let key in activePage.backgrounds) {
                    let item = activePage.backgrounds[key]
                    if (activePage.backgrounds[key].type === 'color') {
                        colors.push(item.color_code);
                    }
                }

                let backgrounds = [];
                let pageBackgrounds = activePage.designs;
                // console.log(activePage)

                for (let key in pageBackgrounds) {
                    if (!pageBackgrounds.hasOwnProperty(key)) continue;
                    let bck = pageBackgrounds[key];
                    backgrounds.push({ key: key, background_image: bck.image });
                }

                // console.log(colors.length > 0 , backgrounds.length > 0);

                if ((colors.length > 0 || backgrounds.length > 0) && this.props.pages[this.props.activePage].multiface_elements_admin_only === 1) {
                    
                    this.props.showBackgroundChangePopup();
                    /*
                    let shouldTextPopupOpen = false;
                    this.props.pages[0].canvasElements.forEach((i) => {
                        if (i.type === "text" && i.changeable) {
                            shouldTextPopupOpen = true
                        }
                    });
                    if (shouldTextPopupOpen) {
                        this.props.onShowTextForm();
                        this.props.showTextChangePopup();
                    } else {
                        this.props.showOrderPreviewPopup()
                    }
                    console.log("shouldTextPopupOpen", shouldTextPopupOpen);
                    */
                } else {
                    this.props.showOrderPreviewPopup()
                }
            }

        } else {
            this.props.hidePopup();
            this.props.onSelectElement("");
        }

        this.setState({
            showLoading: false
        });
    }

    render() {
        const { showLoading } = this.state;

        return (
            <div className="text-form-popup-content">
                <div className="text-form-popup-overlay"
                    onClick={this.props.pages[this.props.activePage].multiface_elements_admin_only !== 1 ? this.props.hidePopup : () => { }}
                />
                <div
                    className={`text-form-popup-content-inner ${showLoading ? 'faceCropLoading' : ''} ${this.props.pages[this.props.activePage].multiface_elements_admin_only !== 1 ? '' : 'faceCropMultifaceWrap'}`}
                >
                    {
                        showLoading && (
                            <div
                                className={stl.crop__wrap_loadingImg}
                            >
                                <img
                                    key="Loading image"
                                    src={loadingImage}
                                    alt={"loading_image"} />
                            </div>
                        )
                    }

                    <div className="text-form-popup-content-title__wrap">
                        <div className="text-form-popup-back" onClick={() => this.props.showImageChangePopup()}>
                            {
                                this.state.loading ? null : <img src={back_btn} alt="" />
                            }
                        </div>

                        {/*<div className="text-form-popup-content-title">Bild anpassen</div>*/}
                        <div className="text-form-popup-content-title"><Translate content={"Face crop"} /></div>

                        <div className="text-form-popup-close"
                            onClick={() => {
                                if (this.state.loading) {
                                    this.props.showImageChangePopup()
                                } else {
                                    window.parent.postMessage({ eventType: "HIDE_LOADING" }, "*")
                                }
                            }}>
                            <img src={close_btn} alt="" />
                        </div>
                    </div>

                    <div className={clsx(stl.crop__wrap, showLoading && stl.crop__wrap_loading)}>
                        <div
                            ref={this.editorWrap}
                            className={clsx(stl.crop__wrap_avatarEditor, this.props?.activeElement?.attrs?.user_isShapeHeart && stl.crop__wrap_avatarEditor__heartMask)}>

                            {this.state.loading ?
                                <div className={clsx(stl.crop__wrap_avatarEditorLoader)}>
                                    <div className={stl.input__btn}>
                                        <Translate content={"Loading"} />
                                    </div>
                                </div>
                                : null}

                            <AvatarEditor
                                ref={this.editor}
                                image={this.state.imgSource}
                                width={
                                    window.innerWidth > 600 ?
                                        (this.state.rotate === 0 || this.state.rotate === 180 ? (this.props?.activeElement?.attrs?.elementRepeatOval ? 455 : 413) : 550)
                                        : (this.state.rotate === 0 || this.state.rotate === 180 ? (this.props?.activeElement?.attrs?.elementRepeatOval ? 192 : 173) : 231)
                                }
                                height={
                                    window.innerWidth > 600 ?
                                        (this.state.rotate === 0 || this.state.rotate === 180 ? 550 : (this.props?.activeElement?.attrs?.elementRepeatOval ? 455 : 413))
                                        : (this.state.rotate === 0 || this.state.rotate === 180 ? 231 : (this.props?.activeElement?.attrs?.elementRepeatOval ? 192 : 173))
                                }
                                border={this.props.activeElement?.attrs?.user_isShapeHeart ? 0 : this.state.border}
                                borderRadius={this.props.activeElement?.attrs?.user_isShapeHeart ? 0 : 300}
                                color={[0, 0, 0, 0.5]}
                                scale={this.state.scale}
                                rotate={this.state.rotate}
                                onPositionChange={this.handleSave}
                                disableBoundaryChecks={false}
                                disableHiDPIScaling={true}
                            />

                        </div>

                        <div className={stl.crop__wrap_properties}>
                            <>
                                <div className={stl.input}>
                                    {this.state.loading ?
                                        <div className={stl.input__btn}>
                                            <Translate content={"Wird freigestellt"} />
                                        </div> : (
                                            this.state.loadingFailed ?
                                                <div className={stl.input__btn_done}>
                                                    <Translate content={"Background not recognized"} />
                                                </div> : (this.state.loadingSuccess ?
                                                    <div className={stl.input__btn_done} onClick={() => {
                                                        this.getImageDataToFaceCropArea()
                                                    }}>
                                                        <Translate content={"Use original image"} />
                                                        {/*<Translate content={"Background already removed"}/>*/}
                                                    </div> :
                                                    <div className={stl.input__btn}
                                                        onClick={this.removeBackground.bind(this)}>
                                                        <Translate content={"Remove background"} />
                                                    </div>
                                                )
                                        )
                                    }
                                </div>


                                {this.state.loading ? null : <>
                                    <div className={stl.input}>
                                        <img className={stl.input__icon} src={fullScreenIcon} alt="" />
                                        <div className={stl.input__slider}>
                                            <img src={minus_icon} className={stl.input__sliderLeftBtn} alt=""
                                                onClick={this.changeValue.bind(this, "scale", this.state.scale - 0.1)} />
                                            {`${Math.round(this.state.scale * 100)}%`}
                                            <img src={plus_icon} className={stl.input__sliderRightBtn} alt=""
                                                onClick={this.changeValue.bind(this, "scale", this.state.scale + 0.1)} />
                                        </div>
                                    </div>

                                    <div className={stl.input}>
                                        <img className={stl.input__icon} src={rotation_icon} alt="" />
                                        <div className={stl.input__slider}>
                                            <img src={minus_icon} className={stl.input__sliderLeftBtn} alt=""
                                                onClick={this.changeValue.bind(this, "rotate", this.state.rotate - 10)} />
                                            {`${Math.round(this.state.rotate)}°`}
                                            <img src={plus_icon} className={stl.input__sliderRightBtn} alt=""
                                                onClick={this.changeValue.bind(this, "rotate", this.state.rotate + 10)} />
                                        </div>
                                    </div>
                                </>}
                                <div
                                    className={`text-form-popup-content-buttons ${this.props.pages[this.props.activePage].multiface_elements_admin_only !== 1 ? '' : 'faceCropMultiface-buttons'}`}>

                                    {/*<Button outline theme="secondary" onClick={() => this.props.hidePopup()}>*/}
                                    {/*    <Translate content={"Cancel"}/>*/}
                                    {/*</Button>*/}
                                    {/*<Button theme="secondary" onClick={this.prepareData.bind(this)}>*/}
                                    {/*    OK*/}
                                    {/*</Button>*/}

                                    {this.props.pages[this.props.activePage].multiface_elements_admin_only === 1 && this.props.mode !== "ADMIN" ?
                                        (
                                            this.state.loading ? null : <>
                                                <Button outline theme="secondary"
                                                    onClick={() => this.props.showImageChangePopup()}>
                                                    <Translate content={"Change Image"} />
                                                </Button>
                                                <Button theme="secondary" onClick={this.prepareData.bind(this)}>

                                                    <Translate content={"Continue"} />
                                                </Button>
                                            </>
                                        ) : <>
                                            <Button outline theme="secondary" onClick={() => this.props.hidePopup()}>
                                                <Translate content={"Cancel"} />
                                            </Button>
                                            <Button theme="secondary" onClick={this.prepareData.bind(this)}>
                                                OK
                                            </Button>
                                        </>
                                    }

                                </div>

                            </>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        pages: state.glb.pages,
        isShowFaceCropPopup: state.tol.showFaceCropPopup,
        faceCropPopupData: state.tol.faceCropPopupData,
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
        stage: state.glb.stage,
        previewUrl: state.glb.previewUrl,
        magentoUrl: state.glb.magentoUrl,
        previews: state.prv.previews,
        previewsLoadingCount: state.prv.previewsLoadingCount,
        previewShouldBeChanged: state.glb.previewShouldBeChanged,
        historyElementsCopy: state.glb.historyElementsCopy,
        historyActiveStep: state.glb.historyActiveStep,
        designerMode: state.glb.designerMode,
        canvasElements: state.cnv.canvasElements,
        activeCanvasElement: state.cnv.activeCanvasElement,
        designId: state.glb.designId,
        statusText: state.glb.statusText,
        mode: state.glb.mode,
        sessionId: state.glb.sessionId,
        productId: state.glb.productId,
        layerId: state.glb.layerId,
        updateId: state.glb.updateId,
        showGrid: state.glb.showGrid,
        showGuideline: state.glb.showGuideline,
        isBorderLimitEditing: state.glb.isBorderLimitEditing,
        isCustomMaskEditing: state.glb.isCustomMaskEditing,
        toolTextAllowed: state.tol.toolTextAllowed,
        toolEmojiAllowed: state.tol.toolEmojiAllowed,
        toolShapesAllowed: state.tol.toolShapesAllowed,
        toolImagesAllowed: state.tol.toolImagesAllowed,
        propertyTextStrokeAllowed: state.tol.propertyTextStrokeAllowed,
        propertyTextStrokeColorAllowed: state.tol.propertyTextStrokeColorAllowed,
        propertyTextShadowColorAllowed: state.tol.propertyTextShadowColorAllowed,
        propertyTextShadowAllowed: state.tol.propertyTextShadowAllowed,
        propertyTextColorAllowed: state.tol.propertyTextColorAllowed,
        propertyTextFontAllowed: state.tol.propertyTextFontAllowed,
        propertyFaceCropSizeAllowed: state.tol.propertyFaceCropSizeAllowed,
        propertyFaceCropZoomAllowed: state.tol.propertyFaceCropZoomAllowed,
        propertyFaceCropRotateAllowed: state.tol.propertyFaceCropRotateAllowed,
        canvasMaskElements: state.cnvMask.canvasMaskElements,
    };
}
    ;

const mapDispatchToProps = dispatch => {
    return {
        onEditElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data))
        },
        onShowTextForm: () => {
            dispatch(actionCreators.showTextForm());
        },
        onSaveLayer: (data) => dispatch(actionCreators.saveConfiguration(data)),
        onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
        hidePopup: () => dispatch(actionCreators.hidePopup()),
        onShowTextPopup: () => dispatch(actionCreators.showTextPopup()),
        showImageChangePopup: () => dispatch(actionCreators.showImageChangePopup()),
        showBackgroundChangePopup: () => dispatch(actionCreators.showBackgroundChangePopup()),
        showTextChangePopup: () => dispatch(actionCreators.showTextChangePopup()),
        generatePreview: (previewData) => dispatch(actionCreators.generatePreview(previewData)),
        showOrderPreviewPopup: () => dispatch(actionCreators.showOrderPreviewPopup()),
    };
}
    ;

export default connect(mapStateToProps, mapDispatchToProps)(ImageCropPopup);
