    import React, {Component} from 'react';
import stl from "./ToolsViews.module.scss";
import {connect} from 'react-redux';
import * as actionCreators from '../../../store/actions/index';
import Translate from "react-translate-component";
import {Button} from "shards-react";
import cropImage from "../../../helpes/cropImage";

class Backgrounds3 extends Component {

    addBackground(elem) {

        let imageWidth;
        let imageHeight;
        this.props.uploadedImages.forEach((item2) => {

            if (item2.url.replace('/thumbs', '') === elem.replace('/thumbs', '')) {
                imageWidth = item2.filesDimensions.width
                imageHeight = item2.filesDimensions.height
            }
        });

        let page = this.props.pages[this.props.activePage];
        let pageElements = page.canvasElements;
        let stage = this.props.stage;
        let stageWidth = stage.attrs.width;
        let stageHeight = stage.attrs.height;

        // Check if background element already exist and if yes then remove
        let backgroundExist = false;

        pageElements.forEach((item) => {
            if (item.name === 'Background2') {
                let backgroundItem = item;
                // let {cW,cH} = item;
                backgroundItem.data = elem.replace('/thumbs', '/resized');
                this.props.onEditImageElement({
                    backgroundItem,
                    data: backgroundItem.data,
                    bgColor: false,
                    historyMode: true,
                    imageCrop: cropImage(item.width, item.height, imageWidth, imageHeight),
                    element: item.name,
                    activePage: this.props.activePage,
                });
                // this.props.hideToolsMenu();
                backgroundExist = true;
            }
        });

        if (!backgroundExist) {
            this.props.addElementToPage({
                type: 'image',
                bgColor: false,
                data: elem.replace('/thumbs', '/resized'),
                imgSize: {width: stageWidth, height: stageHeight},
                width: stageWidth,
                height: stageHeight,
                activePage: this.props.activePage,
                x: (stageWidth / 2),
                y: (stageHeight / 2),
                name: 'Background2'
            });

            this.props.onEditImageElement({
                imageCrop: cropImage(stageWidth, stageHeight, imageWidth, imageHeight),
                element: 'Background2',
                activePage: this.props.activePage,
            });
        }
    }

    addBackgroundColor(elem) {
        let page = this.props.pages[this.props.activePage];
        let pageElements = page.canvasElements;
        let stage = this.props.stage;
        let stageWidth = stage.attrs.width;
        let stageHeight = stage.attrs.height;

        // Check if background element already exist and if yes then remove
        let backgroundExist = false;

        pageElements.forEach((item) => {
            if (item.name === 'Background2') {
                this.props.onEditImageElement({
                    // backgroundItem : '',
                    data: undefined,
                    bgColor: '#' + elem,
                    historyMode: true,
                    element: item.name,
                    activePage: this.props.activePage,
                });

                // this.props.hideToolsMenu();
                backgroundExist = true;
            }
        });

        if (!backgroundExist) {
            this.props.addElementToPage({
                type: 'image',
                data: undefined,
                bgColor: '#' + elem,
                imgSize: {width: stageWidth, height: stageHeight},
                width: stageWidth,
                height: stageHeight,
                activePage: this.props.activePage,
                x: (stageWidth / 2),
                y: (stageHeight / 2),
                name: 'Background2'
            });
        }

    }

    render() {


        let activePage = this.props.pages[this.props.activePage];

        let colors = [];
        let backgrounds = [];
        for (let key in activePage.backgrounds) {
            // if (!activePage.colors.hasOwnProperty(key)) continue;
            // let color = activePage.colors[key];
            let item = activePage.backgrounds[key]
            if (activePage.backgrounds[key].type === 'color') {
                colors.push(item.color_code);
            }
            if (activePage.backgrounds[key].type === 'image') {
                backgrounds.push(item);
            }

        }

        return (this.props.showBackgroundsForm && (colors.length > 0 || backgrounds.length > 0) ? (
                <div className="designer-tools-uploader  designer-tools--uploader-backgrounds-list">
                    {/*TODO translate*/}
                    {colors.length > 0 ? (
                        <div className="designer-tools-backgrounds-list">
                            {/*<div className={stl.uploader}>*/}
                            {/*<span className={stl.uploader__title} style={{"padding": "10px"}}>*/}
                            {/*    /!*<Translate content="ColorsTools"/>*!/*/}
                            {/*</span>*/}
                            {/*</div>*/}
                            <div className={"designer-tools-backgrounds-list-colors"}>
                                <div className={"colorSwitchList"}>
                                    {colors.map((item, index) => {
                                        return (
                                            <Button key={index}
                                                    onClick={this.addBackgroundColor.bind(this, item)}
                                                // className={`colorSwitchBtn ${ activeColor && activeColor.toUpperCase() === item.toUpperCase() ? 'colorSwitchBtnActive' : ''}`}
                                                    style={{'backgroundColor': '#' + item}}>

                                            </Button>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>) : null
                    }

                    {backgrounds.length > 0 ? (
                        <div className="designer-tools-backgrounds-list">
                            <div className={stl.uploader}>
                            <span className={stl.uploader__title} style={{"padding": "10px"}}>
                              <Translate content="ImagesTools"/>
                            </span>
                            </div>
                            <div className={"designer-tools-backgrounds-list-images"}>
                                {backgrounds.map((item, index) => {
                                    return (
                                        <img key={index}
                                             src={item.image}
                                             alt={item.name ?? ''}
                                             onClick={this.addBackground.bind(this, item.image)}
                                            // className={`colorSwitchBtn ${ activeColor && activeColor.toUpperCase() === item.toUpperCase() ? 'colorSwitchBtnActive' : ''}`}
                                        />
                                    )
                                })}
                            </div>
                        </div>) : null}

                    {/*<div className={stl.tools__btn}>*/}
                    {/*    <button className="btn btn-dark btn-sm" onClick={this.props.hideToolsMenu}>*/}
                    {/*        <Translate content={"Close"}/>*/}
                    {/*    </button>*/}
                    {/*</div>*/}

                </div>
            ) : null
        )
    }

}

const mapStateToProps = state => {
    return {
        uploadedImages: state.glb.uploadedImages,
        activePage: state.glb.activePage,
        pages: state.glb.pages,
        designerMode: state.glb.designerMode,
        sessionId: state.glb.sessionId,
        mode: state.glb.mode,
        showDesignsForm: state.tol.showDesignsForm,
        showBackgroundsForm: state.tol.showBackgroundsForm,
        isShowImageChangePopup: state.tol.showImageChangePopup,
        activeElement: state.glb.activeElement,
        stage: state.glb.stage,
    };
};

const mapDispatchToProps = dispatch => {
        return {
            addElementToPage: (canvasElement) => dispatch(actionCreators.addElementToPage(canvasElement)),
            setItemDownloadingStatus: (data) => dispatch(actionCreators.setItemDownloadingStatus(data)),
            refreshUploadedImages: (params) => dispatch(actionCreators.refreshUploadedImages(params)),
            deleteSelectedElement: (params) => dispatch(actionCreators.deleteSelectedElement(params)),
            onEditImageElement: (data) => {
                dispatch(actionCreators.editElementAttrs(data));
                dispatch(actionCreators.hidePopup());
            },
            onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
            hideToolsMenu: () => dispatch(actionCreators.hideToolsMenu())
        };
    }
;

export default connect(mapStateToProps, mapDispatchToProps)(Backgrounds3);
