import * as actionTypes from '../actions/actionTypes';

const initialState = {
    hasTemplateChanges: false,
    statusText: '',
    uploadedImages: [],
    pages: [],
    storeViews: [],
    stage: null,
    mode: '',
    selectedElement: '',
    selectedElementLabels: [],
    selectedElementStoreViewValues: [],
    activePage: 0,
    magentoUrl: '',
    uploaderUrl: '',
    previewUrl: '',
    sessionId: '',
    lastUpdate: 0,
    designId: 0,
    layerId: 0,
    updateId: 0,
    productId: 0,
    storeId: 0,
    activeElement: undefined,
    showGrid: false,
    showGuideline: false,
    isItemDownloading: 1,
    noImgLoadError: true,
    previewShouldBeChanged: false,
    isBorderLimitEditing: {
        status: false
    },
    isCustomMaskEditing: {
        status: false
    },
    historyElementsCopy: [],
    historyActiveStep: -1,
    canvasImage: false,
    allImagesReady: false,
    selectedElements: [],
    displaytype:"shop",
};

const globals = (state = initialState, action) => {
    
    let date = new Date();
    switch (action.type) {
        case actionTypes.SET_DESIGNER_MODE:
            return {
                ...state,
                designerMode: action.mode
            }
        case actionTypes.SET_STAGE:
            return {
                ...state,
                stage: action.stage
            }
        case actionTypes.SET_IMG_STATUS_ERROR:
            return {
                ...state,
                noImgLoadError: action.noImgLoadError
            }
        case actionTypes.SET_INIT_PARAMS:
            return {
                ...state,
                pages: action.pages,
                uploadedImages: action.uploadedImages,
                mode: action.mode,
                magentoUrl: action.magentoUrl,
                uploaderUrl: action.uploaderUrl,
                previewUrl: action.previewUrl,
                sessionId: action.sessionId,
                designId: action.designId,
                layerId: action.layerId,
                updateId: action.updateId,
                productId: action.productId,
                storeId: action.storeId,
                storeViews: action.storeViews,
                canvasImage: action.canvasImage,
            }
        case actionTypes.SET_BORDER_LIMIT_EDITING_STATUS:
            return {
                ...state,
                isBorderLimitEditing: action.isBorderLimitEditing,
            }

        case actionTypes.SET_CUSTOM_MASK_EDITING_STATUS:
            return {
                ...state,
                isCustomMaskEditing: action.isCustomMaskEditing,
            }
        case actionTypes.PREVIEW_UPDATE_STATUS:
            return {
                ...state,
                previewShouldBeChanged: action.previewShouldBeChanged,
            }
        case actionTypes.SET_ELEMENT_LABELS: {
            return {
                ...state,
                selectedElementLabels: action.selectedElementLabels,
                lastUpdate: date.getTime()
            }
        }
        case actionTypes.SET_ELEMENT_STORE_VIEW_VALUES: {
            return {
                ...state,
                selectedElementStoreViewValues: action.selectedElementStoreViewValues,
                lastUpdate: date.getTime()
            }
        }
        case actionTypes.SET_SELECTED_ELEMENTS: {
            return {
                ...state,
                selectedElements: action.selectedElements
            }
        }
        case actionTypes.SET_ACTIVE_ELEMENT: {
            let activeElementLabels = {};
            let activeElementStoreViewValues = {};
            if (action.selectedElement !== '') {
                let elem = state.stage.find('.' + action.selectedElement)[0];
                if (elem !== undefined) {
                    let store_view_values = elem.attrs.store_view_values;
                    if (Array.isArray(store_view_values)) {
                        for (let i = 0; i < store_view_values.length; i++) {
                            activeElementStoreViewValues[store_view_values[i].store_id] = store_view_values[i].label;
                        }
                    } else {
                        // Create empty fields
                        for (let i = 0; i < state.storeViews.length; i++) {
                            activeElementStoreViewValues[state.storeViews[i].id] = "";
                        }
                    }

                    let labels = elem.attrs.quick_edit_labels;
                    if (Array.isArray(labels)) {
                        for (let i = 0; i < labels.length; i++) {
                            activeElementLabels[labels[i].store_id] = labels[i].label;
                        }
                    } else {
                        // Create empty fields
                        for (let j = 0; j < state.storeViews.length; j++) {
                            activeElementLabels[state.storeViews[j].id] = "";
                        }
                    }
                }
            }
            return {
                ...state,
                selectedElement: action.selectedElement,
                selectedElementLabels: activeElementLabels,
                selectedElementStoreViewValues: activeElementStoreViewValues,
                activeElement: action.selectedElement !== '' ? state.stage.find('.' + action.selectedElement)[0] : undefined,
                lastUpdate: date.getTime()
            }
        }
        case actionTypes.SHOW_GRID: {
            return {
                ...state,
                showGrid: action.showGrid
            }
        }
        case actionTypes.SHOW_GUIDELINE: {
            return {
                ...state,
                showGuideline: action.showGuideline
            }
        }
        case actionTypes.GET_ACTIVE_ELEMENT: {
            return {
                ...state
            }
        }
        case actionTypes.SET_ACTIVE_LAYER: {
            return {
                ...state,
                activePage: action.activePage
            }
        }
        case actionTypes.UPDATE_STATUS_TEXT: {
            return {
                ...state,
                statusText: action.statusText
            }
        }
        case actionTypes.ADD_ELEMENT_TO_PAGE: {
            let
                updatedPages = state.pages,
                activePage = action.canvasElement.activePage ? action.canvasElement.activePage : 0,
                page = updatedPages[activePage],
                canvasElement = {},
                type = action.canvasElement.type,
                data = action.canvasElement.data;

            let checkExistedNames = (elements, activePage, newName) => {
                let
                    isNameFree,
                    freeName,
                    checkElementsName = () => {
                        isNameFree = true;
                        newName = newName + 1;
                        elements.forEach((item) => {
                            if (item.name && item.name.length > 0 && 'element-' + activePage + '-' + newName === item.name) {
                                isNameFree = false;
                            }
                        });
                        if (isNameFree) {
                            freeName = 'element-' + activePage + '-' + (newName);
                        } else {
                            newName = newName + 1;
                        }
                    };
                do {
                    checkElementsName();
                }
                while (!isNameFree);
                return freeName;
            };

            let historyElementsCopy = state.historyElementsCopy;
            let newhistoryElementsCopy = state.historyElementsCopy;
            let historyActiveStep = state.historyActiveStep;
            try {
                const clone = JSON.parse(JSON.stringify(updatedPages[activePage]?.canvasElements));
                if (historyActiveStep < historyElementsCopy?.length - 1) {
                    historyActiveStep++;
                    newhistoryElementsCopy = [...historyElementsCopy, [...clone]]
                } else {
                    historyActiveStep++;
                    newhistoryElementsCopy = [...historyElementsCopy, [...clone]]
                }
                // console.log(4, historyActiveStep, newhistoryElementsCopy);
            } catch (e) {
                console.log(e)
            }

            let centerX =((state.stage.attrs.width / state.stage.attrs.scaleX) / 2)
            let centerY =((state.stage.attrs.height / state.stage.attrs.scaleY) / 2)
            canvasElement = {
                ...action.canvasElement,
                historyElementsCopy: newhistoryElementsCopy,
                historyActiveStep,
                scaleX: 1,
                scaleY: 1,
                smallBackground_image: action.canvasElement?.smallBackground_image,
                bgColor: action.canvasElement.bgColor,
                type: type === 'shape' ? data : type.toLowerCase(),
                data: data ? data : action.canvasElement.text,
                x: type === "text" ? centerX : (action.canvasElement.x ? action.canvasElement.x : centerX),
                y: type === "text" ? centerY : (action.canvasElement.y ? action.canvasElement.y : centerY),
                width: action.canvasElement.imgSize ? action.canvasElement.imgSize.width : undefined,
                height: action.canvasElement.imgSize ? action.canvasElement.imgSize.height : undefined,
                name: action.canvasElement.copy ? (checkExistedNames(page.canvasElements, activePage, action.canvasElement.name)) : (action.canvasElement.name ? action.canvasElement.name : checkExistedNames(page.canvasElements, activePage, page.canvasElements.length)),
                quick_edit_font: state.mode === 'ADMIN' ? true : (state.pages[state.activePage || 0].propertyTextFontAllowed ?? true),
                quick_edit_color: state.mode === 'ADMIN' ? true : (state.pages[state.activePage || 0].propertyTextColorAllowed ?? true),
                quick_edit_stroke: state.mode === 'ADMIN' ? true : (state.pages[state.activePage || 0].propertyTextStrokeAllowed ?? true),
                quick_edit_strokeColor: state.mode === 'ADMIN' ? true : (state.pages[state.activePage || 0].propertyTextStrokeColorAllowed ?? true),
                user_removeFromPreview: false,
                user_removeFromPrint: false,
                user_adminAccessOnly: false,
                changeable: true,
                deletable: true,
            };




            let ifHasForeground = false;
            let i = 0, length = updatedPages[activePage].canvasElements.length, firstForeground;
            for (; i <= length; i++) {
                if (updatedPages[activePage].canvasElements[i]?.user_foreground) {
                    ifHasForeground = true;
                    if (firstForeground === undefined) firstForeground = i;
                }
            }

            if (ifHasForeground) {
                updatedPages[activePage].canvasElements = [...updatedPages[activePage].canvasElements.slice(0, firstForeground), canvasElement, ...updatedPages[activePage].canvasElements.slice(firstForeground, length)]
            } else {
                updatedPages[activePage].canvasElements.push(canvasElement)
            }

            return {
                ...state,
                previewShouldBeChanged: true,
                pages: updatedPages,
                lastUpdate: date.getTime()
            }
        }
        case actionTypes.SET_NEW_ELEMENT_INDEX: {
            let
                updatedPages = state.pages,
                activePage = state.activePage;

            if (updatedPages[activePage] && updatedPages[activePage].canvasElements) {
                let canvasElements = updatedPages[activePage].canvasElements;
                let found = false;
                canvasElements.forEach((item) => {
                    if (!found && item.name === action.itemName) {
                        found = true;
                        [canvasElements[action.oldIndex], canvasElements[action.newIndex]] = [canvasElements[action.newIndex], canvasElements[action.oldIndex]];
                    }
                });
            }

            return {
                ...state,
                previewShouldBeChanged: true,
                pages: updatedPages
            }
        }
        case actionTypes.IS_ITEM_DOWNLOADING: {
            return {
                ...state,
                isItemDownloading: state.isItemDownloading + action.isItemDownloading,
                allImagesReady: state.isItemDownloading + action.isItemDownloading === 0
            }
        }

        case actionTypes.EDIT_ELEMENT_ATTRS: {
            let updatedPages = state.pages, activePage = action.activePage;
            let historyActiveStep = state.historyActiveStep;
            let historyElementsCopy = state.historyElementsCopy;
            if (updatedPages[activePage] && updatedPages[activePage].canvasElements) {
                updatedPages[activePage].canvasElements.forEach((item, i) => {
                    if (item.name === action.element) {
                        let el = updatedPages[activePage].canvasElements[i];

                        if (action.fontSize !== undefined) el.fontSize = action.fontSize.toFixed(1) * 1;
                        if (action.newText !== undefined) el.data = action.newText;
                        if (action.hasTemplateChanges !== undefined) el.hasTemplateChanges = action.hasTemplateChanges;
                        if (action.faceId !== undefined) el.faceId = action.faceId;
                        if (action.elementId !== undefined) el.elementId = action.elementId;
                        if (action.color !== undefined) el.fill = action.color;

                        if (action.bgColor !== undefined) {
                            el.bgColor = action.bgColor;
                        }
                       
                        if (action.quick_edit_stroke !== undefined) el.quick_edit_stroke = action.quick_edit_stroke;
                        if (action.smallBackground_image !== undefined) el.smallBackground_image = action.smallBackground_image;
                        if (action. isThisElemEditedByUser !== undefined) el. isThisElemEditedByUser = action. isThisElemEditedByUser;
                        if (action.quick_edit_strokeColor !== undefined) el.quick_edit_strokeColor = action.quick_edit_strokeColor;
                        if (action.deletable !== undefined) el.deletable = action.deletable;
                        if (action.imageWithoutBg !== undefined) el.imageWithoutBg = action.imageWithoutBg;
                        if (action.keepRatio !== undefined) el.keepRatio = action.keepRatio;
                        if (action.unselectable !== undefined) el.unselectable = action.unselectable;
                        if (action.user_foreground !== undefined) el.user_foreground = action.user_foreground;
                        if (action.user_isShapeHeart !== undefined) el.user_isShapeHeart = action.user_isShapeHeart;
                        if (action.shadowEnabled !== undefined) el.shadowEnabled = action.shadowEnabled;
                        if (action.elementRepeat !== undefined) el.elementRepeat = action.elementRepeat;
                        if (action.elementRepeatOval !== undefined) el.elementRepeatOval = action.elementRepeatOval;
                        if (action.shadowColor !== undefined) el.shadowColor = action.shadowColor;
                        if (action.fontStyle !== undefined) el.fontStyle = action.fontStyle;
                        if (action.textLimit !== undefined) el.textLimit = action.textLimit;
                        if (action.textMaxWidth !== undefined) el.textMaxWidth = action.textMaxWidth;
                        if (action.changeable !== undefined) el.changeable = action.changeable;
                        if (action.letterSpacing !== undefined) el.letterSpacing = action.letterSpacing;
                        if (action.flipX !== undefined) el.flipX = action.flipX;
                        if (action.fontStrokeWidth !== undefined) el.fontStrokeWidth = action.fontStrokeWidth;
                        if (action.colorFontStroke !== undefined) el.colorFontStroke = action.colorFontStroke;
                        if (action.flipY !== undefined) el.flipY = action.flipY;
                        if (action.quickEdit !== undefined) el.quickEdit = action.quickEdit;
                        if (action.transformable !== undefined) el.transformable = action.transformable;
                        if (action.user_transformable !== undefined) el.user_transformable = action.user_transformable;
                        if (action.isGlobalElementUrl !== undefined) el.isGlobalElementUrl = action.isGlobalElementUrl;
                        if (action.user_changeable !== undefined) el.user_changeable = action.user_changeable;
                        if (action.user_textTransform !== undefined) el.user_textTransform = action.user_textTransform;
                        if (action.user_quickEdit !== undefined) el.user_quickEdit = action.user_quickEdit;
                        if (action.user_removeFromPreview !== undefined) el.user_removeFromPreview = action.user_removeFromPreview;
                        if (action.user_removeFromPrint !== undefined) el.user_removeFromPrint = action.user_removeFromPrint;
                        if (action.user_adminAccessOnly !== undefined) el.user_adminAccessOnly = action.user_adminAccessOnly;
                        if (action.align !== undefined) el.align = action.align;
                        if (action.fontFamily !== undefined) el.fontFamily = action.fontFamily;
                        if (action.rotation !== undefined) el.rotation = action.rotation;
                        if (action.rotation === '0') el.rotation = 0;
                        if (action.quick_edit_labels !== undefined) el.quick_edit_labels = action.quick_edit_labels;
                        if (action.store_view_values !== undefined) el.store_view_values = action.store_view_values;
                        if (action.quick_edit_color !== undefined) el.quick_edit_color = action.quick_edit_color;
                        if (action.quick_edit_font !== undefined) el.quick_edit_font = action.quick_edit_font;
                        if (action.x !== undefined) el.x = action.x;
                        if (action.y !== undefined) el.y = action.y;
                        if (action.scaleX !== undefined) el.scaleX = action.scaleX;
                        if (action.scaleY !== undefined) el.scaleY = action.scaleY;
                        if (action.width !== undefined) el.width = action.width.toFixed(2) * 1;
                        if (action.height !== undefined) el.height = action.height.toFixed(2) * 1;
                        if (action.heightDiff !== undefined) el.heightDiff = action.heightDiff;
                        if (action.widthDiff !== undefined) el.widthDiff = action.widthDiff;
                        if (action.newImgData !== undefined) {
                            el.data = action.newImgData.imageUrl;
                            el.faceCropped = action.newImgData.faceCropped;
                            if (action.newImgData.width !== undefined) el.width = action.newImgData.width.toFixed(2) * 1;
                            if (action.newImgData.height !== undefined) el.height = action.newImgData.height.toFixed(2) * 1;
                        }
                        if (action.imageCrop !== undefined) {
                            el.cropHeight = action.imageCrop.height;
                            el.cropWidth = action.imageCrop.width;
                            el.cropX = action.imageCrop.x;
                            el.cropY = action.imageCrop.y;
                            el.newBoxX = action.imageCrop.newBoxX;
                            el.newBoxY = action.imageCrop.newBoxY;
                            el.newBoxWidth = action.imageCrop.newBoxWidth;
                            el.newBoxHeight = action.imageCrop.newBoxHeight;
                        }
                    }
                });
            }

            let newhistoryElementsCopy = historyElementsCopy
            let pages = [...updatedPages]
            if (action.historyStep !== undefined) {
                historyActiveStep = historyActiveStep + action.historyStep;

                if (historyActiveStep < 0) {
                    historyActiveStep = 0
                }
                if (historyActiveStep > historyElementsCopy?.length - 1) {
                    historyActiveStep--
                }
                const clone = JSON.parse(JSON.stringify(historyElementsCopy[historyActiveStep]));
                pages[activePage].canvasElements = clone
                // console.log(3, historyActiveStep, historyElementsCopy);
            } else if (action.historyMode !== false) {
                try {
                    const clone = JSON.parse(JSON.stringify(updatedPages[activePage]?.canvasElements));
                    if (historyActiveStep < historyElementsCopy?.length - 1) {
                        historyElementsCopy.splice(historyActiveStep + 1)
                        historyActiveStep = newhistoryElementsCopy?.length;
                        newhistoryElementsCopy = [...historyElementsCopy, [...clone]]
                        // console.log(4, historyActiveStep, newhistoryElementsCopy);
                    } else {
                        historyActiveStep++;
                        newhistoryElementsCopy = [...historyElementsCopy, [...clone]]
                        // console.log(4, historyActiveStep, newhistoryElementsCopy);
                    }
                } catch (e) {
                    // console.log(e)
                }
            }

            return {
                ...state,
                historyActiveStep,
                historyElementsCopy: newhistoryElementsCopy,
                hasTemplateChanges: action.hasTemplateChanges ?? false,
                pages,
                lastUpdate: date.getTime(),
                previewShouldBeChanged: true
            }
        }
        case actionTypes.DELETE_SELECTED_ELEMENT: {
            let
                updatedPages = state.pages,
                activePage = action.activePage;

            if (updatedPages[activePage] && updatedPages[activePage].canvasElements) {
                updatedPages[activePage].canvasElements.forEach((item, i) => {
                    if (item.name === action.element) {
                        updatedPages[activePage].canvasElements.splice(i, 1);
                    }
                });
            }

            return {
                ...state,
                pages: updatedPages,
                previewShouldBeChanged: true,
                lastUpdate: date.getTime()
            }
        }
        case actionTypes.SET_UPLOADED_IMAGES:
            return {
                ...state,
                uploadedImages: action.uploadedImages
            }
        case actionTypes.SHOW_BACKGROUND_CHANGE_POPUP:
            return {
                ...state,
                displaytype: action.displaytype
            }
        default:
            return {
                ...state
            }
    }
}

export default globals;
