import React, {Component} from 'react';
import {Image, Line, Transformer} from 'react-konva';
import DeleteImg from "../../../assets/images/67bacc637d58c80da40a5c2ec6d6f74d_icon.png"
import PencilImg from "../../../assets/images/pencil.png"
import useImage from 'use-image';
import {parse} from "query-string";
import textTransformer from "../../../helpes/textTransformer";

let DeleteImage = React.forwardRef((props, ref) => {
    let [image] = useImage(DeleteImg);
    return <Image image={image} width={50} height={50} x={props.x - 25} y={props.y - 25} ref={ref}
                  onClick={props.deleteElement}
                  onTouchStart={props.deleteElement}
    />;
});

let DeleteImageCopy = React.forwardRef((props, ref) => {
    let [image] = useImage(DeleteImg);

    if (props.deleteIcon.current) {
        let x = props.deleteIcon.current.getAbsolutePosition().x, y = props.deleteIcon.current.getAbsolutePosition().y;

        if (x < 0) {
            x = 0;
        }

        if (x > props.stage.attrs.width) {
            x = props.stage.attrs.width - 25
        }

        if (y < 0) {
            y = 0;
        }

        if (y > props.stage.attrs.height) {
            y = props.stage.attrs.height - 25
        }


        if (ref.current) {
            if (x === props.deleteIcon.current.getAbsolutePosition().x && y === props.deleteIcon.current.getAbsolutePosition().y) return null
            ref.current.setAbsolutePosition({x, y})
        }

        return <Image image={image} width={50} height={50} x={props.x} y={props.y - 25} ref={ref}
                      onClick={props.deleteElement}
                      onTouchStart={props.deleteElement}

        />;
    }

});

let PencilImage = React.forwardRef((props, ref) => {
    let [image] = useImage(PencilImg);
    return <Image image={image}
                  width={50} height={50} x={props.x} y={props.y - 25} ref={ref}
                  onClick={props.pencilElement}
                  onTouchStart={props.pencilElement}

    />;
});

let PencilImageCopy = React.forwardRef((props, ref) => {
    let [image] = useImage(PencilImg);

    if (props.pencilIcon.current) {

        let x = props.pencilIcon.current.getAbsolutePosition().x, y = props.pencilIcon.current.getAbsolutePosition().y;

        if (x < 0) {
            x = 0;
        }

        if (x > props.stage.attrs.width) {
            x = props.stage.attrs.width - 25
        }

        if (y < 0) {
            y = 0;
        }

        if (y > props.stage.attrs.height) {
            y = props.stage.attrs.height - 25
        }


        if (ref.current) {
            if (x === props.pencilIcon.current.getAbsolutePosition().x && y === props.pencilIcon.current.getAbsolutePosition().y) return null
            ref.current.setAbsolutePosition({x, y})
        }

        return <Image image={image} width={50} height={50} x={props.x} y={props.y - 25} ref={ref}
                      onClick={props.pencilElement}
                      onTouchStart={props.pencilElement}
        />;
    }

});

class ComponentTransformer extends Component {

    deleteIcon = React.createRef();
    deleteIconCopy = React.createRef();

    deleteLine = React.createRef();

    pencilLine = React.createRef();
    pencilIcon = React.createRef();
    pencilIconCopy = React.createRef();

    transformer = React.createRef();

    state = {
        deletedIconY: 0,
        pencilIconY: 0,
        stageLoaded: false,
    };

    deleteElement() {
        if (this.props.mode === "ADMIN" || this.props.activeElement?.attrs?.deletable) {
            this.props.deleteSelectedElement({
                element: this.props.selectedShapeName,
                activePage: this.props.activePage,
            });
            this.props.setActiveElement("");
        }
    }


    textChangeHandle(newText) {
        const ranges = /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/ug
        return textTransformer(this.props.pages[this.props.activePage].toolEmojiAllowed !== false ? newText.value : newText.value.replace(new RegExp(ranges, 'g'), ''), this.props.activeElement?.attrs?.user_textTransform?.value).trimLeft();
    }

    pencilElement() {
        if ((this.props.mode === "ADMIN" || this.props.activeElement?.attrs?.user_changeable) && this.props.activeElement) {
            if (this.props.activeElement.attrs.type === "image") {
                let name = this.props.selectedShapeName;
                this.props.showImageChangePopup();
                setTimeout(() => {
                    this.props.onSelectElement(name)
                }, 0)
            } else if (this.props.activeElement.attrs.type === "text") {
                if (this.props.canvasScale !== 100) {
                    this.props.onShowTextEditPopup()
                    return
                }

                let name = this.props.selectedShapeName;

                this.props.hideToolsMenu();

                let changeText = (textarea, activeEl = name, closePencilMode = false) => {
                    if (closePencilMode) {
                        if (this.deleteIcon.current) this.deleteIcon.current.opacity(1);
                        if (this.pencilIcon.current) this.pencilIcon.current.opacity(1);
                        if (this.deleteLine.current) this.deleteLine.current.opacity(1);
                        if (this.pencilLine.current) this.pencilLine.current.opacity(1);
                        if (this.transformer.current) this.transformer.current.opacity(1);
                        if (this.transformer.current) this.transformer.current.enabledAnchors(['top-left', 'top-center', 'top-right', 'middle-right', 'middle-left', 'bottom-left', 'bottom-center', 'bottom-right']);
                        if (this.props.activeElement) this.props.activeElement.opacity(1);
                    } else {
                        if (this.props.activeElement) this.props.activeElement.opacity(0);
                    }

                    if (textarea.value?.length > 0) {
                        console.log(textarea.value);
                        // this.props.onEditElement({
                        //     // newText: textarea.value,
                        //     newText: this.textChangeHandle(textarea),
                        //     element: activeEl,
                        //     activePage: this.props.activePage,
                        // });


                        // TODO: delete setTimeout
                        let el = this.props.stage.children[0].children.filter((i) => i.attrs.name === activeEl);

                        if (el) el = el[0];

                        if (el) {
                            if(closePencilMode){
                                el.opacity(1);
                            }

                            this.props.stage.children[this.props.activePage].children.forEach((item) => {
                                if ((item?.attrs.elementId === el?.attrs?.elementId
                                    && item?.attrs?.elementId !== 0
                                    && item?.attrs?.elementId !== undefined) || this.props?.selectedElements?.includes(item.attrs.name)) {



                                        if (item?.textMaxWidth) item.width(item.textMaxWidth);
                                        let {align} = item.attrs;

                                        let el = this.props.stage.children[0].children.filter((i) => i.attrs.name === activeEl);

                                        if (el) el = el[0];

                                        this.props.onEditElement({
                                            newText: this.textChangeHandle(textarea),
                                            element: item.attrs.name,
                                            activePage: this.props.activePage,
                                        });
                                        setTimeout(()=>{
                                            this.props.onEditElement({
                                                align: "left",
                                                element: item.attrs.name,
                                                historyMode: false,
                                                activePage: this.props.activePage,
                                            });

                                            this.props.onEditElement({
                                                align: align,
                                                element: item.attrs.name,
                                                historyMode: false,
                                                activePage: this.props.activePage,
                                            });

                                            switch (item.attrs.align) {
                                                case "right":
                                                    item.offsetX(item.width());
                                                    break;
                                                case "center":
                                                    item.offsetX(item.width() / 2);
                                                    if (item) {
                                                        let _this = this;
                                                        textarea.style.left = (_this.props.stage.container().offsetLeft + (el?.attrs?.x - el?.width() / 2 + ((document.querySelectorAll('#canvas')[0].getBoundingClientRect().width - _this.props.stage.getWidth()) / _this.props.stage.attrs.scaleX) / 2)) * _this.props.stage.attrs.scaleX + 'px';
                                                    }
                                                    break;
                                                default:
                                                    break;
                                            }
                                        },10)
                                }
                            });


                        }
                    } else {
                        // this.deleteElement();
                        this.props.onEditElement({
                            newText: " ",
                            element: name,
                            activePage: this.props.activePage,
                        });
                    }
                }

                let _this = this;
                let textarea = document.createElement('textarea');

                if (_this.props.activeElement) _this.props.activeElement.opacity(0);
                if (_this.deleteIcon?.current) _this.deleteIcon.current.opacity(0);
                if (_this.pencilIcon?.current) _this.pencilIcon.current.opacity(0);
                if (_this.deleteLine?.current) _this.deleteLine.current.opacity(0);
                if (_this.pencilLine?.current) _this.pencilLine.current.opacity(0);
                if (_this.transformer?.current) _this.transformer.current.opacity(0);

                this.transformer.current.enabledAnchors([]);
                _this.forceUpdate()

                let textAreaParent = document.querySelectorAll('#canvas')[0]

                var areaPosition = {
                    x: (_this.props.stage.container().offsetLeft + (_this.props.activeElement.attrs.x - _this.props.activeElement.attrs.offsetX + ((textAreaParent.getBoundingClientRect().width - _this.props.stage.getWidth()) / _this.props.stage.attrs.scaleX) / 2)) * _this.props.stage.attrs.scaleX,
                    y: (_this.props.stage.container().offsetTop + (_this.props.activeElement.attrs.y - _this.props.activeElement.getHeight() + ((textAreaParent.getBoundingClientRect().height - _this.props.stage.getHeight()) / _this.props.stage.attrs.scaleY) / 2)) * _this.props.stage.attrs.scaleY,
                };


                textAreaParent.appendChild(textarea);

                let customMath = {
                    textarea_style_width: (context) => {
                        return context.props.activeElement.width() * context.props.stage.attrs.scaleX - context.props.activeElement.padding() * context.props.stage.attrs.scaleX * 2
                    },
                    textarea_style_height: (context) => {
                        return context.props.activeElement.height() * context.props.stage.attrs.scaleX - context.props.activeElement.padding() * context.props.stage.attrs.scaleX * 2 + 5
                    },
                    textarea_style_fontSize: (context) => {
                        return context.props.activeElement.fontSize() * _this.props.stage.attrs.scaleX
                    }
                }

                textarea.value = _this.props.activeElement.text();
                textarea.style.position = 'absolute';


                if(_this.props.activeElement?.attrs?.shadowEnabled) {
                    textarea.style.textShadow = "4px 4px " + _this.props.activeElement.attrs.shadowColor;
                }
                if(_this.props.activeElement?.attrs?.strokeWidth > 0 &&  _this.props.activeElement?.attrs?.stroke){
                    textarea.style['-webkit-text-stroke'] =  _this.props.activeElement?.attrs?.strokeWidth + 'px ' +  _this.props.activeElement.attrs.stroke;
                }
                textarea.style.top = areaPosition.y + 'px';
                textarea.style.left = areaPosition.x + 'px';
                console.log("areaPosition.x", areaPosition.x);
                textarea.style.width = customMath.textarea_style_width(_this) > 50 ? customMath.textarea_style_width(_this) + 'px' : 50 + 'px';
                textarea.style.height = customMath.textarea_style_height(_this) > 20 ? customMath.textarea_style_height(_this) + 'px' : 20 + 'px';

                textarea.style.fontSize = customMath.textarea_style_fontSize(_this) > 16 ? customMath.textarea_style_fontSize(_this) + 'px' : 16 + 'px';
                textarea.style.border = 'none';
                textarea.style.padding = '0px';
                textarea.style.margin = '0px';
                textarea.style.overflow = 'hidden';

                textarea.style.textTransform = _this.props?.activeElement?.attrs?.user_textTransform?.value;

                textarea.style.background = 'none';
                textarea.style.outline = 'none';
                textarea.style.resize = 'none';
                textarea.style.lineHeight = _this.props.activeElement.lineHeight();
                textarea.style.fontFamily = _this.props.activeElement.fontFamily();
                textarea.style.transformOrigin = 'left top';
                // textarea.style.textAlign = _this.props.activeElement.align();
                textarea.style.textAlign = "left";
                textarea.style.color = _this.props.activeElement.fill();

                textarea.style.transformOrigin = "bottom " + _this.props.activeElement.attrs.align;
                let rotation = _this.props.activeElement.rotation();
                var transform = '';
                if (rotation) {
                    transform += 'rotateZ(' + rotation + 'deg) ';
                }

                var px = 0;
                // also we need to slightly move textarea on firefox
                // because it jumps a bit
                var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
                if (isFirefox) {
                    px += 2 + Math.round(_this.props.activeElement.fontSize() / 20);
                }
                transform += 'translateY(-' + px + 'px)';
                textarea.style.transform = transform;

                // reset height
                textarea.style.height = 'auto';
                // after browsers resized it we can set actual value
                textarea.style.height = textarea.scrollHeight + 3 + 'px';

                textarea.focus();

                function removeTextarea() {

                    textarea.parentNode.removeChild(textarea);
                    window.removeEventListener('touchstart', handleOutsideClick);
                    window.removeEventListener('click', handleOutsideClick);

                    if (_this.props.activeElement) {
                        _this.props.activeElement.opacity(1);
                        if (_this.deleteIcon.current) _this.deleteIcon.current.opacity(1);
                        if (_this.pencilIcon.current) _this.pencilIcon.current.opacity(1);
                        if (_this.deleteLine.current) _this.deleteLine.current.opacity(1);
                        if (_this.pencilLine.current) _this.pencilLine.current.opacity(1);

                        if (_this.transformer.current) {
                            _this.transformer.current.opacity(1);
                            _this.transformer.current.enabledAnchors(['top-left', 'top-center', 'top-right', 'middle-right', 'middle-left', 'bottom-left', 'bottom-center', 'bottom-right']);
                        }
                    }
                    _this.props.stage.children[0].children.forEach((item) => {item.opacity(1)})

                    _this.forceUpdate();

                }

                function setTextareaWidth(e) {
                    let newWidth = (e.target?.value?.length || 1) * _this.props.activeElement?.fontSize() - (_this.props.activeElement?.padding() || 0);
                    // console.log("e", e.target.value);

                    if (newWidth < 50) {
                        newWidth = 50
                    }
                    // some extra fixes on different browsers
                    var isSafari = /^((?!chrome|android).)*safari/i.test(
                        navigator.userAgent
                    );
                    var isFirefox =
                        navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
                    if (isSafari || isFirefox) {
                        newWidth = Math.ceil(newWidth);
                    }

                    var isEdge =
                        document.documentMode || /Edge/.test(navigator.userAgent);
                    if (isEdge) {
                        newWidth += 1;
                    }


                    textarea.style.width = newWidth + 'px';

                }

                textarea.addEventListener('keydown', function (e) {
                    // hide on enter
                    // but don't hide on shift + enter
                    console.log(e);
                    if ((e.keyCode === 13 && !e.shiftKey) || e.keyCode === 27 ) {
                        // _this.props.activeElement.text(textarea.value);
                        changeText(textarea, name, true);
                        if (_this.props.activeElement) _this.props.activeElement.opacity(1);
                        removeTextarea();
                    } else {
                        // changeText(textarea, name);
                    }

                    setTextareaWidth(e);
                    textarea.style.height = 'auto';
                    textarea.style.height =
                        textarea.scrollHeight + _this.props.activeElement?.fontSize() + 'px';

                    console.log('keydown')

                });

                textarea.addEventListener('keyup', function (e) {
                    // hide on enter
                    // but don't hide on shift + enter
                    console.log(e);

                    if ((e.keyCode !== 13) &&  e.keyCode !== 27 ) {
                        // _this.props.activeElement.text(textarea.value);
                        changeText(textarea, name, false);
                        // if (_this.props.activeElement) _this.props.activeElement.opacity(1);
                        // removeTextarea();
                    }

                    setTextareaWidth(e);
                    textarea.style.height = 'auto';
                    textarea.style.height =
                        textarea.scrollHeight + _this.props.activeElement?.fontSize() + 'px';

                    console.log('keyup')
                });


                function handleOutsideClick(e) {
                    console.log(textarea.value);
                    if (e.target !== textarea) {
                        // _this.props.activeElement.text(textarea.value);
                        // if (_this.props.activeElement) _this.props.activeElement.opacity(1);
                        // changeText(textarea, name, true);



                        removeTextarea();
                    }
                }

                setTimeout(() => {
                    window.addEventListener('touchstart', handleOutsideClick);
                    window.addEventListener('click', handleOutsideClick);
                }, 0);


            }
        }
    }

    componentDidMount() {
        this.checkNode();
        this.transformer.current.on('dblclick', () => {
            console.log(123);
        })


    }

    componentDidUpdate() {
        if (this.deleteIcon.current && this.deleteLine.current)
            if (this.props.elementScaling) {
                this.deleteIcon.current.hide();
                this.deleteLine.current.hide();
            } else if (this.props.activeElement?.attrs?.deletable) {
                this.deleteIcon.current.show();
                this.deleteLine.current.show();
            }

        if (this.pencilIcon.current && this.pencilLine.current)
            if (this.props.elementScaling) {
                this.pencilIcon.current.hide();
                this.pencilLine.current.hide();
            } else if (this.props.activeElement?.attrs?.user_changeable) {
                this.pencilLine.current.show();
                this.pencilIcon.current.show();
            }
        this.checkNode();

        if (this.props.stage && !this.state.stageLoaded) {

            if (!this.state.stageLoaded) {
                this.setState({
                    stageLoaded: true
                })
            }

            this.props.stage.on("dblclick", (e) => {
                this.pencilElement();
            })
        }
    }

    checkNode() {
        // here we need to manually attach or detach Transformer node
        const stage = this.transformer.current.getStage();
        const {selectedShapeName} = this.props;

        if (stage !== undefined) {
            const selectedNode = stage.findOne('.' + selectedShapeName);

            // do nothing if selected node is already attached
            if (selectedNode === this.transformer.current.node()) {
                return;
            }

            if (this.props.mode !== 'ADMIN') {
                if (selectedNode !== undefined) {
                    if (!selectedNode.attrs.changeable) {
                        return false;
                    }
                }
            }

            if (selectedNode) {
                // attach to another node
                this.transformer.current.attachTo(selectedNode);
                this.transformer.current.anchorStroke("black");
                this.transformer.current.borderStroke('black');
                this.transformer.current.borderDash([3, 3]);
                this.transformer.current.rotationSnaps([0, 90, 180, 270]);
                this.transformer.current.enabledAnchors(['top-left', 'top-center', 'top-right', 'middle-right', 'middle-left', 'bottom-left', 'bottom-center', 'bottom-right']);
                if (this.props?.activeElement?.attrs?.type === "text" || this.props?.activeElement?.attrs?.type === "image") this.transformer.current.enabledAnchors(['top-left', 'top-right', 'bottom-left', 'bottom-right']);

                this.setState({
                    deletedIconY: this.transformer.current.getHeight(),
                    pencilIconY: this.transformer.current.getHeight()
                });

                if (this.transformer.current && this.deleteIcon.current && this.deleteLine.current) {
                    this.transformer.current.add(this.deleteIcon.current);
                    this.transformer.current.add(this.deleteLine.current);
                }

                if (this.transformer.current && this.pencilIcon.current && this.pencilLine.current) {
                    this.transformer.current.add(this.pencilLine.current);
                    this.transformer.current.add(this.pencilIcon.current);
                }

                this.transformer.current.boundBoxFunc((oldBox, newBox) => {
                    let activeEl = this.props.activeElement;
                    if (activeEl?.attrs.textMaxWidth !== 0 && newBox.width >= activeEl?.attrs.textMaxWidth) {
                        newBox = oldBox;
                    }

                    this.setState({
                        deletedIconY: newBox.height,
                        pencilIconY: newBox.height,
                    });


                    let x = newBox.x + (activeEl?.attrs?.offsetX?? 0) - (newBox.width - (activeEl?.attrs?.width?? 0));
                    let y = newBox.y + (activeEl?.attrs?.offsetY?? 0) - (newBox.height - (activeEl?.attrs?.height?? 0));


                    this.props.onEditElement({
                        scaleX: 1,
                        scaleY: 1,
                        y,
                        x,
                        fontSize: activeEl?.attrs.type === 'text' ? activeEl?.attrs.fontSize * activeEl?.attrs.scaleX : activeEl?.attrs.fontSize,
                        width: newBox.width > 50 ? newBox.width : 50,
                        height: newBox.height > 10 ? newBox.height : 50,
                        element: activeEl?.attrs.name,
                        activePage: this.props.activePage,
                    });

                    let item = this.props.activeElement;
                    if (item.attrs.type === "text") {
                        setTimeout(() => {
                            switch (item.attrs.align) {
                                case "right":
                                    item.x(item.x() + item.width() - item.offsetX())
                                    item.offsetX(item.width());
                                    break;
                                case "center":
                                    item.x(item.x() + (item.width() / 2 - item.offsetX()))
                                    item.offsetX(item.width() / 2);
                                    break;
                                default:
                                    item.offsetX(0);
                                    break;
                            }
                        }, 50)
                    }


                    if (newBox.width > 5) {

                        return newBox
                    } else {
                        return oldBox;
                    }


                });
            } else {
                // remove transformer
                this.transformer.current.detach();
            }

            this.transformer.current.getLayer().batchDraw();
        }
    }

    render() {
        let resizeEnabled = this.props.canvasScale === 100;
        let rotateEnabled = this.props.canvasScale === 100;

        /* For ADMIN all elements should be transformable */
        if (this.props.mode !== 'ADMIN') {
            if (this.props.stage) {
                let selectedElement = this.props.stage.findOne('.' + this.props.selectedShapeName);
                if (selectedElement !== undefined) {
                    let selectedElementAttributes = selectedElement.attrs;

                    /* Check if element is transformable -> move, resize, rotate */
                    if (!selectedElementAttributes.transformable) {
                        resizeEnabled = false;
                        rotateEnabled = false;
                    }
                }
            }
        }

        return (
            <React.Fragment>
                <Transformer
                    resizeEnabled={resizeEnabled}
                    rotateEnabled={rotateEnabled}
                    ignoreStroke='true'
                    transformEnd={this.transformEnd}
                    ref={this.transformer}
                />

                {(this.props.activeElement?.attrs && this.transformer.current && this.transformer.current.getStage() && this.transformer.current.getStage().findOne('.' + this.props.selectedShapeName) !== undefined && (this.props.activeElement?.attrs?.deletable || this.props.mode === "ADMIN")) && !this.props.isShowOrderPreviewPopup && parseInt(this.props.robot) !== 1 ?
                    <React.Fragment>
                        <Line points={[-33, 0, -5, 0]}
                              y={((this.props?.activeElement?.getHeight() * this.props?.activeElement?.scaleY())?? this.state.deletedIconY) / 2}
                              dash={[3, 3]}
                              stroke={"black"}
                              strokeWidth={1}
                              radius={10} fill={"red"}
                              ref={this.deleteLine}/>
                        <DeleteImage
                            ref={this.deleteIcon}
                            transformer={this.transformer?.current}
                            x={this.props.deletedIconX}
                            y={((this.props?.activeElement?.getHeight() * this.props?.activeElement?.scaleY())?? this.state.deletedIconY) / 2}
                            deleteElement={this.deleteElement.bind(this)}/>
                        {this.props.multiface_elements_admin_only === 1 ? null : <DeleteImageCopy
                            deleteIcon={this.deleteIcon}
                            stage={this.props.stage}
                            ref={this.deleteIconCopy}
                            x={this.props.deletedIconX}
                            y={((this.props?.activeElement?.getHeight() * this.props?.activeElement?.scaleY())?? this.state.deletedIconY) / 2}
                            deleteElement={this.deleteElement.bind(this)}/>}
                    </React.Fragment>
                    : null
                }
                {this.props.activeElement?.attrs && (this.props.activeElement?.attrs?.user_changeable && (this.props.activeElement?.attrs.type === 'image' || this.props.activeElement?.attrs.type === 'text')) && !this.props.isShowOrderPreviewPopup && parseInt(this.props.robot) !== 1 ?
                    <>
                        <Line
                            points={[this.props?.activeElement?.getWidth(), 0, this.props?.activeElement?.getWidth() + 33, 0]}
                            y={((this.props?.activeElement?.getHeight() * this.props?.activeElement?.scaleY())?? this.state.pencilIconY) / 2}
                            dash={[3, 3]}
                            stroke={"black"}
                            strokeWidth={1}
                            radius={10}
                            ref={this.pencilLine}/>
                        <PencilImage
                            ref={this.pencilIcon}
                            x={this.props.pencilIconX || this.props?.activeElement?.getWidth() + 33}
                            y={((this.props?.activeElement?.getHeight() * this.props?.activeElement?.scaleY())?? this.state.pencilIconY) / 2}
                            pencilElement={this.pencilElement.bind(this)}/>
                        {this.props.multiface_elements_admin_only === 1 ? null : <PencilImageCopy
                            pencilIcon={this.pencilIcon}
                            stage={this.props.stage}
                            ref={this.pencilIconCopy}
                            x={this.props.deletedIconX}
                            y={((this.props?.activeElement?.getHeight() * this.props?.activeElement?.scaleY())?? this.state.deletedIconY) / 2}
                            pencilElement={this.pencilElement.bind(this)}/>
                        }

                    </> : null
                }

            </React.Fragment>
        );
    }

}

export default ComponentTransformer;




