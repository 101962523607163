import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../../../store/actions/index';
import clsx from "clsx";
import stl from "./ToolsViews.module.scss";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

import textIcon from '../../../assets/images/Type.png';
import textWhiteIcon from '../../../assets/images/Type_white.png';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import Translate from "react-translate-component";

class ToolsElemenstList extends Component {
    state = {
        activeLayer: 0,
    };

    changeActiveLayer(index) {
        this.props.setActivePage(index);
    }

    componentDidMount() {
        // window.addEventListener('keypress', (event) => {
        //     const keyName = event.key;
        //
        //     alert('keypress event\n\n' + 'key: ' + keyName);
        // });
    }

    changeOrder(itemName, oldIndex, newIndex) {
        if (!this.props.pages[this.props.activePage]?.canvasElements[newIndex]?.deletable && this.props.mode !== 'ADMIN') return
        this.props.setNewElementIndex({
            itemName,
            oldIndex,
            newIndex
        });
        this.forceUpdate();
    }

    onItemSetActive(e, item, index) {

        if (this.props?.activeElement?.attrs.type === item.type && e.shiftKey && this.props.selectedElements.length === 1) {
            let index2 = index;
            let newElements = [];
            this.props.setSelectedElements(Array.from(new Set([item.name, ...this.props.selectedElements])));

            this.props.pages[this.props.activePage].canvasElements.forEach((item2, i) => {
                if (this.props.mode !== 'ADMIN') return null
                if (this.props.selectedElements[0] === item2.name) {
                    index2 = i;
                    this.props.pages[this.props.activePage].canvasElements.forEach((item3, j) => {
                        if (j >= Math.min(index, index2) && j <= Math.max(index, index2) && item3.type === item2.type) {
                            newElements.push(item3.name)
                        }
                    });

                    this.props.setSelectedElements(Array.from(new Set([...newElements, ...this.props.selectedElements])));
                }
            });

        } else if (this.props?.activeElement?.attrs.type === item.type && e.ctrlKey) {
            let itemIndex = this.props.selectedElements.indexOf(item.name);
            if (itemIndex !== -1) {
                this.props.setSelectedElements([...this.props.selectedElements.slice(0, itemIndex), ...this.props.selectedElements.slice(itemIndex+1, this.props.selectedElements.length)]);
            } else {
                this.props.setSelectedElements(Array.from(new Set([item.name, ...this.props.selectedElements])));
            }
        } else {
            this.props.setActiveElement(item.name);
            this.props.setSelectedElements([item.name]);
        }

    }

    removeListElement = async (element) => {
        const {deleteSelectedElement, setActiveElement} = this.props;
        await deleteSelectedElement({
            element: element.key || element.name,
            activePage: this.props.activePage,
        });
        await setActiveElement("");
    };

    fixBackgroundName(name) {
        return name === "Background" ? "Design" : (name === "Background2" ? "Background" : name)
    }

    getElements() {
        if (this.props.pages && this.props.pages[this.props.activePage]) {
            let canvasElements = this.props.pages[this.props.activePage].canvasElements;
            if (this.props.isItemDownloading > 0) return null
            return canvasElements.map((item, index) => {
                if (item.deletable === false && this.props.mode !== 'ADMIN' && item.name !== 'undefined') return null

                if (item.name === this.props.selectedElement && item.user_foreground) {
                    this.props.setNewElementIndex({
                        element: this.props.selectedElement,
                        oldIndex: index,
                        newIndex: this.props.pages[this.props.activePage].canvasElements.length - 1
                    });
                    [canvasElements[this.props.pages[this.props.activePage].canvasElements.length - 1], canvasElements[index]] = [canvasElements[index], canvasElements[this.props.pages[this.props.activePage].canvasElements.length - 1]]
                }
                if (item && Object.keys(item).length !== 0 && item.name !== "background") {
                    let text;
                    if (item.type === "text" && (item.data || item.name)) {
                        try {
                            text = decodeURIComponent(item.data) || item.name
                        } catch (e) {
                            text = item.data || item.name
                        }
                    }

                    return (
                        <Draggable
                            key={item.name}
                            draggableId={item.name}
                            index={index}
                        >
                            {provided => (
                                <div
                                    className={clsx(stl.element, this.props.selectedElements.includes(item.name) && stl["element--active"])}
                                    key={item.name}
                                    onClick={(e) => this.onItemSetActive(e, item, index)}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}

                                >
                                    <div className={stl.element__arrowsWrap}
                                    >
                                        {index !== 1 && (item.user_foreground === false || item.user_foreground === undefined) ?
                                            <FontAwesomeIcon icon="arrow-up"
                                                             className={stl.element__arrowUp}
                                                             onClick={this.changeOrder.bind(this, item.name, index, index - 1)}/>
                                            : null}
                                        {canvasElements.length !== index + 1 && (canvasElements[index + 1].user_foreground === false || canvasElements[index + 1].user_foreground === undefined) ?
                                            <FontAwesomeIcon icon="arrow-down"
                                                             className={stl.element__arrowDown}
                                                             onClick={this.changeOrder.bind(this, item.name, index, index + 1)}/>
                                            : null}
                                    </div>
                                    {item.type === "image" ? (
                                        <div className={stl.element__inner}>
                                            {item.data && item.bgColor === false ? (
                                                <div className={stl.element__img}>
                                                    <img src={item.data} alt=""/>
                                                </div>
                                            ) : null}
                                            <strong className={stl.element__title}>
                                                {this.fixBackgroundName(item.name)}
                                            </strong>
                                        </div>
                                    ) : null}

                                    {item.type === "text" && (item.data || item.name) ? (
                                        <div className={stl.element__inner}>
                                            {item.data ? (
                                                <div
                                                    className={clsx(stl.element__icon, stl["element__icon--text"])}>
                                                    {/*for hover effect*/}
                                                    <img src={textIcon} alt=""/>
                                                    <img src={textWhiteIcon} alt=""/>
                                                </div>
                                            ) : null}
                                            <strong className={stl.element__title}>
                                                {/*{decodeURIComponent(item.data) || item.name}*/}
                                                {text}
                                                <span className={stl["element__title--description"]}>
                                            <Translate content={"Custom Text"}/>
                                        </span>
                                            </strong>
                                        </div>
                                    ) : null}

                                    {item.type === "icon" && item.name ? (
                                        <div className={stl.element__inner}>
                                            {item.data ? (
                                                <div className={stl.element__img}>
                                                    <img src={item.data} alt=""/>
                                                </div>
                                            ) : null}
                                            <strong className={stl.element__title}>
                                                {item.name}
                                            </strong>
                                        </div>
                                    ) : null}
                                    {(item.type === "square" || item.type === "line" || item.type === "circle" || item.type === "triangle") && item.name ? (
                                        <div className={stl.element__inner}>
                                            <strong className={stl.element__title}>
                                                {item.type + " -- " + item.name}
                                            </strong>
                                        </div>
                                    ) : null}
                                    <div className='designer-element-list-trash'
                                         onClick={() => this.removeListElement(item)}>
                                        <FontAwesomeIcon icon="trash"/>
                                    </div>
                                    {provided.placeholder}
                                </div>
                            )}
                        </Draggable>
                    )
                } else return null;
            });
        }
        return <div className={stl.uploader__title}>
            <span className={stl.uploader__text}>No items yet</span>
        </div>
    }


    onDragEnd = async (result) => {
        const {updateCanvasElement} = this.props;
        const {destination, source} = result;
        if (destination) {
            const {index: destinationIndex} = destination;
            const {index: sourceIndex} = source;
            if (this.props.pages && this.props.pages[this.props.activePage]) {
                let canvasElements = this.props.pages[this.props.activePage].canvasElements;
                const result = Array.from(canvasElements);
                const [removed] = result.splice(sourceIndex, 1);
                result.splice(destinationIndex, 0, removed);
                updateCanvasElement(result);
                this.props.pages[this.props.activePage].canvasElements = result;
            }
        }
    }


    render() {
        return (this.props.showActiveElementsList ?
                <div className="designer-tools-uploader">
                    <div className={stl.uploader}>
                        {this.props.pages.map((item, index) => {
                            return <div key={index}>

                                <div
                                    className={clsx(stl.dropdown, this.props.activePage === index && stl["dropdown--active"])}
                                    onClick={this.changeActiveLayer.bind(this, index)}>
                                    <div className={stl.dropdown__title}
                                    >
                                        <Translate content={"view"}/> {index}
                                    </div>
                                </div>
                                <div key={index}>
                                    {
                                        this.props.activePage === index ?
                                            (
                                                <DragDropContext onDragEnd={this.onDragEnd}>
                                                    <Droppable droppableId="elementList" type="app">
                                                        {provided => (
                                                            <div
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}>
                                                                {this.getElements()}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            ) : null
                                    }
                                </div>
                            </div>
                        })}
                    </div>
                    {/*<div className={stl.tools__btn}>*/}
                    {/*    <Button theme="dark" size="sm" onClick={this.props.hideToolsMenu}>*/}
                    {/*        <Translate content={"Close"}/>*/}
                    {/*    </Button>*/}
                    {/*</div>*/}
                </div>
                : null
        )
    }
}

const
    mapStateToProps = state => {
        return {
            isItemDownloading: state.glb.isItemDownloading,
            pages: state.glb.pages,
            activePage: state.glb.activePage,
            showActiveElementsList: state.tol.showActiveElementsList,
            selectedElement: state.glb.selectedElement,
            mode: state.glb.mode,
            stage: state.glb.stage,
            selectedElements: state.glb.selectedElements,
            activeElement: state.glb.activeElement,
        };
    };

const
    mapDispatchToProps = dispatch => {
        return {
            deleteSelectedElement: (data) => dispatch(actionCreators.deleteSelectedElement(data)),
            setNewElementIndex: (data) => dispatch(actionCreators.setNewElementIndex(data)),
            hideToolsMenu: () => {
                document.querySelectorAll("body")[0].style.overflow = 'auto'
                dispatch(actionCreators.hideToolsMenu())
            },
            setActiveElement: (element) => dispatch(actionCreators.setActiveElement(element)),
            setSelectedElements: (elements) => dispatch(actionCreators.setSelectedElements(elements)),
            setActivePage: (activePage) => dispatch(actionCreators.setActivePage(activePage)),
            updateCanvasElement: (canvasElements) => dispatch(actionCreators.updateCanvasElement(canvasElements)),
        };
    };

export default connect(mapStateToProps, mapDispatchToProps)

(
    ToolsElemenstList
)
;
