import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import ImgUploader from "../Tools/ToolsViews/ImgUploader";
import Translate from "react-translate-component";
import { Button } from "shards-react";
import close_btn from "../../assets/images/iconfinder_Close_6351931 2.png";

class ImageChangePopup extends Component {
    render() {
        if (this.props.isShowImageChangePopup) {

            return (<div className="text-form-popup-content">
                <div className="text-form-popup-overlay" onClick={ () => this.props.pages[this.props.activePage].multiface_elements_admin_only !==1 ? this.props.hidePopup() : ''}/>
                {/*<div className="text-form-popup-overlay" onClick={this.props.hidePopup}/>*/}
                <div  className={`text-form-popup-content-inner ${this.props.pages[this.props.activePage].multiface_elements_admin_only !== 1  ? '' : 'faceCropMultifaceWrap'}`}>
                {/*<div  className={`text-form-popup-content-inner`}>*/}

                    {this.props.pages[this.props.activePage].multiface_elements_admin_only !==  1 ? <div className="text-form-popup-content-title__wrap">
                        <div className="text-form-popup-back"></div>
                        <div className="text-form-popup-content-title">
                            <Translate content={"Change Image"}/>
                        </div>
                        <div className="text-form-popup-close"
                             onClick={() => this.props.hidePopup()}>
                            <img src={close_btn} alt=""/>
                        </div>
                    </div> : null}
                    <div>
                        <ImgUploader uploaderUrl={this.props.uploaderUrl} hidePopup={this.props.hidePopup}/>
                    </div>
                    {
                        this.props.pages[this.props.activePage]?.multiface_elements_admin_only !== 1 || this.props.mode === "ADMIN" ? <div className="text-form-popup-content-buttons">
                            <Button outline theme="secondary" onClick={this.props.hidePopup}>
                                <Translate content={"Cancel"}/>
                            </Button>
                        </div> : null
                    }

                </div>
            </div>)
        } else {
            return null
        }
    }
}

const mapStateToProps = state => {
    return {
        mode: state.glb.mode,
        pages: state.glb.pages,
        isShowImageChangePopup: state.tol.showImageChangePopup,
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditImageElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data));
            dispatch(actionCreators.hidePopup());
        },
        hidePopup: () => {
            dispatch(actionCreators.hidePopup());
        },

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageChangePopup);
