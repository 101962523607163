import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';

import ImgUploader from './ToolsViews/ImgUploader';
import Text from "./ToolsViews/Text";
import Shapes from "./ToolsViews/Shapes";
import ElemenstList from "./ToolsViews/ElementsList";
import Emoji from "./ToolsViews/Emoji";
import Backgrounds from "./ToolsViews/Backgrounds";
import Backgrounds2 from "./ToolsViews/Backgrounds3";

import expand_more from '../../assets/images/expand-more.png';
import emoji_img from '../../assets/images/Frame 157.svg';
import shape_icon from '../../assets/images/Frame 185.svg';
import layers_icon from '../../assets/images/Frame 186.svg';

import stl from "./Tools.module.scss"
import clsx from "clsx";
import Translate from "react-translate-component";

import {DESKTOP_WIDTH} from "../../config";
import Switch from "react-switch";
import ShapesCustomMasks from "./ToolsViews/ShapesCustomMasks";

class Tools extends Component {
    state = {
        toolsMoreShow: false
    }

    showOnMobileToggle() {
        this.props.onSelectElement('');

        this.props.toolsMenuVisibleToggle({mode: !this.props.ifToolsMenuVisible});

    }

    handleSubmenuAllowed() {
        this.props.userAllowedTools({
            [this.activeTool()]: !this.props[this.activeTool()]
        });
    }

    handlePropertyChange(e, status) {
        this.props.setUserAllowedProperties({
            [e]: status
        });
    }

    activeTool() {
        if (this.props.showTextForm) {
            return 'toolTextAllowed'
        } else if (this.props.showImageUploadForm) {
            return 'toolImagesAllowed'
        } else if (this.props.showEmojiForm) {
            return 'toolEmojiAllowed'
        } else if (this.props.showShapesForm) {
            return 'toolShapesAllowed'
        }
    }

    componentWillUnmount() {
        document.querySelectorAll("body")[0].style.overflow = 'auto'
    }

    componentDidMount() {
        const {toolTextAllowed, toolImagesAllowed, toolEmojiAllowed, toolShapesAllowed} = this.props.pages[0]
        this.props.userAllowedTools({
            'toolTextAllowed': toolTextAllowed ?? true,
            'toolImagesAllowed': toolImagesAllowed ?? true,
            'toolEmojiAllowed': toolEmojiAllowed ?? true,
            'toolShapesAllowed': toolShapesAllowed ?? true,
        });

        const {
            propertyTextStrokeAllowed,
            propertyTextStrokeColorAllowed,
            propertyTextShadowColorAllowed,
            propertyTextShadowAllowed,
            propertyTextColorAllowed,
            propertyTextFontAllowed
        } = this.props.pages[this.props?.activePage ?? 0]

        this.props.setUserAllowedProperties({
            'propertyTextStrokeAllowed': propertyTextStrokeAllowed ?? true,
            'propertyTextStrokeColorAllowed': propertyTextStrokeColorAllowed ?? true,
            'propertyTextShadowAllowed': propertyTextShadowAllowed ?? true,
            'propertyTextShadowColorAllowed': propertyTextShadowColorAllowed ?? true,
            'propertyTextColorAllowed': propertyTextColorAllowed ?? true,
            'propertyTextFontAllowed': propertyTextFontAllowed ?? true,
        });
    }

    ifShowAllItems() {
        return this.props.ifToolsMenuVisible && !this.props.showEmojiForm && !this.props.showShapesForm && !this.props.showActiveElementsList && !this.props.showImageUploadForm && !this.props.showTextForm
    }

    renderToolsBtn = (backgrounds, colors, pageBackgrounds) => {

        let imageExisted = this.props.pages[this.props.activePage].canvasElements.find((r) => {
            return r.type === "image" && r.name !== "Background2" && r.name !== "Background" && r.unselectable !== true
        });

        return (
            <>
                {!this.props.isCustomMaskEditing?.status && (this.props.toolImagesAllowed || imageExisted || this.props.mode === "ADMIN") ? (
                    <button
                        className={clsx(stl.tools__item, this.props.showImageUploadForm && stl["tools__item--active"])}
                        onClick={() => {
                            if (this.props.showImageUploadForm) {
                                this.props.hideToolsMenu()
                            } else {
                                let isImage = this.props.pages[this.props.activePage].canvasElements.find((r) => {
                                    return r.type === "image" && r.name !== "Background2" && r.name !== "Background" && r.unselectable !== true
                                });
                                if (isImage && this.props.activeElement?.attrs.type !== "image") this.props.onSelectElement(isImage.name);
                                this.props.onShowUploadForm()
                            }
                            // this.props.showImageChangePopup()

                            this.setState({
                                toolsMoreShow: false
                            });
                        }}>

                        <span className={stl.tools__itemText}>
                                    <Translate content={"image"}/>
                        </span>
                    </button>
                ) : null}

                {((colors.length > 0 || pageBackgrounds.length > 0) && !this.props.isCustomMaskEditing?.status) ?
                    <button
                        className={clsx(stl.tools__item, this.props.showBackgroundsForm && stl["tools__item--active"])}
                        onClick={() => {
                            if (this.props.showBackgroundsForm) {
                                this.props.hideToolsMenu()
                            } else {
                                this.props.onShowBackgroundsForm()
                            }
                            this.setState({
                                toolsMoreShow: false
                            });
                        }}>

                        <span className={stl.tools__itemText}>
										<Translate content="BackgroundsTools"/>
									</span>
                    </button>
                    : null}

                {((backgrounds.length > 0 && !this.props.isCustomMaskEditing?.status) ?
                    <button
                        className={clsx(stl.tools__item, this.props.showDesignsForm && stl["tools__item--active"])}
                        onClick={() => {
                            if (this.props.showDesignsForm) {
                                this.props.hideToolsMenu()
                            } else {
                                this.props.onShowDesignsForm()
                            }
                            this.setState({
                                toolsMoreShow: false
                            });
                        }}>
                        <span className={stl.tools__itemText}>
										Design
									</span>
                    </button>
                    : null)}

                {((this.props.toolTextAllowed) || this.props.mode === 'ADMIN') && !this.props.isCustomMaskEditing?.status ? (
                    <button
                        className={clsx(stl.tools__item, this.props.showTextForm && stl["tools__item--active"])}
                        onClick={() => {
                            if (this.props.showTextForm) {
                                this.props.hideToolsMenu()
                            } else {
                                let isText = this.props.pages[this.props.activePage].canvasElements.find((r) => {
                                    return r.type === "text" && r.changeable
                                });
                                if (isText && this.props.activeElement?.attrs.type !== "text") this.props.onSelectElement(isText.name);
                                this.props.onShowTextForm()
                            }
                            this.setState({
                                toolsMoreShow: false
                            });
                        }}>
                        <span className={stl.tools__itemText}>
                                     <Translate content={"Text"}/>
                                </span>
                    </button>
                ) : null}

                {!this.props.isCustomMaskEditing?.status ? (
                    <>
                        <button
                            className={clsx(stl.tools__item, this.state.toolsMoreShow && stl["tools__item--active"])}
                            onClick={() => {
                                this.props.hideToolsMenu()
                                this.setState({
                                    toolsMoreShow: !this.state.toolsMoreShow
                                });
                            }}>
                        <span className={stl.tools__itemText}>
                             <Translate content={"More"}/>
                        </span>
                        </button>
                        <img
                            className={clsx(stl.tools__itemTextExpand, this.state.toolsMoreShow && stl['tools__itemTextExpand--active'])}
                            src={expand_more} alt=""
                            onClick={() => {
                                this.props.hideToolsMenu()
                                this.setState({
                                    toolsMoreShow: !this.state.toolsMoreShow
                                });
                            }}/>
                    </>

                ) : null}

                <div className={clsx(stl.tools__more, this.state.toolsMoreShow && stl.tools__more_active)}>
                    {((this.props.toolEmojiAllowed) || this.props.mode === 'ADMIN') && !this.props.isCustomMaskEditing?.status ? (
                        <button
                            className={clsx(stl.tools__item, this.props.showEmojiForm && stl["tools__item--active"])}
                            onClick={() => {
                                this.setState({
                                    toolsMoreShow: false
                                });
                                this.props.onShowEmojiForm()
                            }}>

                        <span className={stl.tools__itemText}>
                                    <img src={emoji_img} alt=""/>
                                    <Translate content={"emoji"}/>
                                </span>
                        </button>
                    ) : null}

                    {(this.props.toolShapesAllowed) || this.props.mode === 'ADMIN' ? (
                        <button
                            className={clsx(stl.tools__item, this.props.showShapesForm && stl["tools__item--active"])}
                            onClick={() => {
                                this.setState({
                                    toolsMoreShow: false
                                });
                                this.props.onShowShapesForm()
                            }}>
                        <span className={stl.tools__itemText}>
                            <img src={shape_icon} alt=""/>
                                    <Translate content={"shape"}/>
                                </span>
                        </button>
                    ) : null}

                    {!this.props.isCustomMaskEditing?.status ? <button
                        className={clsx(stl.tools__item, this.props.showActiveElementsList && stl["tools__item--active"])}
                        onClick={() => {
                            this.setState({
                                toolsMoreShow: false
                            });
                            this.props.onShowElementsList()
                        }}>
                    <span className={stl.tools__itemText}>
                        <img src={layers_icon} alt=""/>
                                    <Translate content={"layers"}/>
                                </span>
                    </button> : null}
                </div>
            </>
        )
    }

    render() {
        let activePage = this.props.pages[this.props.activePage];
        // Load page
        let backgrounds = [];
        let pageBackgrounds = activePage.designs;
        for (let key in pageBackgrounds) {
            if (!pageBackgrounds.hasOwnProperty(key)) continue;
            backgrounds.push(key);
        }

        let colors = [];
        for (let key in activePage.backgrounds) {
            let item = activePage.backgrounds[key]
            if (activePage.backgrounds[key].type === 'color') {
                colors.push(item.color_code);
            }
        }

        // let toolsCount = 2;
        // if (this.props.pages[0].toolTextAllowed || this.props.pages[0].toolTextAllowed === undefined) toolsCount = toolsCount + 1
        // if (this.props.pages[0].toolImagesAllowed || this.props.pages[0].toolImagesAllowed === undefined) toolsCount = toolsCount + 1
        // if (this.props.pages[0].toolEmojiAllowed || this.props.pages[0].toolEmojiAllowed === undefined) toolsCount = toolsCount + 1
        // if (this.props.pages[0].toolShapesAllowed || this.props.pages[0].toolShapesAllowed === undefined) toolsCount = toolsCount + 1
        // if (backgrounds.length > 0) toolsCount = toolsCount + 1
        // if (colors.length > 0) toolsCount = toolsCount + 1
        // if (this.props.mode === 'ADMIN') toolsCount = 6 + (backgrounds.length > 0 ? 1 : 0)


        let subMenuActiveType;
        if (this.props.showTextForm) {
            subMenuActiveType = "texts"
        } else if (this.props.showImageUploadForm) {
            subMenuActiveType = "images"
        } else if (this.props.showEmojiForm) {
            subMenuActiveType = "emoji"
        } else if (this.props.showShapesForm) {
            subMenuActiveType = "shapes"
        }

        return (
            <div ref={"tools"} className={clsx(stl.tools, stl["tools--active"])}
                // style={this.ifShowAllItems() ? (window.innerWidth >= 620 ? {'top': 645 - 54 * toolsCount} : {'top': window.innerWidth + 50 - 54 * toolsCount}) : {}}>
            >
                <div
                    className={clsx(stl.tools__content, this.props.ifToolsMenuVisible ? stl["tools__content--reverse"] : stl["tools__content--color"])}>
                    {(this.props.ifToolsMenuVisible ? (
                        // <div className="tools--active--menu tools--active--menu--elements">
                        <div
                            className={clsx(stl["tools--menu--elements"], backgrounds.length > 0 && stl["tools--menu--elements-bg"])}>
                            {this.renderToolsBtn(backgrounds, colors, pageBackgrounds)}
                        </div>
                    ) : (
                        this.renderToolsBtn(backgrounds, colors, pageBackgrounds)
                    ))}
                </div>

                <div>
                    {
                        this.props.isShowImageChangePopup && !this.props.showImageUploadForm ? null :
                            <ImgUploader mode={'123'} uploaderUrl={this.props.uploaderUrl}/>
                    }
                    <ElemenstList/>
                    <Text/>
                    <Shapes/>
                    <Emoji/>
                    <ShapesCustomMasks/>

                    {!this.props.isCustomMaskEditing?.status ? <>
                        <Backgrounds/>
                        <Backgrounds2/>
                    </> : null}


                    {(this.props.showTextForm || this.props.showImageUploadForm || this.props.showEmojiForm || this.props.showShapesForm) && this.props.mode === 'ADMIN' && !this.props.isCustomMaskEditing?.status ?
                        <div className={stl.adminSubmenu}>
                            <div>
                                <Switch className={"custom-checkbox"}
                                        checked={this.props[this.activeTool()]}
                                        onChange={this.handleSubmenuAllowed.bind(this)}/>
                                Allowed to show {subMenuActiveType}
                            </div>

                            {subMenuActiveType === "texts" ? <div>
                                <div className="designer-right-panel-item" style={{"border": "none"}}>
                                    <div className={"designer-right-panel-item__titleFlex"}>
                                        <Switch className={"custom-checkbox"}
                                                checked={this.props.propertyTextColorAllowed}
                                                onChange={this.handlePropertyChange.bind(this, 'propertyTextColorAllowed')}/>
                                        Allowed color change for new texts
                                    </div>
                                </div>

                                <div className="designer-right-panel-item" style={{"border": "none"}}>
                                    <div className={"designer-right-panel-item__titleFlex"}>
                                        <Switch className={"custom-checkbox"}
                                                checked={this.props.propertyTextFontAllowed}
                                                onChange={this.handlePropertyChange.bind(this, 'propertyTextFontAllowed')}/>
                                        Allowed font change for new texts
                                    </div>
                                </div>

                                <div className="designer-right-panel-item" style={{"border": "none"}}>
                                    <div className={"designer-right-panel-item__titleFlex"}>
                                        <Switch className={"custom-checkbox"}
                                                checked={this.props.propertyTextStrokeAllowed}
                                                onChange={this.handlePropertyChange.bind(this, 'propertyTextStrokeAllowed')}/>
                                        Allowed stroke for new texts
                                    </div>
                                </div>

                                <div className="designer-right-panel-item" style={{"border": "none"}}>
                                    <div className={"designer-right-panel-item__titleFlex"}>
                                        <Switch className={"custom-checkbox"}
                                                checked={this.props.propertyTextStrokeColorAllowed}
                                                onChange={this.handlePropertyChange.bind(this, 'propertyTextStrokeColorAllowed')}/>
                                        Allowed stroke color for new texts
                                    </div>
                                </div>

                                <div className="designer-right-panel-item" style={{"border": "none"}}>
                                    <div className={"designer-right-panel-item__titleFlex"}>
                                        <Switch className={"custom-checkbox"}
                                                checked={this.props.propertyTextShadowColorAllowed}
                                                onChange={this.handlePropertyChange.bind(this, 'propertyTextShadowColorAllowed')}/>
                                        Allowed shadow color for texts
                                    </div>
                                </div>
                                <div className="designer-right-panel-item" style={{"border": "none"}}>
                                    <div className={"designer-right-panel-item__titleFlex"}>
                                        <Switch className={"custom-checkbox"}
                                                checked={this.props.propertyTextShadowAllowed}
                                                onChange={this.handlePropertyChange.bind(this, 'propertyTextShadowAllowed')}/>
                                        Allowed shadow for texts
                                    </div>
                                </div>
                            </div> : null}

                            {subMenuActiveType === "images" ? <div>
                                <div className="designer-right-panel-item" style={{"border": "none"}}>
                                    <div className={"designer-right-panel-item__titleFlex"}>
                                        <Switch className={"custom-checkbox"}
                                                checked={this.props.propertyFaceCropSizeAllowed}
                                                onChange={this.handlePropertyChange.bind(this, 'propertyFaceCropSizeAllowed')}/>
                                        Allowed face crop size change
                                    </div>
                                </div>
                                <div className="designer-right-panel-item" style={{"border": "none"}}>
                                    <div className={"designer-right-panel-item__titleFlex"}
                                    >
                                        <Switch className={"custom-checkbox"}
                                                checked={this.props.propertyFaceCropZoomAllowed}
                                                onChange={this.handlePropertyChange.bind(this, 'propertyFaceCropZoomAllowed')}/>
                                        Allowed face crop zoom change
                                    </div>
                                </div>
                                <div className="designer-right-panel-item" style={{"border": "none"}}>
                                    <div className={"designer-right-panel-item__titleFlex"}>
                                        <Switch className={"custom-checkbox"}
                                                checked={this.props.propertyFaceCropRotateAllowed}
                                                onChange={this.handlePropertyChange.bind(this, 'propertyFaceCropRotateAllowed')}/>
                                        Allowed face crop rotate change
                                    </div>
                                </div>
                            </div> : null}
                        </div>
                        : null}
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        showTextForm: state.tol.showTextForm,
        showImageUploadForm: state.tol.showImageUploadForm,
        showEmojiForm: state.tol.showEmojiForm,
        showShapesForm: state.tol.showShapesForm,
        mode: state.glb.mode,
        pages: state.glb.pages,
        activePage: state.glb.activePage,
        showBackgroundsForm: state.tol.showBackgroundsForm,
        showDesignsForm: state.tol.showDesignsForm,
        showActiveElementsList: state.tol.showActiveElementsList,
        ifToolsMenuVisible: state.tol.ifToolsMenuVisible,
        stage: state.glb.stage,
        isCustomMaskEditing: state.glb.isCustomMaskEditing,
        activeElement: state.glb.activeElement,
        isShowImageChangePopup: state.tol.showImageChangePopup,
        toolTextAllowed: state.tol.toolTextAllowed,
        toolEmojiAllowed: state.tol.toolEmojiAllowed,
        toolShapesAllowed: state.tol.toolShapesAllowed,
        toolImagesAllowed: state.tol.toolImagesAllowed,
        propertyTextColorAllowed: state.tol.propertyTextColorAllowed,
        propertyTextFontAllowed: state.tol.propertyTextFontAllowed,
        propertyTextStrokeAllowed: state.tol.propertyTextStrokeAllowed,
        propertyTextStrokeColorAllowed: state.tol.propertyTextStrokeColorAllowed,
        propertyTextShadowColorAllowed: state.tol.propertyTextShadowColorAllowed,
        propertyTextShadowAllowed: state.tol.propertyTextShadowAllowed,
        propertyFaceCropSizeAllowed: state.tol.propertyFaceCropSizeAllowed,
        propertyFaceCropZoomAllowed: state.tol.propertyFaceCropZoomAllowed,
        propertyFaceCropRotateAllowed: state.tol.propertyFaceCropRotateAllowed,
    }
};

const mapDispatchToProps = dispatch => {
        return {
            onEditTextElement: (data) => {
                dispatch(actionCreators.hidePopup());
                dispatch(actionCreators.editElementAttrs(data));
            },
            showImageChangePopup: () => dispatch(actionCreators.showImageChangePopup()),
            toolsMenuVisibleToggle: (mode) => {
                dispatch(actionCreators.toolsMenuVisibleToggle(mode));
            },
            userAllowedTools: (data) => {
                dispatch(actionCreators.userAllowedTools(data));
            },
            onShowTextForm: () => {
                dispatch(actionCreators.showTextForm());
            },
            onShowTextPopup: () => dispatch(actionCreators.showTextPopup()),
            onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
            onShowEmojiForm: () => {
                dispatch(actionCreators.showEmojiForm());
            },
            onShowBackgroundsForm: () => {
                dispatch(actionCreators.showBackgroundsForm());
            },
            onShowDesignsForm: () => {
                dispatch(actionCreators.showDesignsForm());
            },
            onShowUploadForm: () => {
                if (window.innerWidth < DESKTOP_WIDTH) document.querySelectorAll("body")[0].style.overflow = 'hidden'
                dispatch(actionCreators.showUploadForm());
            },
            hideToolsMenu: () => {
                dispatch(actionCreators.hideToolsMenu());
            },
            onShowElementsList: () => {
                if (window.innerWidth < DESKTOP_WIDTH) document.querySelectorAll("body")[0].style.overflow = 'hidden'
                dispatch(actionCreators.hideToolsMenu());
                dispatch(actionCreators.showElementsList());
            },
            onShowShapesForm: () => {
                dispatch(actionCreators.showShapesForm());
            },
            hidePopup: () => {
                document.querySelectorAll("body")[0].style.overflow = 'auto'
                dispatch(actionCreators.hidePopup())
            },
            setUserAllowedProperties: (data) => {
                dispatch(actionCreators.userAllowedProperties(data));
            },
        };
    }
;

export default connect(mapStateToProps, mapDispatchToProps)(Tools);
