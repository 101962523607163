import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../../../store/actions/index';
import stl from "./ToolsViews.module.scss";
import Translate from "react-translate-component";
import Switch from "react-switch/index";
import {FormInput} from "shards-react";
import font_icon from "../../../assets/images/Frame 195.svg";
import fontSize_icon from "../../../assets/images/Group 139.svg";
import letterSpace_icon from "../../../assets/images/Group 169.svg";
import colorText_icon from "../../../assets/images/colorFont_icon.svg";

import textAlign_left from "../../../assets/images/Frame 158.svg";
import textAlign_center from "../../../assets/images/textAlign_center.svg";
import textAlign_right from "../../../assets/images/textAlign_right.svg";


import {Button} from "shards-react";

import textFlip_icon from "../../../assets/images/textFlip_icon.svg";
import FontFamily from "../../Properties/PropertiesComponents/FontFamily";
import close_btn from "../../../assets/images/iconfinder_Close_6351931 2.png";
import Color from "../../Properties/PropertiesComponents/Color";
import FontStroke from "../../Properties/PropertiesComponents/FontStroke";
import ColorFontStroke from "../../Properties/PropertiesComponents/ColorFontStroke";
import ShadowTextColor from "../../Properties/PropertiesComponents/ShadowTextColor";
import clsx from "clsx";
import minus_img_icon from "../../../assets/images/faceCropMinus_icon.svg";
import plus_img_icon from "../../../assets/images/faceCropPlus_icon.svg";
import addTextIcon from "../../../assets/images/addTextIcon.png";
import removeElementIcon from "../../../assets/images/removeElementIcon.png";
import changeStyleTextIcon from "../../../assets/images/changeStyleTextIcon.png";

import textTransformer from "../../../helpes/textTransformer";

import CustomScroll from 'react-custom-scroll';

class Text extends Component {
    state = {
        fontFamily_show: false,
        fontColor_show: false,
        fontLetterSpaceShow: false,
        fontSizeShow: false,
        fontAlightText: false,
        showTextProps: false,
        interval: null,
    }


    toggleClass(property) {
        this.setState({
            [property]: !this.state[property]
        });
    }

    componentDidMount() {
        const {
            propertyTextStrokeAllowed,
            propertyTextStrokeColorAllowed,
            propertyTextShadowColorAllowed,
            propertyTextShadowAllowed,
            propertyTextColorAllowed,
            propertyTextFontAllowed
        } = this.props.pages[this.props?.activePage ?? 0]


        this.props.setUserAllowedProperties({
            'propertyTextStrokeAllowed': propertyTextStrokeAllowed || false,
            'propertyTextStrokeColorAllowed': propertyTextStrokeColorAllowed || false,
            'propertyTextShadowAllowed': propertyTextShadowAllowed || false,
            'propertyTextShadowColorAllowed': propertyTextShadowColorAllowed || false,
            'propertyTextColorAllowed': propertyTextColorAllowed || false,
            'propertyTextFontAllowed': propertyTextFontAllowed || false,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.showTextForm
            &&
            (this.state.fontColor_show
                || this.state.fontFamily_show
                || this.state.fontSizeShow
                || this.state.fontLetterSpaceShow
                || this.state.showTextProps
            )) {
            this.setState({
                fontColor_show: false,
                fontFamily_show: false,
                fontSizeShow: false,
                fontLetterSpaceShow: false,
                showTextProps: false,
            })
        }
    }

    showTextEditPopup(name) {
        this.props.onSelectElement(name);
        this.props.onShowTextEditPopup();
    }

    deleteTextElement(item) {
        this.props.deleteSelectedElement({
            element: item.key || item.name,
            activePage: this.props.activePage,
        })

        setTimeout(() => {
            if (this.props.pages && this.props.pages[this.props.activePage]) {
                let canvasElements = this.props.pages[this.props.activePage].canvasElements;
                canvasElements = canvasElements.filter(i => {
                    return i.deletable && i.type === 'text'
                })

                if (canvasElements.length >= 2) {
                    this.props.onSelectElement(canvasElements[0].name);
                } else {
                    this.props.onSelectElement("");
                }
            }
        }, 0)
    }

    showTextProps(name) {
        this.props.onSelectElement(name);
        this.setState({
            showTextProps: !this.state.showTextProps
        })
    }

    getElements() {
        if (this.props.pages && this.props.pages[this.props.activePage]) {
            let canvasElements = this.props.pages[this.props.activePage]?.canvasElements;

            canvasElements = canvasElements.filter(i => {
                return i.changeable && i.type === 'text' && i.data !== "undefined"
            })

            // console.log(canvasElements)
            return canvasElements
        }
    }

    handleLetterSpacingChange(e, val = 0) {
        this.state.interval = setInterval(() => {
            this.props.stage.children[this.props.activePage].children.forEach((item) => {
                if ((item.attrs.elementId === this.props.activeElement.attrs.elementId
                        && this.props.activeElement.attrs.elementId !== 0
                        && this.props.activeElement.attrs.elementId !== undefined)
                    || this.props.selectedElements.includes(item.attrs.name)) {

                    let name = item.attrs.name;

                    this.props.onEditElement({
                        letterSpacing:this.props.activeElement.attrs.letterSpacing + e,
                        element: item.attrs.name,
                        activePage: this.props.activePage,
                    });
                    setTimeout(() => {
                        this.props.onSelectElement(name);
                        let newTextWidth = item.width() >= 1 ? item.width() : 1;

                        switch (item.attrs.align) {
                            case "right":
                                item.offsetX(newTextWidth);
                                break;
                            case "center":
                                item.offsetX(newTextWidth / 2);
                                break;
                            default:
                                return
                        }
                    })
                }
            })
        }, 35)
    }

    propertyChange(propertyName, e) {
        this.props.stage.children[this.props.activePage].children.forEach((item) => {
            if ((item.attrs.elementId === this.props.activeElement.attrs.elementId
                && this.props.activeElement.attrs.elementId !== 0
                && this.props.activeElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.attrs.name)) {

                this.props.onEditElement({
                    [propertyName]: e,
                    faceId: item.attrs.faceId ?? 0,
                    element: item.attrs.name,
                    activePage: this.props.activePage
                });

                // TODO: remove setTimeout
                if (propertyName === 'elementRepeat' || propertyName === 'elementRepeatOval') {
                    if (e) {
                        let saveName = item.attrs.name
                        this.props.onSelectElement("");
                        setTimeout(() => {
                            this.props.onSelectElement(saveName);
                            this.props.showFaceCropPopup();
                        }, 0);
                    } else {
                        let saveName = item.attrs.name
                        this.props.onSelectElement("");
                        setTimeout(() => {
                            this.props.onSelectElement(saveName);
                        }, 0);
                    }
                }
            }
        });


    }

    handlePropertyChange(e, status) {
        this.props.setUserAllowedProperties({
            [e]: status
        });
    }

    deleteElement() {
        if (this.props.mode === "ADMIN" || this.props.activeElement?.attrs?.deletable) {
            this.props.deleteSelectedElement({
                element: this.props.activeElement?.attrs.name,
                activePage: this.props.activePage,
            });
            this.props.onSelectElement("");
        }
    }

    handleAlightTextChange(align) {
        this.props.onEditElement({
            align: align,
            element: this.props.activeElement?.attrs?.name,
            activePage: this.props.activePage
        });
        console.log(align)

        if (this.props.activeElement) {
            switch (align) {
                case "right":
                    this.props.activeElement.offsetX(this.props.activeElement.width());
                    this.props.activeElement.x(this.props.stage.width() / this.props.stage.attrs.scaleX);
                    break;
                case "center":
                    this.props.activeElement.offsetX(this.props.activeElement.width() / 2);
                    this.props.activeElement.x((this.props.stage.width() / this.props.stage.attrs.scaleX) / 2);
                    break;
                default:
                    this.props.activeElement.offsetX(0).x(0);
            }
        }
    }

    generatePreview() {
        if (this.props.stage) {
            if (this.props.isItemDownloading > 0) {
                setTimeout(() => {
                    this.generatePreview()
                }, 1000);
            } else {
                let stage = this.props.stage.clone();
                this.props.generatePreview({
                    pages: this.props.pages,
                    activePage: this.props.activePage,
                    stage: stage,
                    previewUrl: this.props.previewUrl
                });
                this.props.setPreviewUpdateStatus({previewShouldBeChanged: false})
            }
        }
    }

    getStoreViewValues() {
        let storeViewValues = [];
        let inputs = document.getElementsByClassName("text-form-popup-content-store");
        for (let i = 0; i < inputs.length; i++) {
            let storeId = inputs[i].name.replace('text-form-popup-content-store-', '');
            storeViewValues.push({store_id: storeId, label: inputs[i].value});
        }
        return storeViewValues;
    }

    getInterval(e, fn, interval) {
        return e.target
            ? fn()
            : this.state.interval = setInterval(() => fn(), interval)
    }

    handleFontSizeChange(e, val = 0) {

        this.getInterval.call(this, e, () => {
            this.props.stage.children[this.props.activePage].children.forEach((item) => {
                if ((item.attrs.elementId === this.props.activeElement.attrs.elementId
                        && this.props.activeElement.attrs.elementId !== 0
                        && this.props.activeElement.attrs.elementId !== undefined)
                    || this.props.selectedElements.includes(item.attrs.name)) {
                    this.props.onEditElement({
                        fontSize:
                            e.target === undefined
                                ? Math.abs(parseFloat(this.props.activeElement.attrs.fontSize + e))
                                : Math.abs(parseFloat(e.target.value)),
                        element: item.attrs.name,
                        activePage: this.props.activePage,
                    });
                    setTimeout(() => {
                        switch (item.attrs.align) {
                            case "right":
                                item.x(item.x() + item.width() - item.offsetX())
                                item.offsetX(item.width());
                                break;
                            case "center":
                                item.x(item.x() + (item.width() / 2 - item.offsetX()))
                                item.offsetX(item.width() / 2);
                                break;
                            default:
                                item.offsetX(0);
                                break;
                        }
                    }, 15)
                }
            });
        }, 35)
    }

    addTextInput() {
        const createObj = {
            type: 'text',
            data: ' ',
            store_view_values: this.getStoreViewValues(),
            activePage: this.props.activePage
        };

        const {activePage, pages} = this.props;
        if (pages && pages.length && pages[activePage]) {
            const {customFonts = {}} = pages[activePage];
            const objectKey = Object.keys(customFonts);
            if (objectKey.length > 0) {
                createObj.fontFamily = customFonts[objectKey[0]]['font_family'];
            }
        }
        this.props.addTextToPage(createObj);

        setTimeout(() => {
            let name = this.props.stage.children[this.props.activePage].children[this.props.stage.children[this.props.activePage].children.length - 2].attrs.name

            this.props.onSelectElement(name)
            this.props.onEditElement({
                align: "center",
                element: name,
                deletable: true,
                activePage: this.props.activePage,
            });

            if (this.props.activeElement) {
                this.props.activeElement.offsetX(this.props.activeElement.width() / 2);
                this.props.activeElement.x((this.props.stage.width() / this.props.stage.attrs.scaleX) / 2);
            }


        }, 0)
    }

    textChangeHandle(newText) {

        const ranges = /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/ug

        return textTransformer(this.props.pages[this.props.activePage].toolEmojiAllowed !== false ? newText.target.value : newText.target.value.replace(new RegExp(ranges, 'g'), ''), this.props.activeElement?.attrs.user_textTransform?.value);
    }

    changeText(name, text) {
        console.log('text', text.target.value);
        console.log('text', text);
        console.log('name', name);
        if (text.target.value?.length > 0) {
            this.props.stage.children[this.props.activePage].children.forEach((item) => {

                if ((item?.attrs.elementId === this.props.activeElement?.attrs?.elementId
                    && item?.attrs?.elementId !== 0
                    && item?.attrs?.elementId !== undefined) || this.props?.selectedElements?.includes(item.attrs.name)) {

                    this.props.onEditElement({
                        newText: this.textChangeHandle(text).trimLeft(),
                        element: item.attrs.name,
                        activePage: this.props.activePage,
                    });


                    if (this.props.activeElement) {
                        setTimeout(() => {
                            if (item?.attrs?.textMaxWidth && this.props.activeElement) item.width(item.attrs.textMaxWidth);
                            let align = item.attrs.align;
                            this.props.onEditElement({
                                align: "left",
                                element: item.attrs.name,
                                historyMode: false,
                                activePage: this.props.activePage,
                            });

                            this.props.onEditElement({
                                align: align,
                                element: item.attrs.name,
                                historyMode: false,
                                activePage: this.props.activePage,
                            });

                            switch (align) {
                                case "right":
                                    item.offsetX(item.width());
                                    break;
                                case "center":
                                    item.offsetX(item.width() / 2);
                                    break;
                                default:
                                    break;
                            }
                        }, 50)
                    }
                }
            });


            // TODO: delete setTimeout



        } else {
            this.props.onEditElement({
                newText: " ",
                element: name,
                activePage: this.props.activePage,
            });
        }
    }

    decodeTextData(item) {
        let text = " ";
        try {
            text = textTransformer(decodeURI(item.data || item.text), item.user_textTransform?.value) ?? " ";
        } catch (e) {
            text = textTransformer((item.data || item.text), item.user_textTransform?.value) ?? " "
        }

        if(text !== 'undefined' && text !== 'UNDEFINED') return text
        return " ";
    }

    render() {
        let alignIcon;
        let align = 'left'
        if (this.props.activeElement) {
            align = this.props.activeElement?.attrs.align
        }

        switch (align) {
            case "left":
                alignIcon = textAlign_left
                break;
            case "center":
                alignIcon = textAlign_center
                break;
            default:
                alignIcon = textAlign_right
                break;
        }


        return (this.props.showTextForm ?
                <>
                    {/*{window.innerWidth >= DESKTOP_WIDTH ? (*/}
                    <>
                    <CustomScroll   heightRelativeToParent="180px">
                        <div className={stl.textMenu}>
                            {window.innerWidth > 616 || (!this.state.showTextProps || (this.state.fontColor_show || this.state.fontFamily_show || this.state.fontSizeShow || this.state.fontLetterSpaceShow)) ? null :
                                <div className={stl.textMenu__closeBtn}
                                >
                                    <img src={close_btn} alt="" onClick={() => {
                                        this.setState({showTextProps: false})
                                    }}/>
                                </div>
                            }

                            {this.props.activeElement?.attrs.type === "text" && (this.state.showTextProps || window.innerWidth > 616) ? <>
                                {this.state.fontFamily_show ? <>
                                    <div className={stl.textMenu__closeBtn}
                                    >
                                        <img src={close_btn} alt="" onClick={() => {
                                            this.setState({fontFamily_show: false})
                                        }}/>
                                    </div>
                                    <FontFamily/>
                                </> : null}

                                {this.state.fontColor_show ? <>
                                    <div className={stl.textMenu__closeBtn}
                                    >
                                        <img src={close_btn} alt="" onClick={() => {
                                            this.setState({fontColor_show: false})
                                        }}/>
                                    </div>
                                    <Color toggleClass={this.toggleClass.bind(this)}/>
                                    <br/>
                                    <FontStroke toggleClass={this.toggleClass.bind(this)}/>
                                    <br/>
                                    {!!(this.props.activeElement.attrs.shadowEnabled) ?
                                        <ColorFontStroke toggleClass={this.toggleClass.bind(this)}/> : null}

                                    {
                                        ((this.props.mode === 'ADMIN') || (this.props.propertyTextShadowAllowed) && this.props.activeElement?.attrs.type === 'text') ?
                                            <div className="designer-right-panel-item_shadow">
                                                <Switch className={"custom-checkbox"}
                                                        onChange={this.propertyChange.bind(this, "shadowEnabled")}
                                                        checked={(!!(this.props.activeElement.attrs.shadowEnabled))}/>
                                                <Translate content={"Shadow"}/>
                                            </div> : null
                                    }
                                    <br/>
                                    <ShadowTextColor toggleClass={this.toggleClass.bind(this)}/>


                                </> : null}


                                {this.state.fontColor_show || this.state.fontFamily_show ? null : <>
                                    {/*<button onClick={() => {*/}
                                    {/*    this.props.onShowTextEditPopup()*/}
                                    {/*}}>*/}
                                    {/*    <img src={pencil_icon} alt=""/>*/}
                                    {/*    <Translate content={"Edit"}/>*/}

                                    {/*</button>*/}


                                    <div className={clsx(this.state.fontAlightText && stl.active, stl.fontAlightText)}>
                                        <button style={window.innerWidth <= 616 ? {"marginBottom": "0"} : {}}
                                                onClick={() => {
                                                    this.setState({
                                                        fontColor_show: false,
                                                        fontFamily_show: false,
                                                        fontSizeShow: false,
                                                        fontLetterSpaceShow: false,
                                                        fontAlightText: !this.state.fontAlightText
                                                    })
                                                }}>

                                            {
                                                window.innerWidth > 616 ? <img src={alignIcon} alt=""/> : <>
                                                    <img src={textAlign_left} alt=""
                                                         onClick={() => {
                                                             this.handleAlightTextChange('left')
                                                         }}
                                                    />
                                                    <img src={textAlign_center} alt=""
                                                         onClick={() => {
                                                             this.handleAlightTextChange('center')
                                                         }}
                                                    />
                                                    <img src={textAlign_right} alt=""
                                                         onClick={() => {
                                                             this.handleAlightTextChange('right')
                                                         }}
                                                    />
                                                </>
                                            }
                                            <Translate content={"Align"}/>
                                        </button>
                                        {
                                            window.innerWidth < 616 ? null : <div className={stl.activeFontSize}>
                                                <img src={textAlign_left} alt=""
                                                     onClick={() => {
                                                         this.handleAlightTextChange('left')
                                                     }}
                                                />
                                                <img src={textAlign_center} alt=""
                                                     onClick={() => {
                                                         this.handleAlightTextChange('center')
                                                     }}
                                                />
                                                <img src={textAlign_right} alt=""
                                                     onClick={() => {
                                                         this.handleAlightTextChange('right')
                                                     }}
                                                />
                                            </div>
                                        }

                                    </div>

                                    <div className={clsx(this.state.fontSizeShow && stl.active)}>
                                        <button onClick={() => {
                                            this.setState({
                                                fontColor_show: false,
                                                fontFamily_show: false,
                                                fontSizeShow: !this.state.fontSizeShow,
                                                fontLetterSpaceShow: false
                                            })
                                        }}>
                                            <img src={fontSize_icon} alt=""/>
                                            <Translate content={"Font Size"}/>
                                        </button>

                                        <div className={stl.activeFontSize}>
                                            <img
                                                draggable="false"
                                                src={minus_img_icon}
                                                alt=""
                                                onMouseDown={
                                                    this.props.activeElement?.attrs?.fontSize > 0
                                                        ? this.handleFontSizeChange.bind(this,-1)
                                                        : clearInterval(this.state.interval)
                                                }
                                                onMouseUp={() => clearInterval(this.state.interval)}
                                                onMouseLeave={() => clearInterval(this.state.interval)}
                                            />
                                            <FormInput
                                                className={stl.activeFontSizeInput}
                                                size="sm"
                                                type="number"
                                                value={this.props.activeElement?.attrs?.fontSize || 0}
                                                innerRef={this.fontSizeInput}
                                                onChange={(e) => {
                                                    (e.target.value.replace(/ +/g, ' ') >= 0 && this.handleFontSizeChange.call(this, e))
                                                }}
                                            />
                                            <img
                                                draggable="false"
                                                src={plus_img_icon}
                                                alt=""
                                                onMouseDown={this.handleFontSizeChange.bind(this, 1)}
                                                onMouseUp={() => clearInterval(this.state.interval)}
                                                onMouseLeave={() => clearInterval(this.state.interval)}
                                            />
                                        </div>
                                    </div>

                                    <div className={clsx(this.state.fontLetterSpaceShow && stl.active)}>
                                        <button onClick={() => {
                                            this.setState({
                                                fontColor_show: false,
                                                fontFamily_show: false,
                                                fontSizeShow: false,
                                                fontLetterSpaceShow: !this.state.fontLetterSpaceShow
                                            })
                                        }}>
                                            <img src={letterSpace_icon} alt=""/>
                                            <Translate content={"Letter Space"}/>
                                        </button>

                                        <div className={stl.activeFontSize}>
                                            <img
                                                draggable="false"
                                                src={minus_img_icon}
                                                alt=""
                                                onMouseDown={() =>this.handleLetterSpacingChange(-1)}
                                                onMouseUp={() => clearInterval(this.state.interval)}
                                                onMouseLeave={() => clearInterval(this.state.interval)}
                                            />

                                            {this.props.activeElement?.attrs?.letterSpacing}

                                            <img
                                                draggable="false"
                                                src={plus_img_icon}
                                                alt=""
                                                onMouseDown={() => this.handleLetterSpacingChange(1)}
                                                onMouseUp={() => clearInterval(this.state.interval)}
                                                onMouseLeave={() => clearInterval(this.state.interval)}
                                            />
                                        </div>
                                    </div>

                                    <button onClick={() => {
                                        this.setState({fontFamily_show: true})
                                    }}>
                                        <img src={font_icon} alt=""/>
                                        <Translate content={"Font"}/>

                                    </button>


                                    {!!this.props.propertyTextColorAllowed || this.props.mode === "ADMIN" ?
                                        <button onClick={() => {
                                            this.setState({
                                                fontColor_show: true
                                            })
                                        }}>
                                            <img src={colorText_icon} alt=""/>
                                            <Translate content={"Text Color"}/>
                                        </button> : null
                                    }


                                    {/*{this.props.toolTextAllowed || this.props.mode === "ADMIN" ?*/}
                                    {/*    <button onClick={() => {*/}
                                    {/*        this.props.onShowTextPopup();*/}
                                    {/*    }}>*/}
                                    {/*        <img src={addText_icon} alt=""/>*/}
                                    {/*        <Translate content={"New text"}/>*/}

                                    {/*    </button>*/}
                                    {/*    : null}*/}
                                    <button onClick={() => {
                                        this.props.onEditElement({
                                            newText: this.props.activeElement?.attrs?.text.split("").reverse().join(""),
                                            flipX: this.props.activeElement.attrs.flipX === undefined ? true : !!!this.props.activeElement.attrs.flipX,
                                            element: this.props.activeElement.attrs.name,
                                            activePage: this.props.activePage
                                        });

                                    }}>
                                        <img src={textFlip_icon} alt=""/>
                                        <Translate content={"Flip X"}/>
                                    </button>


                                </>}
                            </> : null}


                            {!this.state.showTextProps || window.innerWidth >= 616 ?
                                <div className={stl.textMenu_elementsList}>

                                    {this.getElements().map((i, index) => <div key={index} className={stl.textMenu_listItem}>

                                        {/*<input type="text"*/}
                                        {/*       */}
                                        {/*/>*/}
                                        {/* Field to set Value for Canvas */}
                                        <textarea
                                            rows="2"
                                            cols="3"
                                            // defaultValue={this.props.activeElement?.attrs?.text}
                                            value={this.decodeTextData(i)}
                                            maxLength={i.textLimit || undefined}
                                            onClick={()=>{
                                                this.props.setSelectedElements([i.name]);
                                                this.props.onSelectElement(i.name)
                                            }}
                                            onChange={this.changeText.bind(this, i.name)}
                                            style={i.name === this.props?.activeElement?.attrs?.name ? {
                                                "borderColor": "#03A786",
                                                "borderWidth": "2px"
                                            } : {}}
                                        />

                                        {window.innerWidth > 616 ?
                                            <>
                                                {
                                                    this.props.pages[this.props.activePage].multiface_elements_admin_only ?
                                                        null
                                                        : <img src={removeElementIcon} alt="" onClick={this.deleteTextElement.bind(this, i)}/>
                                                }
                                            </> :

                                            <img src={changeStyleTextIcon} alt=""
                                                 onClick={this.showTextProps.bind(this, i.name)}/>
                                        }
                                    </div>)}



                                    <div className={stl.textMenu_elementAdd}>

                                        {this.props.pages[this.props.activePage].multiface_elements_admin_only ?
                                            <div onClick={() => {
                                                setTimeout(() => {
                                                    this.generatePreview();
                                                }, 0)
                                            }}>
                                                <Translate content={"Preview"}/>
                                            </div> :

                                            <img src={addTextIcon} alt="" onClick={this.addTextInput.bind(this)}/>
                                        }
                                    </div>

                                </div> : null}

                        </div>
                        </CustomScroll>
                    </>
                </>
                : null
        )
    }
}

const
    mapStateToProps = state => {
        return {
            activeElement: state.glb.activeElement,
            pages: state.glb.pages,
            showTextForm: state.tol.showTextForm,
            mode: state.glb.mode,
            activePage: state.glb.activePage,
            toolTextAllowed: state.tol.toolTextAllowed,
            stage: state.glb.stage,
            propertyTextColorAllowed: state.tol.propertyTextColorAllowed,
            propertyTextFontAllowed: state.tol.propertyTextFontAllowed,
            propertyTextStrokeAllowed: state.tol.propertyTextStrokeAllowed,
            propertyTextStrokeColorAllowed: state.tol.propertyTextStrokeColorAllowed,
            propertyTextShadowColorAllowed: state.tol.propertyTextShadowColorAllowed,
            propertyTextShadowAllowed: state.tol.propertyTextShadowAllowed,
            selectedElements: state.glb.selectedElements,
            previewUrl: state.glb.previewUrl,
            previews: state.prv.previews,
            isItemDownloading: state.glb.isItemDownloading,
        };
    };

const
    mapDispatchToProps = dispatch => {
        return {
            setUserAllowedProperties: (data) => {
                dispatch(actionCreators.userAllowedProperties(data));
            },
            setSelectedElements: (elements) => dispatch(actionCreators.setSelectedElements(elements)),

            onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
            onEditElement: (data) => dispatch(actionCreators.editElementAttrs(data)),
            onShowTextEditPopup: () => dispatch(actionCreators.showTextEditPopup()),
            onShowTextPopup: () => dispatch(actionCreators.showTextPopup()),
            hideToolsMenu: () => dispatch(actionCreators.hideToolsMenu()),
            deleteSelectedElement: (data) => dispatch(actionCreators.deleteSelectedElement(data)),
            addTextToPage: (canvasElement) => {
                dispatch(actionCreators.addElementToPage(canvasElement));
                dispatch(actionCreators.hidePopup());
            },
            setPreviewUpdateStatus: (data) => {
                dispatch(actionCreators.setPreviewUpdateStatus(data));
            },
            generatePreview: (previewData) => dispatch(actionCreators.generatePreview(previewData)),
        };
    }
;

export default connect(mapStateToProps, mapDispatchToProps)(Text);
