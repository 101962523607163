import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../../../store/actions/index';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import stl from "./ToolsViews.module.scss";
import Translate from "react-translate-component";
// import {DESKTOP_WIDTH} from "../../../config";
import {Button} from "shards-react";

class Shapes extends Component {

    addElementToPage(data) {
        this.props.addMasksElement({
            type: data,
        });

        // if (window.innerWidth <= DESKTOP_WIDTH) {
        //     this.props.hideToolsMenu();
        //     this.props.toolsMenuVisibleToggle({mode: false});
        // }
    }

    deleteCustomMaskElement(elName) {
        if(elName === "customShape") {
            this.props.setMasksElements({
                canvasMaskElements: JSON.stringify(this.props?.canvasMaskElements.map(i=>{
                    if(i.name === "customShape") i.points = []
                    return i
                }))
            });
        } else{
            this.props.setMasksElements({
                canvasMaskElements: JSON.stringify(this.props?.canvasMaskElements.filter(i => i.name !== elName))
            });
        }

        this.props.setActiveMaskElement('')
    }

    toggleMaskDrawMode() {
        this.props.toggleMaskDrawMode(!this.props.isMaskDrawMode)
    }

    render() {
        return (
            this.props.showShapesForm && this.props.isCustomMaskEditing.status ?
                <div className="designer-tools-uploader">
                    <div className={stl.uploader}>
                        <span className={stl.uploader__title}>
                            <Translate content={"Shapes"}/>
                        </span>
                    </div>
                    <div className={stl.shapes}>

                        <Button outline
                                theme="secondary"
                                onClick={this.toggleMaskDrawMode.bind(this)}>
                            <FontAwesomeIcon icon="slash"/>
                            &nbsp;&nbsp;
                            {this.props.isMaskDrawMode ? 'Finish creating' : 'Start creating'}
                        </Button>

                        {this.props.isMaskDrawMode ? null :
                            <>

                                {this.props?.canvasMaskElements[0]?.name === 'borderLimit' ? null : <Button outline
                                                                                                           theme="secondary"
                                                                                                           onClick={this.addElementToPage.bind(this, "borderLimit")}>
                                    <FontAwesomeIcon icon="square-full"/>
                                    &nbsp;&nbsp;
                                    Border Limit
                                </Button>
                                }

                                <Button outline
                                        theme="secondary"
                                        onClick={this.addElementToPage.bind(this, "square")}>
                                    <FontAwesomeIcon icon="square-full"/>
                                    &nbsp;&nbsp;
                                    <Translate content={"Add a rectangle"}/>
                                </Button>
                                <Button outline
                                        theme="secondary"
                                        onClick={this.addElementToPage.bind(this, "circle")}>
                                    <FontAwesomeIcon icon="circle"/>
                                    &nbsp;&nbsp;
                                    <Translate content={"Add a circle"}/>
                                </Button>
                                <Button outline
                                        theme="secondary"
                                        onClick={this.addElementToPage.bind(this, "triangle")}>
                                    <FontAwesomeIcon icon="caret-up"/>
                                    &nbsp;&nbsp;
                                    <Translate content={"Add a triangle"}/>
                                </Button>
                            </>
                        }

                        {!this.props.isMaskDrawMode ? <>
                            {this.props.activeMaskElement ? <Button outline
                                                                    theme="secondary"
                                                                    onClick={this.deleteCustomMaskElement.bind(this, this.props.activeMaskElement)}>
                                Delete selected
                            </Button> : null}

                            <Button outline
                                    theme="secondary"
                                    onClick={this.props.setMasksElements.bind(this, "delete")}>
                                <Translate content={"Delete"}/>
                            </Button>
                        </> : null}
                    </div>
                    <div className={stl.tools__btn}>
                        <Button theme="dark" size="sm" onClick={this.props.hideToolsMenu}>
                            <Translate content={"Close"}/>
                        </Button>
                    </div>
                </div>
                : null
        );
    }
}

const mapStateToProps = state => {
    return {
        activePage: state.glb.activePage,
        showShapesForm: state.tol.showShapesForm,
        isCustomMaskEditing: state.glb.isCustomMaskEditing,
        isMaskDrawMode: state.cnvMask.isMaskDrawMode,
        activeMaskElement: state.cnvMask.activeMaskElement,
        canvasMaskElements: state.cnvMask.canvasMaskElements,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActiveMaskElement: (data) => dispatch(actionCreators.setActiveMaskElement(data)),
        setMasksElements: (data) => dispatch(actionCreators.setMasksElements(data)),
        toggleMaskDrawMode: (data) => dispatch(actionCreators.toggleMaskDrawMode(data)),
        addElementToPage: (canvasElement) => dispatch(actionCreators.addElementToPage(canvasElement)),
        addMasksElement: (data) => dispatch(actionCreators.addMasksElement(data)),
        addTextToPage: (canvasElement) => {
            dispatch(actionCreators.addElementToPage(canvasElement));
            dispatch(actionCreators.hidePopup());
        },
        hideToolsMenu: () => dispatch(actionCreators.hideToolsMenu()),
        toolsMenuVisibleToggle: (data) => dispatch(actionCreators.toolsMenuVisibleToggle(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Shapes);
