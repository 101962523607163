import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import Translate from "react-translate-component";
import clsx from "clsx";
import * as actionCreators from "../../../store/actions/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import Translate from "react-translate-component";

class DeleteBtn extends Component {

    deleteElement() {
        this.props.deleteSelectedElement({
            element: this.props.selectedElement,
            activePage: this.props.activePage,
        });
        this.props.setActiveElement("");
    }

    render() {
        return null
        if (this.props.mode === "ADMIN" || this.props.activeElement?.attrs?.deletable) {
            return (
                <div
                    className={clsx("designer-right-panel-item", "designer-right-panel-item__deleteBtn")}
                    onClick={this.deleteElement.bind(this)}>
                    <div className={"designer-right-panel-item__titleFlex"}>
                        <FontAwesomeIcon className="designer-right-panel-item__trash" icon="trash"/>
                        <span className={"text"}>
                            <Translate content={"Delete"}/>
                        </span>
                    </div>
                </div>
            );
        } else {
            return null
        }
    }
}

const mapStateToProps = state => {
    return {
        pages: state.glb.pages,
        mode: state.glb.mode,
        selectedElement: state.glb.selectedElement,
        activePage: state.glb.activePage,
        activeElement: state.glb.activeElement,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        deleteSelectedElement: (data) => dispatch(actionCreators.deleteSelectedElement(data)),
        showImageChangePopup: () => dispatch(actionCreators.showImageChangePopup()),
        setActiveElement: (element) => dispatch(actionCreators.setActiveElement(element)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(DeleteBtn);
