export default {
    "Revert": "Revert",
    "Retry": "Retry",
    "Face crop":"Face crop",
    "Remove background":"Remove background",
    "Loading":"Loading",
    "Background not recognized":"Background not recognized",
    "Background already removed":"Background already removed",
    "settings": "Settings",
    "gridlines": "Show gridlines",
    "layers": "Layers",
    "image": "Image",
    "Text": "Text",
    "shape": "Shape",
    "emoji": "Emoji",
    "done": "Done",
    "view": "View",
    "Custom Text": "Custom Text",
    "Close": "Close",
    "Position": "Position",
    "Rotate": "Rotate",
    "Change Image": "Change Image",
    "Crop Image": "Crop Image",
    "Upload Images": "Upload Images",
    "Add Text": "Add Text",
    "Cancel": "Cancel",
    "Start creating your masterpiece by browsing our new content in the panels on the left": "Start creating your masterpiece by browsing our new content in the panels on the left",
    "Shapes": "Shapes",
    "Add a line": "Add a line",
    "Add a rectangle": "Add a rectangle",
    "Add a circle": "Add a circle",
    "Add a triangle": "Add a triangle",
    "Color": "Color",
    "Edit Text": "Edit Text",
    "Font type": "Font type",
    "Font Style": "Font Style",
    "Size": "Size",
    "Justification": "Justification",
    "Letter spacing": "Letter spacing",
    "Flip": "Flip",
    "Save" : "Save",
    "Preview": "Preview",
    "Editor": "Editor",
    "Uploads": "Uploads",
    "Letter limit": "Letter limit",
    "Recently Used Colors": "Recently Used Colors",
    "You placed an element outside the printable area. Please confirm, that you understood that this will not be completely or correctly printed" : "You placed an element outside the printable area. Please confirm, that you understood that this will not be completely or correctly printed",
    "There was error during getting files!": "There was an error uploading your pictures. Please refresh the page and try again.",
    "Your Image is too big to upload! The max file size is 8 MB": "Your Image is too big to upload! The max file size is 8 MB",
    "Your image is too small": "Your image is too small, to be printed in decent quality. Please choose another.",
    "Reset changes": "Reset changes",
    "BackgroundsTools": "Backgrounds",
    "ColorsTools": "Colors",
    "ImagesTools" : "Images",
    "Delete" : "Delete",
    "Shadow color": "Shadow color",
    "Shadow": "Shadow",
    "Add to cart": "Add to cart",
    "Information before ordering": "Before ticking off, please double-check everything carefully. Because everything is printed as in the preview. Clear? Otherwise take a look at our FAQ",
    "More": "More",
    "edit Image": "Edit Image",
    "Add_Image": "Add_Image",
    "Cut": "Cut",
    "Change": "Change",

    "Edit": "Edit",
    "Font": "Font",
    "Font Size": "Font Size",
    "Letter Space": "Letter Space",
    "Text Color": "Text Color",
    "New text": "New text",
    "Align": "Alignment",
    "Flip X": "Flip",
    "Oops, didn't you forget something?": "Oops, didn't you forget something?",
    "Use original image": "Use original image",
    "Continue": "Continue",
    "Wird freigestellt": "Loading...",
}
