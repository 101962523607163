import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../../../store/actions/index';

import smart from "../../../assets/images/emojis/smart.png";
import smile from "../../../assets/images/emojis/smile.png";
import angry from "../../../assets/images/emojis/angry.png";
import angry1 from "../../../assets/images/emojis/angry-1.png";
import bored from "../../../assets/images/emojis/bored.png";
import bored1 from "../../../assets/images/emojis/bored-1.png";
import bored2 from "../../../assets/images/emojis/bored-2.png";
import confused from "../../../assets/images/emojis/confused.png";
import confused1 from "../../../assets/images/emojis/confused-1.png";
import crying from "../../../assets/images/emojis/crying.png";
import crying1 from "../../../assets/images/emojis/crying-1.png";
import embarrassed from "../../../assets/images/emojis/embarrassed.png";
import emoticons from "../../../assets/images/emojis/emoticons.png";
import happy from "../../../assets/images/emojis/happy.png";
import happy1 from "../../../assets/images/emojis/happy-1.png";
import happy2 from "../../../assets/images/emojis/happy-2.png";
import happy3 from "../../../assets/images/emojis/happy-3.png";
import happy4 from "../../../assets/images/emojis/happy-4.png";
import ill from "../../../assets/images/emojis/ill.png";
import inlove from "../../../assets/images/emojis/in-love.png";
import kissing from "../../../assets/images/emojis/kissing.png";
import mad from "../../../assets/images/emojis/mad.png";
import nerd from "../../../assets/images/emojis/nerd.png";
import ninja from "../../../assets/images/emojis/ninja.png";
import quiet from "../../../assets/images/emojis/quiet.png";
import sad from "../../../assets/images/emojis/sad.png";
import secret from "../../../assets/images/emojis/secret.png";
import smiling from "../../../assets/images/emojis/smiling.png";
import surprised from "../../../assets/images/emojis/surprised.png";
import surprised1 from "../../../assets/images/emojis/surprised-1.png";
import suspicious from "../../../assets/images/emojis/suspicious.png";
import suspicious1 from "../../../assets/images/emojis/suspicious-1.png";
import tongueOut from "../../../assets/images/emojis/tongue-out.png";
import tongueOut1 from "../../../assets/images/emojis/tongue-out-1.png";
import unhappy from "../../../assets/images/emojis/unhappy.png";
import wink from "../../../assets/images/emojis/wink.png";
import heartIcon from "../../../assets/images/emojis/heart_emoji.png";
import heartIcon1 from "../../../assets/images/emojis/heart_emoji1.png";
import heartIcon2 from "../../../assets/images/emojis/heart_emoji2.png";
import heartIcon3 from "../../../assets/images/emojis/heart_emoji3.png";
import stl from "./ToolsViews.module.scss";

import {DESKTOP_WIDTH} from "../../../config";

class Emoji extends Component {
    addElement(imgUrl) {
        this.props.addElementToPage({
            type: 'icon',
            data: imgUrl,
            activePage: this.props.activePage
        });

        setTimeout(()=>{
            this.props.setActiveElement(this.props.stage.children[this.props.activePage].children[this.props.stage.children[this.props.activePage].children.length-2].attrs.name)
        }, 50)

        if (window.innerWidth <= DESKTOP_WIDTH) {
            this.props.hideToolsMenu();
            this.props.toolsMenuVisibleToggle({mode: false});
        }
    }

    state = {
        icons: [smart, smile, angry, angry1, bored, bored1, bored2,
            confused, confused1, crying, crying1, embarrassed,
            emoticons, happy, happy1, happy2, happy3, happy4, ill,
            inlove, kissing, mad, nerd, ninja, quiet, sad, secret,
            smiling, surprised, surprised1, suspicious, suspicious1,
            tongueOut, tongueOut1, unhappy, wink, heartIcon, heartIcon1,
            heartIcon2, heartIcon3]
    };

    render() {
        return (this.props.showEmojiForm ?
                <div className="designer-tools-uploader">
                    <div className={stl["tools-icons"]}>
                        {
                            this.state.icons.map((icon, i) => {
                                return (
                                    <button key={i} className={stl["tools-icons__item"]}
                                            onClick={this.addElement.bind(this, icon)}>
                                        <img src={icon} alt="smart"/>
                                    </button>
                                )
                            })
                        }
                    </div>
                </div>
                : null
        )
    }
}

const mapStateToProps = state => {
    return {
        stage: state.glb.stage,
        showEmojiForm: state.tol.showEmojiForm,
        activePage: state.glb.activePage,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        hideToolsMenu: () => dispatch(actionCreators.hideToolsMenu()),
        addElementToPage: (canvasElement) => dispatch(actionCreators.addElementToPage(canvasElement)),
        setActiveElement: (element) => dispatch(actionCreators.setActiveElement(element)),
        toolsMenuVisibleToggle: (data) => dispatch(actionCreators.toolsMenuVisibleToggle(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Emoji);
