import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import {Image, Layer, Rect, Stage, Transformer} from "react-konva";
import Translate from "react-translate-component";
import stl from './popups.module.scss'
import {post} from "axios";
import loadingImage from "../../assets/images/loading.gif";
import stlCanvas from '../Canvas/Canvas.module.scss';
import {Button} from "shards-react";

let
    STAGE_SIZE = Math.min(window.innerWidth - 120, window.innerHeight - 200),
    PADDING_STAGE = 0;

class ImageCropPopup extends Component {
    transformer = React.createRef();
    stage = React.createRef();
    rect = React.createRef();

    state = {
        activeElement: undefined,
        crop: {},
        x: 0,
        y: 0,
        width: STAGE_SIZE,
        height: STAGE_SIZE,
        rotateChanged: true,
        oldImgSrc: undefined
    };

    componentDidMount() {
        if (this.props.activeElement) {
            this.setState({
                activeElement: this.props.activeElement,
            });
        }

        window.addEventListener('resize', () => {
            this.checkRatio();
        });
        this.checkRatio();
        this.checkNode();
    }

    prepareData() {
        this.rect.current.remove();
        let config = {headers: {'content-type': 'multipart/form-data'}};

        let stageCopy = this.stage.current.clone();

        /* Remove Transformer before print */
        stageCopy.removeName('Transformer');
        let url = stageCopy.toDataURL();
        let data = JSON.stringify({imgCropedData: url});
        post('https://uploader.radbag.de/uploadCropFile', data, config).then(response => {
            url = response.data.imgCropedData;
            this.submitChanges(url)
        }).catch(() => {
            console.warn('Error during cropped image saving.')
        });
    }

    submitChanges(url, hide) {
        let {width, height} = this.props.activeElement.attrs;
        let {newBoxWidth, newBoxHeight} = this.state.crop;
        let
            newMin = Math.min(newBoxWidth, newBoxHeight),
            newMax = Math.max(newBoxWidth, newBoxHeight);

        this.props.onEditImageElement({
            // rotation: 0,
            width: newBoxWidth > newBoxHeight ? height * (newMax / newMin) : width,
            height: newBoxHeight > newBoxWidth ? width * (newMax / newMin) : height,
            imageCrop: this.state.crop,
            element: this.props.activeElement.attrs.name,
            activePage: this.props.activePage,
        });


        this.props.pages[0].canvasElements.forEach((item) => {
            if ((item.faceId === this.props.activeElement.attrs.faceId
                && this.props.activeElement.attrs.faceId !== 0
                && this.props.activeElement.attrs.elementRepeatOval !== true
                && this.props.activeElement.attrs.elementRepeat !== true
                && this.props.activeElement.attrs.faceId !== undefined) || this.props.selectedElements.includes(item.name)) {
                    this.props.onEditImageElement({
                        // rotation: 0,
                        width: newBoxWidth > newBoxHeight ? height * (newMax / newMin) : width,
                        height: newBoxHeight > newBoxWidth ? width * (newMax / newMin) : height,
                        imageCrop: this.state.crop,
                        element: item.name,
                        activePage: this.props.activePage,
                    });
            }
        });


        // this.props.activeElement.attrs.rotation = 0

        // this.props.onEditImageElement({
        //     rotation: 0,
        //     element: this.props.activeElement.attrs.name,
        //     activePage: this.props.activePage,
        // });

        if (hide) this.props.hidePopup();
    }

    checkNode() {
        let
            transformer = this.transformer.current,
            activeElement = this.state.activeElement || this.props.activeElement;
        transformer.attachTo(this.rect.current);
        let
            width = this.props.activeElement.attrs.image.width,
            height = this.props.activeElement.attrs.image.height;
        if (activeElement) {
            this.rect.current.dragBoundFunc((newBox) => {
                let
                    newBoxWidth = this.state.crop.newBoxWidth || this.props.activeElement.attrs.width / 2,
                    newBoxHeight = this.state.crop.newBoxHeight || this.props.activeElement.attrs.height / 2,
                    newBoxX = this.state.crop.newBoxX,
                    newBoxY = this.state.crop.newBoxY,
                    ifX = ((newBox.x >= (PADDING_STAGE / 2)) && ((newBox.x + newBoxWidth) <= (this.state.width - PADDING_STAGE / 2))),
                    ifY = ((newBox.y >= (PADDING_STAGE / 2)) && ((newBox.y + newBoxHeight) <= (this.state.height - PADDING_STAGE / 2)));

                newBox.x = ((this.state.width - PADDING_STAGE / 2) - (newBox.x + newBoxWidth)) > 0 ? newBox.x : newBoxX
                newBox.y = ((this.state.height - PADDING_STAGE / 2) - (newBox.y + newBoxHeight)) > 0 ? newBox.y : newBoxY

                newBox.x = (newBox.x >= (PADDING_STAGE / 2) ? newBox.x : PADDING_STAGE / 2)
                newBox.y = (newBox.y >= (PADDING_STAGE / 2) ? newBox.y : PADDING_STAGE / 2)
                //
                newBoxX = (newBoxX >= (PADDING_STAGE / 2) ? newBoxX : PADDING_STAGE / 2)
                newBoxY = (newBoxY >= (PADDING_STAGE / 2) ? newBoxY : PADDING_STAGE / 2)

                this.setState({
                    crop: {
                        ...this.state.crop,
                        x: ifX ? ((width / 100) * (newBox.x - PADDING_STAGE / 2) / ((this.state.width - PADDING_STAGE) / 100)) : this.state.crop.x,
                        y: ifY ? ((height / 100) * (newBox.y - PADDING_STAGE / 2) / ((this.state.height - PADDING_STAGE) / 100)) : this.state.crop.y,
                        width: (width / 100) * (newBoxWidth / ((this.state.width - PADDING_STAGE) * 0.01)),
                        height: (height / 100) * (newBoxHeight / ((this.state.height - PADDING_STAGE) * 0.01)),
                        newBoxX: (ifX ? ((width / 100) * (newBox.x - PADDING_STAGE / 2) / ((this.state.width - PADDING_STAGE) / 100)) : this.state.crop.x) * ((this.state.width * 100 / this.props.activeElement.attrs.image.width) * 0.01),
                        newBoxY: (ifY ? ((height / 100) * (newBox.y - PADDING_STAGE / 2) / ((this.state.height - PADDING_STAGE) / 100)) : this.state.crop.y) * ((this.state.height * 100 / this.props.activeElement.attrs.image.height) * 0.01),
                        newBoxWidth: newBoxWidth,
                        newBoxHeight: newBoxHeight
                    }
                });

                return {
                    x: (ifX ? newBox.x : newBoxX), //
                    y: (ifY ? newBox.y : newBoxY), //
                }
            })
            transformer.boundBoxFunc((oldBox, newBox) => {
                if (newBox.width > this.state.width - PADDING_STAGE || newBox.width - PADDING_STAGE < 10) {
                    return oldBox;
                } else if (newBox.height > this.state.height - PADDING_STAGE || newBox.height - PADDING_STAGE < 10) {
                    return oldBox;
                } else {
                    this.setState({
                        crop: {
                            x: (width / 100) * (newBox.x - PADDING_STAGE / 2) / ((this.state.width - PADDING_STAGE) / 100),
                            y: (height / 100) * (newBox.y - PADDING_STAGE / 2) / ((this.state.height - PADDING_STAGE) / 100),
                            width: (width / 100) * (newBox.width / ((this.state.width - PADDING_STAGE) * 0.01)),
                            height: (height / 100) * (newBox.height / ((this.state.height - PADDING_STAGE) * 0.01)),
                            newBoxX: newBox.x,
                            newBoxY: newBox.y,
                            newBoxWidth: newBox.width,
                            newBoxHeight: newBox.height
                        }
                    });

                    return newBox;
                }
            });
        }
    }

    checkRatio() {
        let
            width = this.props.activeElement?.attrs?.image?.width,
            height = this.props.activeElement?.attrs?.image?.height;


        let
            newBoxWidth,
            newBoxHeight,
            newBoxX = this.props.activeElement.attrs.cropX * ((this.state.width * 100 / this.props.activeElement.attrs.image.width) * 0.01),
            newBoxY = this.props.activeElement.attrs.cropY * ((this.state.height * 100 / this.props.activeElement.attrs.image.height) * 0.01)

        STAGE_SIZE = Math.min(window.innerWidth - 120, window.innerHeight - 200);

        if (width > height) {
            let newHeight = STAGE_SIZE / (width / height);
            this.setState({
                width: STAGE_SIZE,
                height: newHeight,
                y: ((STAGE_SIZE - newHeight) / 2),
            })
            newBoxWidth = this.props.activeElement.attrs.cropWidth * (((STAGE_SIZE - PADDING_STAGE) * 100 / this.props.activeElement.attrs.image.width) * 0.01)
            newBoxHeight = this.props.activeElement.attrs.cropHeight * (((newHeight - PADDING_STAGE) * 100 / this.props.activeElement.attrs.image.height) * 0.01)
        } else if (width < height) {
            let newWidth = STAGE_SIZE / (height / width);
            this.setState({
                width: newWidth,
                height: STAGE_SIZE,
                x: (STAGE_SIZE - newWidth) / 2,
            });
            newBoxWidth = this.props.activeElement.attrs.cropWidth * (((newWidth - PADDING_STAGE) * 100 / this.props.activeElement.attrs.image.width) * 0.01)
            newBoxHeight = this.props.activeElement.attrs.cropHeight * (((STAGE_SIZE - PADDING_STAGE) * 100 / this.props.activeElement.attrs.image.height) * 0.01)
        } else {
            if (this.props.activeElement.attrs.width > this.props.activeElement.attrs.height || this.props.activeElement.attrs.width < this.props.activeElement.attrs.height) {
            } else {
                newBoxWidth = STAGE_SIZE - PADDING_STAGE;
                newBoxHeight = STAGE_SIZE - PADDING_STAGE;
            }
        }

        this.setState({
            crop: {
                x: this.props.activeElement.attrs.cropX,
                y: this.props.activeElement.attrs.cropY,
                width: this.props.activeElement.attrs.cropWidth,
                height: this.props.activeElement.attrs.cropHeight,
                newBoxX: this.props.activeElement.attrs.newBoxX || newBoxX,
                newBoxY: this.props.activeElement.attrs.newBoxY || newBoxY,
                newBoxWidth,
                newBoxHeight
            }
        });
    }

    render() {
        return (
            <div className="text-form-popup-content">
                <div className="text-form-popup-overlay" onClick={this.props.hidePopup}/>
                <div className="text-form-popup-content-inner text-form-popup-content-inner__imgCrop"
                     style={{transform: `rotate(${this.props.activeElement.attrs.rotation})`}}>

                    {this.state.rotateChanged ? null :
                        <div className={stlCanvas["canvas-loader"]} style={{"position": "absolute", "z-index": "1"}}>
                            <img className={stlCanvas['canvas-loader__image']} key="Loading image" src={loadingImage}
                                 alt={"loading_image"}/>
                        </div>}

                    <div className="text-form-popup-content-title">
                        <Translate content={"Crop Image"}/>
                    </div>
                    <Stage
                        ref={this.stage}
                        y={0}
                        x={0}
                        className={stl['canvas-crop-popup']}
                        width={STAGE_SIZE}
                        height={STAGE_SIZE}
                    >
                        <Layer>
                            <Image
                                // rotation={this.state.rotateChanged ? 0 : this.props.activeElement.attrs.rotation}
                                // offsetX={(this.state.width - PADDING_STAGE / 2) / 2}
                                // offsetY={(this.state.height - PADDING_STAGE / 2) / 2}
                                // x={(PADDING_STAGE / 2) + (this.state.width - PADDING_STAGE / 2) / 2}
                                // y={(PADDING_STAGE / 2) + (this.state.height - PADDING_STAGE / 2) / 2}
                                x={PADDING_STAGE / 2}
                                y={PADDING_STAGE / 2}
                                width={this.state.width - PADDING_STAGE / 2}
                                height={this.state.height - PADDING_STAGE / 2}
                                image={this.props.activeElement.attrs.image}
                                draggable={false}
                            />
                            <Rect
                                x={this.state.crop.newBoxX || this.props.activeElement.attrs.newBoxX || PADDING_STAGE / 2}
                                y={this.state.crop.newBoxY || this.props.activeElement.attrs.newBoxY || PADDING_STAGE / 2}
                                width={this.props.activeElement.attrs.newBoxWidth || this.state.crop.newBoxWidth || this.props.activeElement.attrs.width / 2}
                                height={this.props.activeElement.attrs.newBoxHeight || this.state.crop.newBoxHeight || this.props.activeElement.attrs.height / 2}
                                draggable={true}
                                transformable={true}
                                fill={"blue"}
                                opacity={.1}
                                ref={this.rect}
                            />
                            <Transformer
                                anchorSize={18}
                                rotateEnabled={false}
                                ignoreStroke={false}
                                draggable={false}
                                ref={this.transformer}
                            />
                        </Layer>
                    </Stage>
                    <div className="text-form-popup-content-buttons">
                        <Button outline theme="secondary" onClick={() => this.props.hidePopup()}>
                            <Translate content={"Cancel"}/>
                        </Button>
                        <Button theme="secondary" onClick={this.submitChanges.bind(this, undefined, true)}>
                            OK
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        pages: state.glb.pages,
        isShowImageCropPopup: state.tol.showImageCropPopup,
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
        mode: state.glb.mode,
        selectedElements: state.glb.selectedElements,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditImageElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data));
        },

        setItemDownloadingStatus: (data) => dispatch(actionCreators.setItemDownloadingStatus(data)),
        hidePopup: () => dispatch(actionCreators.hidePopup()),
        onShowTextPopup: () => dispatch(actionCreators.showTextPopup())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageCropPopup);
