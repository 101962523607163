export const ADD_CANVAS_ELEMENT = 'ADD_CANVAS_ELEMENT';
export const UPDATE_CANVAS_ELEMENT = 'UPDATE_CANVAS_ELEMENT';
export const GET_CANVAS_ELEMENT = 'GET_CANVAS_ELEMENT';

export const GET_MASKS_ELEMENTS = 'GET_MASKS_ELEMENTS';
export const SET_MASKS_ELEMENTS = 'SET_MASKS_ELEMENTS';
export const SET_ACTIVE_MAKS_ELEMENT = 'SET_ACTIVE_MAKS_ELEMENT';
export const TOGGLE_MASK_DRAW_MODE = 'TOGGLE_MASK_DRAW_MODE';
export const EDIT_MASKS_ELEMENTS = 'EDIT_MASKS_ELEMENTS';
export const ADD_MASKS_ELEMENT = 'ADD_MASKS_ELEMENT';

export const COPIED_CANVAS_ELEMENT = 'COPIED_CANVAS_ELEMENT';
export const SET_ACTIVE_CANVAS_ELEMENT = 'SET_ACTIVE_CANVAS_ELEMENT';

export const INIT_DESIGNER = 'INIT_DESIGNER';
export const ADD_PREVIEW_LOADING_COUNTER = 'ADD_PREVIEW_LOADING_COUNTER';
export const SET_INIT_PARAMS = 'SET_INIT_PARAMS';
export const ADD_ELEMENT_TO_PAGE = 'ADD_ELEMENT_TO_PAGE';
export const DELETE_SELECTED_ELEMENT = 'DELETE_SELECTED_ELEMENT';
export const EDIT_ELEMENT_ATTRS = 'EDIT_ELEMENT_ATTRS';
export const SET_NEW_ELEMENT_INDEX = 'SET_NEW_ELEMENT_INDEX';
export const PREVIEW_UPDATE_STATUS = 'PREVIEW_UPDATE_STATUS';
export const SET_ACTIVE_ELEMENT = 'SET_ACTIVE_ELEMENT';
export const SET_SELECTED_ELEMENTS = 'SET_SELECTED_ELEMENTS';
export const SET_ELEMENT_LABELS = 'SET_ELEMENT_LABELS';
export const SET_ELEMENT_STORE_VIEW_VALUES = 'SET_ELEMENT_STORE_VIEW_VALUES';
export const SET_ACTIVE_LAYER = 'SET_ACTIVE_LAYER';
export const GET_ACTIVE_ELEMENT = 'GET_ACTIVE_ELEMENT';
export const REFRESH_UPLOADED_IMAGES = 'REFRESH_UPLOADED_IMAGES';
export const UPDATE_STATUS_TEXT = 'UPDATE_STATUS_TEXT';
export const UPDATE_DESIGNER_STATUS = 'UPDATE_DESIGNER_STATUS';

export const TOOLS_MENU_SHOW = 'TOOLS_MENU_SHOW';
export const TOOLS_USER_ALLOWED = 'TOOLS_USER_ALLOWED';
export const TOOLS_PROPERTIES_USER_ALLOWED = 'TOOLS_PROPERTIES_USER_ALLOWED';

export const SET_DESIGNER_MODE = 'SET_DESIGNER_MODE';
export const GET_DESIGNER_MODE = 'GET_DESIGNER_MODE';

export const SET_UPLOADED_IMAGES = 'SET_UPLOADED_IMAGES';
export const SET_STAGE = 'SET_STAGE';
export const SET_IMG_STATUS_ERROR = 'SET_IMG_STATUS_ERROR';
export const SHOW_GRID = 'SHOW_GRID';
export const SHOW_GUIDELINE = 'SHOW_GUIDELINE';
export const LOAD_DESIGN_CONFIGURATION = 'LOAD_DESIGN_CONFIGURATION';

export const SHOW_TEXT_FORM = 'SHOW_TEXT_FORM';
export const IS_ITEM_DOWNLOADING = 'IS_ITEM_DOWNLOADING';

export const SHOW_EMOJI_FORM = 'SHOW_EMOJI_FORM';
export const HIDE_TOOLS_MENU = 'HIDE_TOOLS_MENU';
export const SHOW_IMAGE_CROP_POPUP = 'SHOW_IMAGE_CROP_POPUP';
export const SHOW_FACE_CROP_POPUP = 'SHOW_FACE_CROP_POPUP';
export const SHOW_BACKGROUND_CHANGE_POPUP = 'SHOW_BACKGROUND_CHANGE_POPUP';
export const SHOW_TEXT_CHANGE_POPUP = 'SHOW_TEXT_CHANGE_POPUP';
export const SHOW_ORDER_PREVIEW_POPUP = 'SHOW_ORDER_PREVIEW_POPUP';
export const SHOW_IMAGE_CHANGE_POPUP = 'SHOW_IMAGE_CHANGE_POPUP';
export const SHOW_TEXT_POPUP = 'SHOW_TEXT_POPUP';
export const SHOW_QUICK_EDIT_LABELS_POPUP = 'SHOW_QUICK_EDIT_LABELS_POPUP';
export const SHOW_TEXT_EDIT_POPUP = 'SHOW_TEXT_EDIT_POPUP';
export const SHOW_UPLOAD_FORM = 'SHOW_UPLOAD_FORM';
export const SHOW_BACKGROUNDS_FORM = 'SHOW_BACKGROUNDS_FORM';
export const SHOW_DESIGNS_FORM = 'SHOW_DESIGNS_FORM';
export const SHOW_SHAPES_FORM = 'SHOW_SHAPES_FORM';
export const SHOW_ELEMENTS_LIST = 'SHOW_ELEMENTS_LIST';
export const HIDE_POPUP = 'HIDE_POPUP';

export const GENERATE_PREVIEW = 'GENERATE_PREVIEW';
export const ADD_PREVIEW_IMAGE = 'ADD_PREVIEW_IMAGE';
export const ADD_RENDERERS = 'ADD_RENDERERS';


export const SET_BORDER_LIMIT_EDITING_STATUS = 'SET_BORDER_LIMIT_EDITING_STATUS';
export const SET_CUSTOM_MASK_EDITING_STATUS = 'SET_CUSTOM_MASK_EDITING_STATUS';