import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import * as actionCreators from "../../../store/actions/index";
import clsx from "clsx";
import Translate from "react-translate-component";
import colorIcon from '../../../assets/images/color.svg';
import {DESKTOP_WIDTH} from "../../../config";
import Switch from "react-switch/index";
import {Button} from "shards-react";
import expand_more from "../../../assets/images/expand-more.png";

class PositionProperty extends Component {

    colorFontStroke = React.createRef();

    state = {
        textColorsListRecently: [],
        colorDropdown: false,
        currentStrokeColor: this.props.activeElement.attrs.stroke,
        newStrokeColor: ''
    };

    componentDidMount() {
        this.setProperties(this.props.activeElement.attrs);
    }

    componentWillUnmount() {
        // document.querySelectorAll(".properties-column")[0].classList.remove("active")
    }


    setProperties(attrs) {
        if (this.colorFontStroke.current) this.colorFontStroke.current.style.backgroundColor = attrs.stroke;
    }

    addColorToRecantlyList(color) {
        let colorsList = this.state.textColorsListRecently;
        if (colorsList.indexOf(color) === -1) {
            this.setState({
                textColorsListRecently: [color, ...colorsList.length >= 8 ? colorsList.slice(0, -1) : colorsList]
            });
        }
    }

    handleChangeableClick(e) {
        this.props.pages[0].canvasElements.forEach((item) => {
            if ((item.elementId === this.props.activeElement.attrs.elementId
                && this.props.activeElement.attrs.elementId !== 0
                && this.props.activeElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.name)) {
                this.props.onEditElement({
                    quick_edit_strokeColor: e,
                    element: item.name,
                    activePage: this.props.activePage
                });
            }
        });
    }

    handleColorChange(color) {
        this.props.pages[0].canvasElements.forEach((item) => {
            if ((item.elementId === this.props.activeElement.attrs.elementId
                && this.props.activeElement.attrs.elementId !== 0
                && this.props.activeElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.name)) {
                this.props.onEditElement({
                    colorFontStroke: "#" + color,
                    element: item.name,
                    activePage: this.props.activePage
                });
            }
        });

        this.addColorToRecantlyList(color);
        this.colorFontStroke.current.style.backgroundColor = "#" + color;
        this.setState({
            newStrokeColor: color
        })
    }


    saveStrokeColor = () => {
        //TODO refactor (reuse)
        if (window.innerWidth <= DESKTOP_WIDTH) {
            let name = this.props.activeElement.attrs.name;
            this.props.onSelectElement("");
            setTimeout(() => {
                this.props.onSelectElement(name);
            }, 0)
        }
    }

    closeStrokeColor = () => {
        const {currentStrokeColor} = this.state;
        const replaceCurrentStrokeColor = currentStrokeColor ? currentStrokeColor.replace('#', '') : currentStrokeColor

        this.props.onEditElement({
            colorFontStroke: currentStrokeColor ? `#${replaceCurrentStrokeColor}` : '',
            element: this.props.activeElement.attrs.name,
            activePage: this.props.activePage
        });
        this.colorFontStroke.current.style.backgroundColor = currentStrokeColor ? `#${replaceCurrentStrokeColor}` : '';
        if (window.innerWidth <= DESKTOP_WIDTH) {
            let name = this.props.activeElement.attrs.name;
            this.props.onSelectElement("");
            setTimeout(() => {
                this.props.onSelectElement(name);
            }, 0)
        }
    }


    render() {
        const {newStrokeColor, currentStrokeColor} = this.state;
        let activePage = this.props.pages[this.props.activePage];
        let colors = [];
        for (let key in activePage.colors) {
            if (!activePage.colors.hasOwnProperty(key)) continue;
            let color = activePage.colors[key];
            colors.push(color.color_code);
        }

        //TODO refactor (reuse)
        const replaceCurrentStrokeColor = currentStrokeColor ? currentStrokeColor.replace('#', '') : currentStrokeColor
        const activeStrokeColor = newStrokeColor || replaceCurrentStrokeColor;


        return (
            <>
                {/*&& this.props.propertyTextStrokeColorAllowed*/}
                {(this.props.activeElement?.attrs.quick_edit_strokeColor === true) || this.props.mode === 'ADMIN' ? <div
                    className={clsx("designer-right-panel-item", "designer-right-panel-item__colorFont", this.state.colorDropdown && "active")}>
                    <div className={"designer-right-panel-item__color"} onClick={() => {
                        this.setState({
                            colorDropdown: !this.state.colorDropdown
                        })
                    }
                    }>
                        <div className={"designer-right-panel-item__titleFlex"}>
                            <span className={"text"}>
                                <Translate content={"Stroke color"}/>
                                <img className={clsx("arrow", this.state.colorDropdown && "active")} src={expand_more}
                                     alt=""/>
                            </span>
                        </div>
                        <div className={"designer-right-panel-colorBox"}
                             ref={this.colorFontStroke}/>
                    </div>
                    <div className={"dropdownContent"}>
                        {/*<div className="dropdownContentBtn">*/}
                        {/*    <Button outline theme="secondary" onClick={this.closeStrokeColor}>*/}
                        {/*        <Translate content={"Cancel"}/>*/}
                        {/*    </Button>*/}
                        {/*    <Button theme="secondary" onClick={this.saveStrokeColor}>*/}
                        {/*        OK*/}
                        {/*    </Button>*/}
                        {/*</div>*/}


                        <div className={"colorSwitchList"}>
                            {colors.map((item) => {
                                return (
                                    <button
                                        key={item}
                                        onClick={this.handleColorChange.bind(this, item)}
                                        className={`colorSwitchBtn ${activeStrokeColor && activeStrokeColor.toUpperCase() === item.toUpperCase() ? 'colorSwitchBtnActive' : ''}`}
                                        style={{'backgroundColor': '#' + item}}>
                                    </button>
                                )
                            })}
                        </div>
                        {this.state.textColorsListRecently.length > 0 ?
                            <div className={"colorSwitchList__recently"}>
                                <div>
                                    <Translate content={"Recently Used Colors"}/>
                                </div>
                                <div className={"colorSwitchList"}>
                                    {this.state.textColorsListRecently.map((item) => {
                                        return (
                                            <button
                                                key={item}
                                                onClick={this.handleColorChange.bind(this, item)}
                                                className="colorSwitchBtn" data-color={"#" + item}
                                                style={{'backgroundColor': '#' + item}}>
                                            </button>
                                        )
                                    })}
                                </div>
                            </div> : null
                        }
                    </div>
                </div> : null}

                {this.props.mode === 'ADMIN' ? (
                    <div className="designer-right-panel-item designer-right-panel-item--admin">
                        <div className={"designer-right-panel-item__titleFlex"}>
                            <Switch className={"custom-checkbox"}
                                    onChange={this.handleChangeableClick.bind(this)}
                                    checked={this.props.activeElement.attrs.quick_edit_strokeColor || false}/>
                            Allow change stroke color
                        </div>
                    </div>
                ) : null
                }

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
        pages: state.glb.pages,
        mode: state.glb.mode,
        propertyTextStrokeColorAllowed: state.tol.propertyTextStrokeColorAllowed,
        selectedElements: state.glb.selectedElements,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onEditElement: (data) => dispatch(actionCreators.editElementAttrs(data)),
        onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PositionProperty);
