import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import stl from "./FaceCropPopup.module.scss"

import close_btn from '../../assets/images/iconfinder_Close_6351931 2.png'
import back_btn from '../../assets/images/expand-more.png'

import {Button} from "shards-react";

import Translate from "react-translate-component";


class BackgroundChangePopup extends Component {
    state = {
        colorsShow: {
            values: [],
            show: false,
        },
        designShow: {
            values: [],
            show: false,
        },
        whatShow: 'shop',
    };
   

    componentDidMount() {
        
        this.generatePreview();
        let activePage = this.props.pages[this.props.activePage];

        let colors = [];
        for (let key in activePage.backgrounds) {
            let item = activePage.backgrounds[key]
            if (activePage.backgrounds[key].type === 'color') {
                colors.push(item.color_code);
            }
        }

        let backgrounds = [];
        let pageBackgrounds = activePage.designs;

        for (let key in pageBackgrounds) {
            if (!pageBackgrounds.hasOwnProperty(key)) continue;
            let bck = pageBackgrounds[key];
            backgrounds.push({key: key, background_image: bck.image, smallBackground_image: bck.small_image});
        }

        console.log({
            colorsShow: {
                values: colors,
                show: colors.length > 0,
            },
            designShow: {
                values: backgrounds,
                show: backgrounds.length > 0,
            }
        })

        this.setState({
            colorsShow: {
                values: colors,
                show: colors.length > 0,
            },
            designShow: {
                values: backgrounds,
                show: backgrounds.length > 0,
            },
            whatShow: backgrounds.length>0 ? 'design' : (colors.length>0 ? 'color' : 'shop'),
        });
    }

    generatePreview() {
        if (this.props.stage) {
            console.log(this.props.isItemDownloading);
            if (this.props.isItemDownloading > 0) {
                setTimeout(() => {
                    this.generatePreview()
                }, 1000);
            } else {
                let stage = this.props.stage.clone();
                this.props.generatePreview({
                    pages: this.props.pages,
                    activePage: this.props.activePage,
                    stage: stage,
                    previewUrl: this.props.previewUrl
                });
                this.props.setPreviewUpdateStatus({previewShouldBeChanged: false})
                setTimeout(() => {
                    document.getElementById("loaderdv_id").style.display="none";
                    var myEle = document.getElementById("prv_id");
                    if(myEle){
                        myEle.style.opacity="1";
                    }
                }, 35000); //37000
            }
        }
    }


    changeDesign(elem) {
        console.log('changeDesign');
        let page = this.props.pages[this.props.activePage];
        let pageElements = page.canvasElements;

        pageElements.forEach((item) => {
            if (item.name === 'Background') {
                let backgroundItem = item;
                backgroundItem.data = elem.background_image;
                this.props.onEditImageElement({
                    backgroundItem,
                    data: backgroundItem,
                    bgColor: false,
                    historyMode: true,
                    element: item.name,
                    smallBackground_image: elem.smallBackground_image,
                    activePage: this.props.activePage,
                });
            }
        });
        document.getElementById("loaderdv_id").style.display="flex";
        document.getElementById("prv_id").style.opacity="0.5";
        //this.props.setPreviewUpdateStatus({previewShouldBeChanged: true});

        setTimeout(()=>{
            this.generatePreview();
        }, 0)

    }

    changeBackgroundColor(elem) {
        console.log('changeBackgroundColor');
        let page = this.props.pages[this.props.activePage];
        let pageElements = page.canvasElements;
        pageElements.forEach((item) => {
            if (item.name === 'Background2') {
                this.props.onEditImageElement({
                    // backgroundItem : '',
                    data: undefined,
                    bgColor: '#' + elem,
                    historyMode: true,
                    element: item.name,
                    activePage: this.props.activePage,
                });
            }
        });
        document.getElementById("loaderdv_id").style.display="flex";
        document.getElementById("prv_id").style.opacity="0.5";
        //this.props.setPreviewUpdateStatus({previewShouldBeChanged: true});
        setTimeout(()=>{
            this.generatePreview();
        }, 0)

    }

    render() {
        //console.log(this.state,'<========----->',this.props);
        if(this.props.displaytype=='design'){
            this.state.whatShow = 'design';
        }else if(this.props.displaytype=='color'){
            this.state.whatShow = 'color';
        }else{}

        const {previews: previewsProps} = this.props;
        let previews = previewsProps.map((item) => {
            return (
                <img id="prv_id" key={item.name} src={item.image} alt={''}/>
            )
        });

        return (
            <div className="text-form-popup-content">
                <div className="text-form-popup-overlay"
                     onClick={this.props.hidePopup}/>
                <div
                    className={'text-form-popup-content-inner'}>
                    <div className="text-form-popup-content-title__wrap">
                        <div className="text-form-popup-back"
                             onClick={() => {
                                 this.props.hidePopup();
                                 this.props.showFaceCropPopup();
                                 this.props.setPreviewUpdateStatus({previewShouldBeChanged: true})
                             }}>
                            <img src={back_btn} alt=""/>
                        </div>
                        <div className="text-form-popup-content-title">
                            {/* {this.state.colorsShow.values.length > 0 && this.state.colorsShow.show ? 'Background' : 'Design'} */}
                            {this.state.whatShow == 'color' ? 'Hintergundfarbe anpassen' : 'Design anpassen'}
                        </div>
                        <div className="text-form-popup-close"
                             onClick={() => {
                                 window.parent.postMessage({datatype: "hide_designer"}, "*")
                                 this.props.setPreviewUpdateStatus({previewShouldBeChanged: true})
                                 window.onbeforeunload = null;
                             }}>
                            <img src={close_btn} alt=""/>
                        </div>
                    </div>

                    <div className={'bgChangePopup'}>
                        <div className={'bgChangePopup__preview'}>
                            {this.props?.pages[this.props.activePage]?.renderers?.length > 0 ? (previews?.length > 0 && !this.props.previewShouldBeChanged ? previews :
                                <Translate content={"Loading"}/>) : "preview not fount"}
                            <div id="loaderdv_id" style={{display:"none"}}><Translate content={"Loading"}/></div>
                        </div>
                        {/* {this.state.colorsShow.values.length > 0 && this.state.colorsShow.show ? ( */}
                        {this.state.colorsShow.values.length > 0 && this.state.whatShow == 'color' ? (
                            <div className="designer-tools-backgrounds-list">
                                <div className={"designer-tools-backgrounds-list-colors"}>
                                    <div className={"colorSwitchList"}>
                                        {this.state.colorsShow.values.map((item, index) => {
                                            return (
                                                <Button key={index}
                                                        onClick={this.changeBackgroundColor.bind(this, item)}
                                                        // className={`colorSwitchBtn ${ activeColor && activeColor.toUpperCase() === item.toUpperCase() ? 'colorSwitchBtnActive' : ''}`}
                                                        style={{'backgroundColor': '#' + item}}>
                                                </Button>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>) : null
                        }

                        {/* {this.state.designShow.values.length > 0 && this.state.designShow.show && !this.state.colorsShow.show ? ( */}
                        {this.state.designShow.values.length > 0 && this.state.whatShow == 'design' ? (
                            <div className="designer-tools-backgrounds-list designer-tools-backgrounds-list__popup">
                                {this.state.designShow.values.map((image, i) => {
                                    return (
                                        <img key={i}
                                             className="background-image"
                                             src={image.smallBackground_image || image.background_image}
                                             alt="alt"
                                             onClick={this.changeDesign.bind(this, image)}/>
                                    );
                                })}
                            </div>
                        ) : null}
                    </div>
                    <Button outline theme="secondary"
                            style={{
                                margin: '30px auto',
                                display: 'flex',
                                border: 'none',
                                fontWeight: '600',
                                padding: '16px',
                                fontSize: '16px',
                                color: '#FFFFFF',
                                background: '#333333',
                                borderRadius: '100px',
                            }}
                            onClick={() => {

                                let shouldTextPopupOpen = false;
                                this.props.pages[0].canvasElements.forEach((i)=>{
                                    if(i.type === "text" && i.changeable){
                                        shouldTextPopupOpen = true
                                    }
                                });
                                if(shouldTextPopupOpen){
                                    this.props.onShowTextForm();
                                    this.props.showTextChangePopup();
                                } else {
                                    if(this.props.displaytype == 'design' || this.props.displaytype == 'color'){
                                        setTimeout(() => {
                                            this.props.showOrderPreviewPopup()
                                        }, 0)
                                    }else{
                                        if(this.state.whatShow=='color' || this.state.whatShow == 'shop'){
                                            setTimeout(() => {
                                                this.props.showOrderPreviewPopup()
                                            }, 0)
                                        }else{
                                            if(this.state.colorsShow.values.length>0){
                                                this.setState({
                                                    whatShow: this.state.whatShow =='design' ? 'color' : 'shop',
                                                });
                                            }else{
                                                setTimeout(() => {
                                                    this.props.showOrderPreviewPopup()
                                                }, 0)
                                            }
                                        }
                                    }
                                }
                                console.log("shouldTextPopupOpen", shouldTextPopupOpen);


                            }}
                    >
                        {/* <Translate content={"done"}/> */}
                        <Translate content={"Continue"}/>
                    </Button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        activePage: state.glb.activePage,
        stage: state.glb.stage,
        pages: state.glb.pages,
        previewUrl: state.glb.previewUrl,
        previews: state.prv.previews,
        isItemDownloading: state.glb.isItemDownloading,
        displaytype: state.glb.displaytype,
        //previewShouldBeChanged: state.glb.previewShouldBeChanged,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditImageElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data));
        },
        setItemDownloadingStatus: (data) => dispatch(actionCreators.setItemDownloadingStatus(data)),
        generatePreview: (previewData) => dispatch(actionCreators.generatePreview(previewData)),
        showOrderPreviewPopup: () => dispatch(actionCreators.showOrderPreviewPopup()),
        hidePopup: () => dispatch(actionCreators.hidePopup()),
        showFaceCropPopup: (data) => dispatch(actionCreators.showFaceCropPopup({data: data})),
        addElementToPage: (canvasElement) => dispatch(actionCreators.addElementToPage(canvasElement)),
        setPreviewUpdateStatus: (data) => {
            dispatch(actionCreators.setPreviewUpdateStatus(data));
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundChangePopup);
