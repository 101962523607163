import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import * as actionCreators from "../../../store/actions/index";
import Switch from "react-switch/index";
import Translate from "react-translate-component";
import {FormInput} from "shards-react";
import {DESKTOP_WIDTH} from "../../../config";
import clsx from "clsx";

class PositionProperty extends Component {

    strokeWidth = React.createRef()

    state = {
        fontStrokeDropdown: false,
        currentFontStroke: this.props.activeElement.attrs.strokeWidth,
        newFontStroke: this.props.activeElement.attrs.strokeWidth || '0',
    }

    handleFontSizeChange(e) {
        this.props.pages[0].canvasElements.forEach((item) => {
            if ((item.elementId === this.props.activeElement.attrs.elementId
                && this.props.activeElement.attrs.elementId !== 0
                && this.props.activeElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.name)) {
                this.props.onEditElement({
                    fontStrokeWidth: Math.abs(parseFloat(e.target.value)),
                    element: item.name,
                    activePage: this.props.activePage
                });
            }
        });
    }

    componentDidMount() {
        this.setProperties(this.props.activeElement.attrs);
    }


    setProperties(attrs) {
        if (this.strokeWidth.current) this.strokeWidth.current.value = attrs.strokeWidth;
    }

    onKeyDown(e) {
        if (e.keyCode === 188 && window.navigator.userAgent.indexOf('Firefox') !== -1) {
            e.target.value = e.target.value + ".";
            e.preventDefault();
        }
    }

    handleChangeableClick(e) {


        this.props.pages[0].canvasElements.forEach((item) => {
            if ((item.elementId === this.props.activeElement.attrs.elementId
                && this.props.activeElement.attrs.elementId !== 0
                && this.props.activeElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.name)) {
                this.props.onEditElement({
                    quick_edit_stroke: e,
                    element: item.name,
                    activePage: this.props.activePage
                });
            }
        });
    }


    saveFontStroke = () => {
        if (window.innerWidth <= DESKTOP_WIDTH) {
            let name = this.props.activeElement.attrs.name;
            this.props.onSelectElement("");
            setTimeout(() => {
                this.props.onSelectElement(name);
            }, 0)
        }
    }

    closeFontStroke = () => {
        const {currentFontStroke} = this.state;
        const condition = this.props.activeElement.attrs.stroke && currentFontStroke === undefined ? 1 : Math.abs(parseFloat(currentFontStroke))
        this.props.onEditElement({
            fontStrokeWidth: condition,
            element: this.props.activeElement.attrs.name,
            activePage: this.props.activePage
        });
        if (window.innerWidth <= DESKTOP_WIDTH) {
            let name = this.props.activeElement.attrs.name;
            this.props.onSelectElement("");
            setTimeout(() => {
                this.props.onSelectElement(name);
            }, 0)
        }
    }

    changeFontStroke = (type) => {
        const {newFontStroke} = this.state;
        const updateFontStroke = type ? +newFontStroke + 1 : +newFontStroke - 1;



        this.props.pages[0].canvasElements.forEach((item) => {
            if ((item.elementId === this.props.activeElement.attrs.elementId
                && this.props.activeElement.attrs.elementId !== 0
                && this.props.activeElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.name)) {
                this.props.onEditElement({
                    fontStrokeWidth: updateFontStroke < 0 ? 0 : updateFontStroke,
                    element: item.name,
                    activePage: this.props.activePage,
                });
            }
        });

        this.setState({
            newFontStroke: updateFontStroke < 0 ? 0 : updateFontStroke,
        })
    }


    render() {

        return (
            <>
                {(this.props.activeElement.attrs.quick_edit_stroke === true) || this.props.mode === 'ADMIN' ?
                    <div
                        className={clsx("designer-right-panel-item", "designer-right-panel-item__colorFont", "designer-right-panel-item__FontStroke", "active")}>
                        <div className={"designer-right-panel-item__titleFlex"}>
                        <span className={"text"}>
                        <Translate content={"Stroke"}/>
                    </span>
                        </div>
                        <div className={"designer-right-panel-inputNumber"}>
                            <FormInput type="number"
                                       size="sm"
                                       defaultValue={this.props.activeElement.attrs.strokeWidth}
                                       min={0}
                                       onKeyDown={this.onKeyDown}
                                       innerRef={this.strokeWidth}
                                       onChange={this.handleFontSizeChange.bind(this)}
                            />
                        </div>

                    </div> : null}

                {this.props.mode === 'ADMIN' ? (
                    <div className="designer-right-panel-item designer-right-panel-item--admin">
                        <div className={"designer-right-panel-item__titleFlex"}>
                            <Switch className={"custom-checkbox"}
                                    onChange={this.handleChangeableClick.bind(this)}
                                    checked={this.props.activeElement.attrs.quick_edit_stroke || false}/>
                            Allow change stroke
                        </div>
                    </div>
                ) : null
                }
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        pages: state.glb.pages,
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
        mode: state.glb.mode,
        propertyTextStrokeAllowed: state.tol.propertyTextStrokeAllowed,
        selectedElements: state.glb.selectedElements,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onEditElement: (data) => dispatch(actionCreators.editElementAttrs(data)),
        onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PositionProperty);
