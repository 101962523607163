import React, {Component} from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import stl from "./ToolsViews.module.scss";
import * as actionCreators from '../../../store/actions/index';
import Dropzone from '../../Dropzone/Dropzone';
import Progress from '../../Progress/Progress';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Translate from "react-translate-component";
import TranslateForAlert from '../../../helpes/translateForAlert'

import {DESKTOP_WIDTH, IMAGE_MAX_SIZE} from "../../../config";
import cropImage from "../../../helpes/cropImage";
import {Button} from "shards-react";
import new_img_icon from "../../../assets/images/Frame 208.svg";

import minus_img_icon from "../../../assets/images/faceCropMinus_icon.svg";
import plus_img_icon from "../../../assets/images/faceCropPlus_icon.svg";

import rotate_img_icon from "../../../assets/images/Frame 134.svg";
import zoom_img_icon from "../../../assets/images/Frame 135.png";
import cut_img_icon from "../../../assets/images/Frame 140.svg";
import change_img_icon from "../../../assets/images/Frame 144.svg";
import close_btn from "../../../assets/images/iconfinder_Close_6351931 2.png";
import clsx from "clsx";
import queryString from "query-string";

const IMAGE_MIN_WIDTH = 50;
const IMAGE_MIN_HEIGHT = 50;
const STAGE_SIZE = 800;

const params = queryString.parse(window.location.search);

class ImgUploader extends Component {
    constructor(props) {
        super(props);
        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.sendRequest = this.sendRequest.bind(this);
        this.dropzone = React.createRef();
        this.clearInt = this.clearInt.bind(this);

        this.imagesList = React.createRef();
        this.state = {
            isFirstImgLoaded: false,
            files: [],
            filesDimensions: [],
            uploading: false,
            uploadProgress: {},
            filesList: [],
            successfullUploaded: false,
            waitingForUpload: true,
            uploadLength: 0,
            uploaderResponse: {},
            newImgUploader: false,
            elRotationOpened: false,
            elZoomOpened: false,
        };
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.showImageUploadForm && (this.state.newImgUploader)) {
            this.setState({
                newImgUploader: false
            })
        }
    }

    ImgRerender(imgAttrs, fullImageUrl, cW, cH, width, height) {
        setTimeout(() => {
            let needRerender = false;
            this.props.pages[0].canvasElements.map((item) => {
                if (((imgAttrs?.faceId > 0 && item?.faceId > 0 && imgAttrs?.faceId > 0 && imgAttrs?.faceId === item?.faceId && item.type === 'image' && imgAttrs.name !== item.name) && imgAttrs?.elementRepeat !== true && imgAttrs?.elementRepeatOval !== true) || this.props.selectedElements.includes(item.name)) {
                    if (item.data !== fullImageUrl) {
                        this.props.onEditImageElement({
                            imageWithoutBg: 'empty',
                            isThisElemEditedByUser: true,
                            newImgData: {
                                imageUrl: fullImageUrl,
                                width: cW,
                                height: cH,
                            },
                            imageCrop: cropImage(cW, cH, width, height),
                            element: item.name,
                            activePage: 0,
                        });
                        // needRerender = true
                    }
                }
            });
            if (needRerender) {
                console.log("needRerender");
                this.ImgRerender(imgAttrs, fullImageUrl, cW, cH, width, height);
            }
        }, 0);
    }

    selectUploadedImage(imgUrl, index, filesDimensions) {
        this.props.setItemDownloadingStatus({
            isItemDownloading: 1
        });

        setTimeout(()=>{
            if (this.props.isShowImageChangePopup) {
                /* Change thumb to full image URL */
                let fullImageUrl = imgUrl.replace('/thumbs', '/resized');
                let timeStart = +new Date();

                let imgAttrs = this.props.activeElement?.attrs;
                axios.get(fullImageUrl).then(() => {

                    let
                        cW = imgAttrs?.width || imgAttrs?.image?.width,
                        cH = imgAttrs?.height || imgAttrs?.image?.height,
                        width = filesDimensions.width,
                        height = filesDimensions.height;

                    if (width === null || height === null || imgAttrs === undefined) {
                        TranslateForAlert("There was error during getting files!")
                    } else {
                        if (width >= IMAGE_MIN_WIDTH && height >= IMAGE_MIN_HEIGHT) {
                            if ((imgAttrs?.elementRepeat || imgAttrs?.elementRepeatOval) && imgAttrs?.type === "image") {
                                this.props.pages[0].canvasElements.map((item) => {
                                    // (item.attrs?.elementRepeat && imgAttrs?.faceId !== undefined && imgAttrs?.faceId === item.attrs?.faceId) ||
                                    if (item.name === imgAttrs.name) {
                                        this.props.onEditImageElement({
                                            isThisElemEditedByUser: true,
                                            imageWithoutBg: 'empty',
                                            newImgData: {
                                                imageUrl: fullImageUrl,
                                                width: item.width,
                                                height: item.height,
                                            },
                                            historyMode: false,
                                            element: item.name,
                                            activePage: 0,
                                        });
                                    }
                                    return null
                                });
                                let timer = (+new Date() - timeStart) + 500

                                setTimeout(() => {
                                    this.props.showFaceCropPopup();
                                }, timer);

                                this.props.onSelectElement(imgAttrs?.name);
                            } else {
                                this.props.onEditImageElement({
                                    imageWithoutBg: 'empty',
                                    isThisElemEditedByUser: true,
                                    newImgData: {
                                        imageUrl: fullImageUrl,
                                        width: cW,
                                        height: cH,
                                    },
                                    imageCrop: cropImage(cW, cH, width, height),
                                    element: imgAttrs.name,
                                    activePage: this.props.activePage,
                                });

                                this.ImgRerender(imgAttrs, fullImageUrl, cW, cH, width, height);

                                return null
                            }
                        } else {
                            TranslateForAlert("Your image is too small");
                        }
                    }
                });

                this.props.hidePopup();
                // this.props.onSelectElement('');
                // this.setState({
                //     newImgUploader: false
                // })

                // turn off loader

            } else if (this.props.showImageUploadForm) {
                /* Change thumb to full image URL */
                let fullImageUrl = imgUrl;
                fullImageUrl = fullImageUrl.replace('/thumbs', '/resized');
                const naturalWidth = filesDimensions.width;
                const naturalHeight = filesDimensions.height;

                if (naturalWidth >= IMAGE_MIN_WIDTH && naturalHeight >= IMAGE_MIN_HEIGHT) {
                    let {
                        width,
                        height
                    } = this.checkImgSize(filesDimensions, STAGE_SIZE / 3);

                    this.props.addElementToPage({
                        type: 'image',
                        data: fullImageUrl,
                        imgSize: {width, height},
                        width,
                        height,
                        naturalWidth,
                        naturalHeight,
                        activePage: this.props.activePage
                    });


                    setTimeout(() => {
                        this.props.onSelectElement(this.props.stage.children[this.props.activePage].children[this.props.stage.children[this.props.activePage].children.length - 2].attrs.name)
                    }, 50)

                    // }
                    if (window.innerWidth <= DESKTOP_WIDTH) {
                        this.props.hideToolsMenu();
                        this.props.toolsMenuVisibleToggle({mode: false});
                    }
                } else {
                    TranslateForAlert("Your image is too small");
                }
            }
        }, 1500);

        setTimeout(()=>{
            this.props.setItemDownloadingStatus({
                isItemDownloading: -1
            });
        }, 4500)

    }

    checkImgSize(fileDimension, halfStageSize) {
        if (fileDimension) {
            let {width, height} = fileDimension;
            if (height > halfStageSize) {
                width = halfStageSize / (height / width);
                height = halfStageSize;
            } else if (width > halfStageSize) {
                height = halfStageSize / (width / height);
                width = halfStageSize;
            }
            return {width, height};
        }
    }

    handleElemRotation(rotateValue = 0) {

        this.props.stage.children[this.props.activePage].children.forEach((item) => {
            if ((item.attrs.faceId === this.props.activeElement.attrs.faceId
                && this.props.activeElement.attrs.faceId !== 0
                && this.props.activeElement.attrs.elementRepeatOval !== true
                && this.props.activeElement.attrs.elementRepeat !== true
                && this.props.activeElement.attrs.faceId !== undefined) || this.props.selectedElements.includes(item.attrs.name)) {

                this.props.onEditElement({
                    rotation: (item.rotation() !== undefined ? item.rotation() + rotateValue : 0),
                    element: item.attrs.name,
                    activePage: this.props.activePage
                });
            }
        });
    }

    clearInt(interval) {
        return clearInterval(interval);
    }

    handleSizeChange(val) {
        let {type} = this.props.activeElement.attrs;
        if (type === 'text') {
            this.interval = setInterval(() => {
                this.props.onEditElement({
                    fontSize: parseFloat(this.props.activeElement.attrs.fontSize + val),
                    element: this.props.activeElement.attrs.name,
                    activePage: this.props.activePage
                })

                let item = this.props.activeElement;
                setTimeout(() => {
                    switch (item.attrs.align) {
                        case "right":
                            item.x(item.x() + item.width() - item.offsetX())
                            item.offsetX(item.width());
                            break;
                        case "center":
                            item.x(item.x() + (item.width() / 2 - item.offsetX()))
                            item.offsetX(item.width() / 2);
                            break;
                        default:
                            item.offsetX(0);
                            break;
                    }
                }, 50)

            }, 50);

        } else if (type === 'icon' || type === 'image') {

            this.interval = setInterval(() => {
                this.props.pages[0].canvasElements.forEach((item) => {
                    if ((item.faceId === this.props.activeElement.attrs.faceId
                        && this.props.activeElement.attrs.faceId !== 0
                        && this.props.activeElement.attrs.elementRepeatOval !== true
                        && this.props.activeElement.attrs.elementRepeat !== true
                        && this.props.activeElement.attrs.faceId !== undefined) || this.props.selectedElements.includes(item.name)) {
                        this.props.onEditElement({
                            width: (parseFloat(this.props.activeElement.attrs.width + this.props.activeElement.attrs.width / this.props.activeElement.attrs.height * val) > 25 ? parseFloat(this.props.activeElement.attrs.width + this.props.activeElement.attrs.width / this.props.activeElement.attrs.height * val) : 25),
                            height: (parseFloat(this.props.activeElement.attrs.height + val) > 25 ? parseFloat(this.props.activeElement.attrs.height + val) : 25),
                            element: item.name,
                            activePage: this.props.activePage,
                        })
                    }
                });
            }, 50);
        } else {
            this.interval = setInterval(() => {
                this.props.onEditElement({
                    scaleX: (this.props.activeElement.attrs.scaleX + val / 10) > 0.5 ? this.props.activeElement.attrs.scaleX + val / 10 : 0.5,
                    scaleY: (this.props.activeElement.attrs.scaleX + val / 10) > 0.5 ? this.props.activeElement.attrs.scaleX + val / 10 : 0.5,
                    element: this.props.activeElement.attrs.name,
                    activePage: this.props.activePage,
                })
            }, 50);
        }
    }

    onFilesAdded(files) {
        let leng = this.state.files.length;

        files.forEach((FILE) => {
            if (FILE.size > IMAGE_MAX_SIZE && this.props.mode !== "ADMIN") {
                // alert('File ' + FILE.name + ' is too big! Max file size ' + IMAGE_MAX_SIZE / 1048576 + 'MB');

                TranslateForAlert("Your Image is too big to upload! The max file size is 8 MB")
            } else {
                this.setState(prevState => ({
                    files: prevState.files.filter(item => item.name !== FILE.name).concat(FILE)
                }), () => {
                });
            }
        })

        if (leng < this.state.files.length) this.uploadFiles();
    }

    async uploadFiles() {
        this.setState({uploadProgress: {}, uploading: true, isFirstImgLoaded: true});
        const promises = [];
        this.state.files.forEach(file => {
            promises.push(this.sendRequest(file));
        });

        try {
            await Promise.all(promises);
            this.setState({successfullUploaded: true, uploading: false, files: []});
        } catch (e) {

            TranslateForAlert("There was error during getting files!")
        }
    }

    handlePropertyChange(e, status) {
        this.props.setUserAllowedProperties({
            [e]: status
        });
    }


    sendRequest(file) {
        return new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();
            let width = file.width, height = file.height;

            req.upload.addEventListener("progress", event => {
                if (event.lengthComputable) {
                    const copy = {...this.state.uploadProgress};
                    copy[file.name] = {
                        state: "pending",
                        percentage: (event.loaded / event.total) * 100
                    };
                    this.setState({uploadProgress: copy});
                }
            });

            req.upload.addEventListener("load", (data) => {
                const copy = {...this.state.uploadProgress};
                copy[file.name] = {state: "done", percentage: 100};
                this.setState({uploadProgress: copy});

                resolve(req.response);
            });

            req.upload.addEventListener("error", () => {
                const copy = {...this.state.uploadProgress};
                copy[file.name] = {state: "error", percentage: 0};
                this.setState({uploadProgress: copy});
                reject(req.response);
            });

            const formData = new FormData();
            formData.append("mode", this.props.mode);
            formData.append("sid", this.props.sessionId);
            formData.append("file", file, file.name);

            formData.append("width", width);
            formData.append("height", height);

            req.onreadystatechange = () => {
                if (req.readyState === 4) {

                    if (this.props.mode !== "ADMIN" && params.robot !== 1) {
                        this.selectUploadedImage(this.props.uploaderUrl + "/visitor/filename/" + this.props.sessionId + "/thumbs/" + JSON.parse(req.response)?.files?.[0], 0, JSON.parse(req.response)?.filesDimensions?.[0]);
                    }
                    // refreshUploadedImages without request
                    this.props.refreshUploadedImages({
                        mode: this.props.mode,
                        uploaderUrl: this.props.uploaderUrl,
                        sessionId: this.props.sessionId,
                        dataAfterImgAdded: req.response
                    });
                }
            }

            req.open("POST", this.props.uploaderUrl + "/upload/" + this.props.sessionId);
            req.send(formData);
        });
    }

    componentDidMount() {
        if (!this.props.showImageUploadForm) this.showUploadedFiles();
    }

    showUploadedFiles() {
        this.props.refreshUploadedImages({
            mode: this.props.mode,
            uploaderUrl: this.props.uploaderUrl,
            sessionId: this.props.sessionId,
        })
    }

    renderProgress(file) {
        const uploadProgress = this.state.uploadProgress[file.name];
        if (this.state.uploading || this.state.successfullUploaded) {
            return (
                <div className="ProgressWrapper">
                    <Progress progress={uploadProgress ? uploadProgress.percentage : 0}/>
                    <FontAwesomeIcon className="CheckIcon" icon="check-circle"
                                     style={{opacity: uploadProgress && uploadProgress.state === "done" ? 0.5 : 0}}/>
                </div>
            );
        }
    }

    onChange(e) {
        this.setState({file: e.target.files[0]});
    }

    render() {
        return (this.state.newImgUploader || this.props.isShowImageChangePopup) ? ((this.props.showImageUploadForm && this.state.newImgUploader) || this.props.isShowImageChangePopup ? (
                    <>
                        <div className="designer-tools-uploader">
                            <div className={stl.uploader}>
                                {this.props.pages[this.props.activePage ?? 0]?.multiface_elements_admin_only === 1 ?
                                    <div className="text-form-popup-content-title__wrap">
                                        <div className="text-form-popup-back"/>
                                        <div className="text-form-popup-content-title">
                                            <Translate content={"Change Image"}/>
                                        </div>
                                        <div className="text-form-popup-close"
                                             onClick={() => window.parent.postMessage({datatype: "hide_designer"}, "*")}>
                                            <img src={close_btn} alt=""/>
                                        </div>
                                    </div> : <div className="text-form-popup-content-title__wrap">
                                        {this.props.isShowImageChangePopup ? null :
                                            <>
                                                <div className="text-form-popup-back">
                                                </div>
                                                <div className="text-form-popup-content-title">
                                                    <Translate content={"Uploads"}/>
                                                </div>
                                                <div className="text-form-popup-close"
                                                     onClick={() => {
                                                         this.setState({
                                                             newImgUploader: false
                                                         })
                                                     }}>
                                                    <img src={close_btn} alt=""/>
                                                </div>
                                            </>

                                        }
                                    </div>
                                }
                            </div>

                            <div className="uploader-form-content">

                                <Dropzone
                                    multiface_elements_admin_only={this.props.pages[this.props.activePage].multiface_elements_admin_only}
                                    ref={this.dropzone}
                                    onFilesAdded={this.onFilesAdded.bind(this)}
                                    disabled={this.state.uploading && this.state.successfullUploaded}/>

                                <div className="Files" style={{padding: "0 5px"}}>
                                    {this.state.files.map(file => {
                                        return (
                                            <div key={file.name} className="Row">
                                                <span className="Filename">{file.name}</span>
                                                {this.renderProgress(file)}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            {this.props.pages[this.props.activePage].multiface_elements_admin_only === 1 ?
                                <div className={stl.uploader__title} style={{'paddingLeft': 0, 'paddingBottom': '5px'}}>
                                    <Translate content={"Uploads"}/>
                                </div> : null
                            }

                            <div className={stl.uploader__files} ref={this.imagesList}>

                                {this.props.uploadedImages.map((image, i) => {
                                    return (
                                        <img key={image.key}
                                             className={clsx([stl.uploader__img, this.props.pages[this.props.activePage].multiface_elements_admin_only === 1 ? stl.uploader__img_round : ""])}
                                             src={image.url}
                                             alt="alt" width="100"
                                             onClick={this.selectUploadedImage.bind(this, image.url, i, this.props.uploadedImages[i]?.filesDimensions)}/>
                                    );
                                })}
                            </div>
                            {
                                this.props.showImageUploadForm && !this.props.isShowImageChangePopup ? (
                                    <div className={stl.tools__btn}>
                                        <Button outline theme="secondary" size="sm" onClick={() => {
                                            this.setState({
                                                newImgUploader: false
                                            })
                                        }}>

                                            <Translate content={"Close"}/>
                                        </Button>
                                    </div>
                                ) : null
                            }
                        </div>
                    </>
                ) : null
            )
            : this.props.showImageUploadForm ?
                <div className={stl.imgMenu}>
                    {this.props.activeElement?.attrs?.type === "image" ? <>
                        <button onClick={() => {
                            this.props.showImageChangePopup();
                        }}>
                            <img src={change_img_icon} alt=""/>
                            <Translate content={"Change"}/>
                        </button>
                        <div className={clsx(this.state.elRotationOpened && stl.active)}>
                            <button onClick={() => {
                                this.setState({
                                    elRotationOpened: !this.state.elRotationOpened,
                                    elZoomOpened: false
                                })
                            }}>
                                <img src={rotate_img_icon} alt=""/>
                                <Translate content={"Rotate"}/>
                            </button>

                            <div className={stl.elRotationMenu}>
                                <img src={minus_img_icon} alt=""
                                     onClick={this.handleElemRotation.bind(this, -5)}/>
                                {this.props.activeElement.rotation() !== undefined ? Math.round(this.props.activeElement.rotation()) : 0}
                                <img src={plus_img_icon} alt=""
                                     onClick={this.handleElemRotation.bind(this, +5)}/>
                            </div>
                        </div>
                        <button onClick={() => {
                            this.props.showImageCropPopup()
                        }}>
                            <img src={cut_img_icon} alt=""/>

                            <Translate content={"Cut"}/>

                        </button>
                        <div className={clsx(this.state.elZoomOpened && stl.active)}>
                            <button onClick={() => {
                                this.setState({
                                    elZoomOpened: !this.state.elZoomOpened,
                                    elRotationOpened: false,
                                })
                            }}>
                                <img src={zoom_img_icon} alt=""/>
                                Zoom
                            </button>

                            <div className={stl.elZoomMenu}>

                                <img src={minus_img_icon} alt=""
                                     onMouseDown={this.handleSizeChange.bind(this, -1)}
                                     onMouseUp={this.clearInt(this.interval)}/>

                                {/*{this.props.activeElement.scaleX()}*/}

                                <img src={plus_img_icon} alt=""
                                     onMouseDown={this.handleSizeChange.bind(this, +1)}
                                     onMouseUp={this.clearInt(this.interval)}/>
                            </div>
                        </div>
                    </> : null}

                    {
                        this.props.toolImagesAllowed || this.props.mode === "ADMIN" ? <button onClick={() => {
                            this.setState({
                                newImgUploader: !this.state.newImgUploader
                            })
                        }}>
                            <img src={new_img_icon} alt=""/>
                            <Translate content={"Add_Image"}/>
                        </button> : null
                    }


                </div> : null
    }
}

const mapStateToProps = state => {
    return {
        isItemDownloading: state.glb.isItemDownloading,
        activePage: state.glb.activePage,
        uploadedImages: state.glb.uploadedImages,
        designerMode: state.glb.designerMode,
        sessionId: state.glb.sessionId,
        mode: state.glb.mode,
        showImageUploadForm: state.tol.showImageUploadForm,
        isShowImageChangePopup: state.tol.showImageChangePopup,
        activeElement: state.glb.activeElement,
        stage: state.glb.stage,
        pages: state.glb.pages,
        toolImagesAllowed: state.tol.toolImagesAllowed,
        selectedElements: state.glb.selectedElements,
    };
};

const mapDispatchToProps = dispatch => {
        return {
            hidePopup: () => {
                dispatch(actionCreators.hidePopup());
            },

            showImageChangePopup: () => dispatch(actionCreators.showImageChangePopup()),
            addElementToPage: (canvasElement) => dispatch(actionCreators.addElementToPage(canvasElement)),
            setItemDownloadingStatus: (data) => dispatch(actionCreators.setItemDownloadingStatus(data)),
            refreshUploadedImages: (params) => dispatch(actionCreators.refreshUploadedImages(params)),
            onEditImageElement: (data) => {
                dispatch(actionCreators.editElementAttrs(data));
                dispatch(actionCreators.hidePopup());
            },
            setUserAllowedProperties: (data) => {
                dispatch(actionCreators.userAllowedProperties(data));
            },
            showImageCropPopup: () => dispatch(actionCreators.showImageCropPopup()),
            onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
            hideToolsMenu: () => {
                document.querySelectorAll("body")[0].style.overflow = 'auto'
                dispatch(actionCreators.hideToolsMenu())
            },
            onEditElement: (data) => {
                dispatch(actionCreators.editElementAttrs(data));
            },
            toolsMenuVisibleToggle: (data) => dispatch(actionCreators.toolsMenuVisibleToggle(data)),
            showFaceCropPopup: () => dispatch(actionCreators.showFaceCropPopup()),
        };
    }
;

export default connect(mapStateToProps, mapDispatchToProps)(ImgUploader);
