import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../../../store/actions/index';

import stl from "./ToolsViews.module.scss";
import rectangle from "../../../assets/images/shape_1.png";
import line from "../../../assets/images/Line-18.png";
import circle from "../../../assets/images/circle.png";
import triangle from "../../../assets/images/triangle.png";

import colorText_icon from "../../../assets/images/colorFont_icon.svg";
import {DESKTOP_WIDTH} from "../../../config";
import {Button} from "shards-react";
import minus_img_icon from "../../../assets/images/faceCropMinus_icon.svg";
import plus_img_icon from "../../../assets/images/faceCropPlus_icon.svg";
import clsx from "clsx";
import zoom_img_icon from "../../../assets/images/Frame 135.png";

class Shapes extends Component {

    constructor(props) {
        super(props);

        this.clearInt = this.clearInt.bind(this);

        this.state = {
            elZoomOpened: false,
            elColorOpened: true
        }
    }


    addElementToPage(data) {
        this.props.addElementToPage({
            type: 'shape',
            data: data,
            activePage: this.props.activePage
        });

        setTimeout(() => {
            this.props.setActiveElement(this.props.stage.children[this.props.activePage].children[this.props.stage.children[this.props.activePage].children.length - 2].attrs.name)
        }, 50)

        if (window.innerWidth <= DESKTOP_WIDTH) {
            this.props.hideToolsMenu();
            this.props.toolsMenuVisibleToggle({mode: false});
        }
    }

    handleSizeChange(val) {
        if (this.props.activeElement) {
            this.interval = setInterval(() => {
                this.props.onEditElement({
                    scaleX: (this.props.activeElement.attrs.scaleX + val / 10) > 0.5 ? this.props.activeElement.attrs.scaleX + val / 10 : 0.5,
                    scaleY: (this.props.activeElement.attrs.scaleY + val / 10) > 0.5 ? this.props.activeElement.attrs.scaleY + val / 10 : 0.5,
                    element: this.props.activeElement.attrs.name,
                    activePage: this.props.activePage,
                })
            }, 50);
        }
    }

    clearInt(interval) {
        return clearInterval(interval);
    }

    handleColorChange(color) {
        this.props.onEditElement({
            color: "#" + color,
            element: this.props.activeElement.attrs.name,
            activePage: this.props.activePage
        });

    }

    render() {
        let activePage = this.props.pages ? this.props.pages[this.props.activePage] : false
        let colors = [];
        if (activePage) {
            for (let key in activePage.colors) {
                if (!activePage.colors.hasOwnProperty(key)) continue;
                let color = activePage.colors[key];
                colors.push(color.color_code);
            }
        }

        return (
            this.props.showShapesForm && !this.props.isCustomMaskEditing.status ?
                <div className="designer-tools-uploader">
                    <div className={stl.shapes}>
                        {(this.props.activeElement && (this.props.activeElement.attrs.type === "square" || this.props.activeElement.attrs.type === "circle" || this.props.activeElement.attrs.type === "triangle" || this.props.activeElement.attrs.type === "line")) ? <>
                            <div className={clsx(this.state.elZoomOpened && stl.active)}>
                                <button onClick={() => {
                                    this.setState({
                                        elZoomOpened: !this.state.elZoomOpened,
                                        elColorOpened: false,
                                    })
                                }}>
                                    <img src={zoom_img_icon} alt=""/>
                                    zoom
                                </button>

                                <div className={stl.elZoomMenu}>
                                    <img src={minus_img_icon} alt=""
                                         onMouseDown={this.handleSizeChange.bind(this, -1)}
                                         onMouseUp={this.clearInt(this.interval)}/>

                                    <img src={plus_img_icon} alt=""
                                         onMouseDown={this.handleSizeChange.bind(this, +1)}
                                         onMouseUp={this.clearInt(this.interval)}/>
                                </div>
                            </div>

                            <div className={clsx(this.state.elColorOpened && stl.activeColor)}>
                                <button onClick={() => {
                                    this.setState({
                                        elZoomOpened: false,
                                        elColorOpened: !this.state.elColorOpened,
                                    })
                                }}>
                                    <img src={colorText_icon} alt=""/>
                                    Color
                                </button>

                                <div className={stl.elColorMenu}>

                                    {colors.map((item) => {
                                        return (
                                            <div key={item}
                                                 onClick={this.handleColorChange.bind(this, item)}
                                                 className={stl.elColorMenu__btn}
                                                 style={{'backgroundColor': '#' + item}}>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        </> : null}
                        <br/>
                        <Button outline
                                theme="secondary"
                                onClick={this.addElementToPage.bind(this, "square")}>
                            <img src={rectangle} alt=""/>
                        </Button>
                        <Button outline
                                theme="secondary"
                                onClick={this.addElementToPage.bind(this, "circle")}>
                            <img src={circle} alt=""/>
                        </Button>
                        <Button outline
                                theme="secondary"
                                onClick={this.addElementToPage.bind(this, "triangle")}>
                            <img src={triangle} alt=""/>

                        </Button>
                        <Button outline
                                theme="secondary"
                                onClick={this.addElementToPage.bind(this, "line")}>
                            <img src={line} alt=""/>
                        </Button>
                    </div>
                </div>
                : null
        );
    }
}

const mapStateToProps = state => {
    return {
        activePage: state.glb.activePage,
        showShapesForm: state.tol.showShapesForm,
        stage: state.glb.stage,
        activeElement: state.glb.activeElement,
        pages: state.glb.pages,
        isCustomMaskEditing: state.glb.isCustomMaskEditing,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addElementToPage: (canvasElement) => dispatch(actionCreators.addElementToPage(canvasElement)),
        addTextToPage: (canvasElement) => {
            dispatch(actionCreators.addElementToPage(canvasElement));
            dispatch(actionCreators.hidePopup());
        },
        onEditElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data));
        },
        setActiveElement: (element) => dispatch(actionCreators.setActiveElement(element)),
        hideToolsMenu: () => dispatch(actionCreators.hideToolsMenu()),
        toolsMenuVisibleToggle: (data) => dispatch(actionCreators.toolsMenuVisibleToggle(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Shapes);
