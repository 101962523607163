import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import * as actionCreators from "../../../store/actions/index";
import clsx from "clsx";
import Translate from "react-translate-component";

import textIcon from '../../../assets/images/text.svg';

import {DEFAULT_FONTS, DESKTOP_WIDTH} from "../../../config";
import {Button} from "shards-react";

class PositionProperty extends Component {
    state = {
        fontTypeDropdown: false,
        fontFamilyList: ((Object.keys(this.props.pages[this.props.activePage].googleFonts).length > 0) ? this.props.pages[this.props.activePage].googleFonts : DEFAULT_FONTS),
        currentFontFamily: '',
        newFontFamily: ''
    };

    componentDidMount() {
        let finalFamilyList = ((Object.keys(this.props.pages[this.props.activePage].googleFonts).length > 0) ? this.props.pages[this.props.activePage].googleFonts : DEFAULT_FONTS);
        let fontFamilyActive = this.props.activeElement?.attrs?.fontFamily;
        let customFonts = this.props.pages[this.props.activePage].customFonts;
        if (Object.keys(this.props.pages[this.props.activePage].customFonts).length > 0) {
            for (let customFont in customFonts) {
                finalFamilyList[customFonts[customFont].font_family] = ['regular'];
            }
        }
        this.setState({
            fontFamilyList: finalFamilyList,
            currentFontFamily: fontFamilyActive,
        });
    }


    componentWillUnmount() {
        if (document.querySelectorAll(".properties-column")[0]) document.querySelectorAll(".properties-column")[0].classList.remove("active")
    }

    hanleChangeFontFamily(fontFamily) {


        this.props.pages[0].canvasElements.forEach((item) => {
            if ((item.elementId === this.props.activeElement.attrs.elementId
                && this.props.activeElement.attrs.elementId !== 0
                && this.props.activeElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.name)) {
                this.props.onEditTextElement({
                    fontFamily: fontFamily,
                    element: item.name,
                    activePage: this.props.activePage
                });
            }
        });

        // TODO: change (for mobile)
        if (window.innerWidth <= DESKTOP_WIDTH) {
            let name = this.props.activeElement.attrs.name;
            this.props.onSelectElement("");
            setTimeout(() => {
                this.props.onSelectElement(name);
            }, 0)
        }
    }

    handleChangeLocalFontFamily = (fontFamily) => {


        this.props.pages[0].canvasElements.forEach((item) => {
            if ((item.elementId === this.props.activeElement.attrs.elementId
                && this.props.activeElement.attrs.elementId !== 0
                && this.props.activeElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.name)) {
                this.props.onEditTextElement({
                    fontFamily: fontFamily === "WaltDisneyScriptv4.1" ? '"WaltDisneyScriptv4.1"' : fontFamily,
                    element: item.name,
                    activePage: this.props.activePage
                })
            }
        });

        this.setState({
            newFontFamily: fontFamily,
        });

        let oldTextWidth = this.props.activeElement.width();

        let selectedName = this.props.activeElement.attrs.name;
        this.props.onSelectElement("");

        setTimeout(() => {
            this.props.onSelectElement(selectedName);
            let newTextWidth = this.props.activeElement.width();

            switch (this.props.activeElement.attrs.align) {
                case "right":
                    this.props.activeElement.offsetX(newTextWidth);
                    break;
                case "center":
                    this.props.activeElement.offsetX(newTextWidth / 2);
                    break;
                default:
                    return
            }

            // this.props.onEditTextElement({
            //     x: this.props.activeElement.attrs.x - (newTextWidth - oldTextWidth) / 2,
            //     element: this.props.activeElement.attrs.name,
            //     activePage: this.props.activePage
            // });

        }, 50)


    }


    closeFontFamily = () => {
        const {currentFontFamily} = this.state;
        this.props.onEditTextElement({
            fontFamily: currentFontFamily,
            element: this.props.activeElement.attrs.name,
            activePage: this.props.activePage
        })
        if (window.innerWidth <= DESKTOP_WIDTH) {
            let name = this.props.activeElement.attrs.name;
            this.props.onSelectElement("");
            setTimeout(() => {
                this.props.onSelectElement(name);
            }, 0)
        }
    }

    saveFontFamily = () => {
        // const { newFontFamily } = this.state;
        if (window.innerWidth <= DESKTOP_WIDTH) {
            let name = this.props.activeElement.attrs.name;
            this.props.onSelectElement("");
            setTimeout(() => {
                this.props.onSelectElement(name);
            }, 0)
        }
    }

    render() {
        let fontFamilyActive = this.props.activeElement?.attrs.fontFamily;
        let fonts = [];
        for (let item in this.state.fontFamilyList) {
            fonts.push(item);
        }
        // || !this.props.propertyTextFontAllowed
        if ((this.props.activeElement?.attrs.quick_edit_font === false) && this.props.mode !== 'ADMIN') {
            return null
        }

        return (
            <div
                className={clsx("designer-right-panel-item", "designer-right-panel-item__column", "designer-right-panel-item__fontType", "active")}>
                {/*<div className={"designer-right-panel-item__titleFlex "}>*/}
                {/*    /!*<img src={textIcon} alt=""/>*!/*/}
                {/*    /!*<span className={"text"}>*!/*/}
                {/*    /!*    <Translate content={"Font type"}/>*!/*/}
                {/*    /!*</span>*!/*/}
                {/*    <div*/}
                {/*        style={{*/}
                {/*            fontFamily: fontFamilyActive + ', sans-serif',*/}
                {/*            fontWeight: this.props.activeElement?.attrs.fontStyle*/}
                {/*        }}*/}
                {/*        className={"selected-font"}>*/}
                {/*        {fontFamilyActive ?? ""}*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className={"dropdownContent font-family-selector"}>
                    {/*<div className="dropdownContentBtn">*/}
                    {/*    <Button outline theme="secondary" onClick={this.closeFontFamily}>*/}
                    {/*        <Translate content={"Cancel"}/>*/}
                    {/*    </Button>*/}
                    {/*    <Button theme="secondary" onClick={this.saveFontFamily}>*/}
                    {/*        OK*/}
                    {/*    </Button>*/}
                    {/*</div>*/}
                    <div className="dropdownContentList">
                        {
                            fonts.map((item, i) => {
                                return (
                                    <div
                                        style={{
                                            fontFamily: (item === "WaltDisneyScriptv4.1" ? '"WaltDisneyScriptv4.1"' : item) + ', sans-serif',
                                            fontWeight: 400
                                        }}
                                        className={((fontFamilyActive === item) ? 'active-font' : 'font')}
                                        key={i}
                                        onClick={() => this.handleChangeLocalFontFamily(item)}
                                    >
                                        {item}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        selectedElement: state.glb.selectedElement,
        stage: state.glb.stage,
        activePage: state.glb.activePage,
        pages: state.glb.pages,
        activeElement: state.glb.activeElement,
        mode: state.glb.mode,
        propertyTextFontAllowed: state.tol.propertyTextFontAllowed,
        selectedElements: state.glb.selectedElements,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
        onEditTextElement: (data) => dispatch(actionCreators.editElementAttrs(data))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PositionProperty);
