export default {
    "Revert": "Deshacer",
    "Face crop":"Recortar imagen",
    "Remove background":"Eliminar fondo",
    "Loading":"Cargando...",
    "settings": "Ajustes",
    "gridlines": "Pautas",
    "layers": "Niveles",
    "image": "Imagen",
    "Text": "Texto",
    "shape": "Forma",
    "emoji": "Emoji",
    "done": "Hecho",
    "Custom Text": "Texto",
    "Close": "Cerrar",
    "Position": "Posición",
    "Rotate": "Rotar",
    "Change Image": "Cambiar imagen",
    "Crop Image": "Recortar imagen",
    "Upload Images": "Descargar imagen",
    "Add Text": "Añadir texto",
    "Cancel": "Cancelar",
    "Start creating your masterpiece by browsing our new content in the panels on the left": "Cambia el diseño a tu gusto haciendo click sobre el contenido de la izquierda",
    "Shapes": "Formas configurables",
    "Add a line": "Añadir línea",
    "Add a rectangle": "Añadir rectángulo",
    "Add a circle": "Añadir cruz",
    "Add a triangle": "Añadir triángulo ",
    "Color": "Color",
    "Edit Text": "Modificar texto",
    "Font type": "Fuente",
    "Font Style": "Estilo",
    "Size": "Tamaño",
    "Justification": "Alineación",
    "Letter spacing": "Espacio entre letras",
    "Flip": "Dar la vuelta",
    "Save": "Guardar",
    "Preview": "Vista previa",
    "Editor": "Editor",
    "Uploads": "Imágenes descargadas",
    "Letter limit": "Máximo de caracteres",
    "Recently Used Colors": "Colores usados recientemente",
    "Drag and drop your images here!": "Arrastra y suelta tus imágenes aquí",
    "view": "Frontal",
    "Reset changes": "Volver atrás",
    "Delete": "Borrar",
    "Retry": "De nuevo",
    "Background not recognized":"Fondo no identificado",
    "Background already removed":"Fondo eliminado",
    "You placed an element outside the printable area.": "Hay un elemento fuera del área imprimible.",
    "There was error during getting files!": "¡Hubo un error al cargar el archivo!",
    "Your Image is too big to upload! The max file size is 8 MB": "Tu imagen es demasiado grande. El tamaño máximo del archivo es de 8 MB",
    "Your image is too small": "Tu imagen es demasiado pequeña. Por favor selecciona otra.",
    "Stroke": "Contorno",
    "Stroke color": "Color del contorno",
    "BackgroundsTools": "Fondo",
    "ColorsTools": "Color",
    "ImagesTools" : "Imagen",
    "Shadow color": "Color del sombreado",
    "Shadow": "Sombreado",
    "Information before ordering": "Antes de hacer clic comprueba por favor que todo está correcto y no hay errores. Una vez aceptado, todo se imprimirá tal y como aparece en la vista previa. Si tienes dudas, consulta antes por favor, nuestra sección de Preguntas Frecuentes (FAQ) - y Respuestas.",
    "Add to cart": "Añadir al carrito de la compra",
    "edit Image": "Modificar",
    "Add_Image": "Foto adicional",
    "Cut": "Recortar imagen",
    "Change": "Subir Foto",
    "More": "Más",
    "Edit": "Modificar texto",
    "Font": "Fuente",
    "Font Size": "Tamaño",
    "Letter Space": "Espacio",
    "Text Color": "Color",
    "New text": "Añadir texto",
    "Align": "Alineación",
    "Flip X": "Dar la vuelta",
    "Oops, didn't you forget something?": "¡Vaya!, ¿has olvidado algo?Parece que no has completado toda tu personalización, por favor revisa tu producto y reemplaza la imagen predefinida (excepto si te gusta tal y como aparece ahora).",
    "Use original image": "Usar imagen original",
    "Continue": "Continuar",
    "Wird freigestellt": "Eliminando el fondo...",
}









