import React, {Component} from 'react';
import {connect} from 'react-redux';
import queryString from 'query-string';
import './Designer.scss';
import clsx from "clsx";
import Menu from '../components/Menu/Menu';
import Tools from '../components/Tools/Tools';
import Canvas from '../components/Canvas/Canvas';
import loadingImage from '../assets/images/loading.gif';
import ProductPreview from '../components/ProductPreview/ProductPreview';
import PropertiesResolver from '../components/Properties/PropertiesResolver';
import * as actionCreators from '../store/actions/index';
import ImageCropPopup from "../components/Popups/ImageCropPopup";
import FaceCropPopup from "../components/Popups/FaceCropPopup";
import ImageChangePopup from "../components/Popups/ImageChangePopup";
import TextEditPopup from "../components/Popups/TextEditPopup";
import QuickEditLabelsPopup from "../components/Popups/QuickEditLabelsPopup";
import TextAddPopup from "../components/Popups/TextAddPopup";
// Translations/
import counterpart from "counterpart";
import en from "../components/Translations/en";
import de from "../components/Translations/de";
import dk from "../components/Translations/dk";
import fr from "../components/Translations/fr";
import it from "../components/Translations/it";
import nl from "../components/Translations/nl";
import es from "../components/Translations/es";

import {DESKTOP_WIDTH, MAX_SCALE, MAX_STAGE_SIZE, MIN_SCALE, MIN_STAGE_SIZE} from "../config";

/* Font awesome icons */
import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faAlignJustify,
    faAlignLeft,
    faAlignRight,
    faArrowDown,
    faArrowsAlt,
    faArrowUp,
    faBold,
    faCaretUp,
    faCheckCircle,
    faCircle,
    faCropAlt,
    faExpandArrowsAlt,
    faFileExport,
    faFill,
    faFont,
    faGripLines,
    faImage,
    faImages,
    faLayerGroup,
    faMinus,
    faPalette,
    faPencilAlt,
    faPlus,
    faQuestionCircle,
    faRedo,
    faRedoAlt,
    faShapes,
    faSlash,
    faSmile,
    faSquareFull,
    faTextHeight,
    faTint,
    faTrash,
    faTrashAlt,
    faUndo,
    faUndoAlt,
    faBorderStyle,
    faTimes
} from '@fortawesome/free-solid-svg-icons';
import PropertiesMaskResolver from "../components/Properties/PropertiesMaskResolver";
import stl from "../components/Canvas/Canvas.module.scss";
import OrderPreviewPopup from "../components/Popups/OrderPreviewPopup";
import BackgroundChangePopup from "../components/Popups/BackgroundChangePopup";
import TextChangePopup from "../components/Popups/TextChangePopup";


counterpart.registerTranslations('en', en);
counterpart.registerTranslations('dk', dk);
counterpart.registerTranslations('de', de);
counterpart.registerTranslations('nl', nl);
counterpart.registerTranslations('fr', fr);
counterpart.registerTranslations('it', it);
counterpart.registerTranslations('es', es);
counterpart.setLocale('de');

library.add(
    faTimes,
    faBold,
    faArrowsAlt,
    faTrashAlt,
    faCropAlt,
    faGripLines,
    faTint,
    faFill,
    faUndoAlt,
    faRedoAlt,
    faImage,
    faTextHeight,
    faPalette,
    faAlignJustify,
    faAlignRight,
    faPencilAlt,
    faUndo,
    faRedo,
    faArrowUp,
    faArrowDown,
    faAlignLeft,
    faImages,
    faFont,
    faSmile,
    faShapes,
    faFileExport,
    faQuestionCircle,
    faCheckCircle,
    faSquareFull,
    faCircle,
    faCaretUp,
    faSlash,
    faLayerGroup,
    faPlus,
    faTrash,
    faExpandArrowsAlt,
    faMinus,
    faBorderStyle
);

const params = queryString.parse(window.location.search);

class Designer extends Component {
    state = {
        magentoUrl: params.magento_url,
        previewUrl: params.preview_url,
        uploaderUrl: params.uploader_url,
        mode: 'PRODUCTION',
        mobile: false,
        showProductPreview: false,
        saving: false,
        lang: params.lang ? params.lang : "de",
        canvasScale: MIN_SCALE,
        STAGE_SIZE: MIN_STAGE_SIZE,
    };

    showMobilePreview() {

        this.props.onSelectElement('');
        setTimeout(() => {
            this.setState({
                showProductPreview: true
            });
            if (this.props.stage) {
                let stage = this.props.stage.clone();
                this.props.generatePreview({
                    pages: this.props.pages,
                    activePage: this.props.activePage,
                    stage: stage,
                    previewUrl: this.props.previewUrl
                });
                this.props.setPreviewUpdateStatus({previewShouldBeChanged: false})
            }
        }, 0)

    }

    closeMobilePreview() {
        this.setState({
            showProductPreview: false
        });
    }

    handleKeyUp = (e) => {
        e = e || window.event;
        let key = e.which || e.keyCode; // keyCode detection
        let ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17)); // ctrl detection

        if (key === 67 && ctrl && this.props.selectedElement && !this.props.showTextEditPopup && !this.props.showQuickEditLabelsPopup) {
            // ctrl + c
            this.props.setCopiedCanvasElement(this.props.activeElement);
        } else if (key === 86 && ctrl && this.props.getCopiedCanvasElement) {
            // ctrl + v
            this.props.addElementToPage(this.createCopyOfElement());
            this.props.onSelectElement('');
            this.props.setCopiedCanvasElement('');
        }
    };

    createCopyOfElement() {
        let
            oldElAttrs = this.props.getCopiedCanvasElement.attrs,
            newEl;

        if (oldElAttrs.type === "image" || oldElAttrs.type === "icon") {
            newEl = {
                ...oldElAttrs,
                imgSize: {width: oldElAttrs.width, height: oldElAttrs.height},
                data: oldElAttrs.image.src,
                copy: true
            };
        } else {
            newEl = {
                ...oldElAttrs,
                copy: true
            };
        }
        return newEl;
    }

    handleDeleteSelectedEl() {
        if (this.props.mode === "ADMIN" || this.props.activeElement?.attrs?.deletable) {
            this.props.deleteSelectedElement({
                element: this.props.selectedElement,
                activePage: this.props.activePage,
            });
            this.props.onSelectElement('');
        }
    }

    handleResize = () => {

        this.toggleMobileVersion();
        this.resetCanvasScale();

        if (window.innerWidth < DESKTOP_WIDTH && this.props.canvasScale !== MIN_SCALE) {
            this.resetCanvasScale();
        }
        // this.forceUpdate()
    };

    resetCanvasScale() {
        this.setState({
            canvasScale: MIN_SCALE,
            STAGE_SIZE: MIN_STAGE_SIZE,
        });
    }

    handleMessage = (e) => {
        if (e.data.action === 'updateCanvasElement') {
            let elementName = e.data.element;
            if (e.data.elementType === 'text') {
                this.props.onEditTextElement({
                    newText: 'adam',
                    element: elementName,
                    activePage: this.props.activePage
                })
            }
        } else if (e.data.action === 'showPreview') {
            window.parent.postMessage("save", "*");
            window.parent.postMessage({data: "image", images: this.props.previews}, "*");
        }
    };

    toggleMobileVersion() {
        this.setState({
            mobile: window.innerWidth <= DESKTOP_WIDTH
        });
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if (this.props.stage) {
    //         let stage = this.props.stage.clone();
    //         stage.attrs.scaleX = 1;
    //         stage.attrs.scaleY = 1;
    //         this.props.generatePreview({
    //             pages: this.props.pages,
    //             activePage: this.props.activePage,
    //             stage: stage,
    //             previewUrl: this.props.previewUrl
    //         });
    //     }
    // }

    componentDidMount() {
        counterpart.setLocale(this.state.lang);
        /* Init listeners */
        this.initListeners();
        /* Init designer */
        let mode = 'PRODUCTION';

        this.props.onDesignerInit({
            designId: params.id_design,
            layerId: params.id_template,
            magentoUrl: this.state.magentoUrl,
            uploaderUrl: this.state.uploaderUrl,
            previewUrl: this.state.previewUrl,
            mode: mode,
            sessionId: ((typeof params.id_session !== 'undefined') ? params.id_session : params.sid),
            productId: params.id_product,
            storeId: params.id_store,
            updateId: params.id_update,
            selectionId: params.selection_id,
            robot: parseInt(params.robot) ?? 0
        });

        const req = new XMLHttpRequest();
        req.onreadystatechange = () => {
            if (req.readyState === 4 && req.status === 200) {


                function IsJsonString(str) {
                    try {
                        JSON.parse(str);
                    } catch (e) {
                        return false;
                    }
                    return true;
                }

                if (req.responseText && IsJsonString(req.responseText) && JSON.parse(req.responseText).valid === 1) {
                    mode = ((typeof params.id_session !== 'undefined') ? 'PRODUCTION' : 'ADMIN');
                    this.setState({
                        mode: mode
                    });
                    this.props.onDesignerInit({
                        designId: params.id_design,
                        layerId: params.id_template,
                        magentoUrl: this.state.magentoUrl,
                        uploaderUrl: this.state.uploaderUrl,
                        previewUrl: this.state.previewUrl,
                        mode: mode,
                        sessionId: ((typeof params.id_session !== 'undefined') ? params.id_session : params.sid),
                        productId: params.id_product,
                        storeId: params.id_store,
                        updateId: params.id_update,
                        selectionId: params.selection_id,
                        robot: parseInt(params.robot) ?? 0
                    });
                } else {
                    // this.props.stage.children[this.props.activePage].children.map((item)=>{
                    //     if(item.attrs.user_adminAccessOnly){
                    //         // item.destroy();
                    //         console.log('deleted');
                    //         this.props.deleteSelectedElement({
                    //             element: item.attrs.name,
                    //             activePage: this.props.activePage,
                    //         });
                    //     }
                    // });
                    // this.props.setStageObject(this.props.stage)
                }
            }
        };

        let date = new Date();
        // req.open("PUT", 'https://www.radbag.de/productsdesigner/config/check?t=1644829462547');
        req.open("PUT", this.state.magentoUrl + 'productsdesigner/config/check?t=' + date.getTime());
        req.send(JSON.stringify({pass: params.admin_key}));
        this.toggleMobileVersion();
        window.addEventListener('message', this.receiveQuickChanges.bind(this));
    }


    receiveQuickChanges(event) {
        if (event.data.eventType === "IMAGE_LIST_CHANGED") {
            this.props.refreshUploadedImages({
                designId: params.id_design,
                layerId: params.id_template,
                magentoUrl: this.state.magentoUrl,
                uploaderUrl: this.state.uploaderUrl,
                previewUrl: this.state.previewUrl,
                mode: this.state.mode,
                sessionId: params.sid,
                productId: params.id_product,
                storeId: params.id_store,
                updateId: params.id_update
            });
        }
    }

    /* Init mouse and keyboard listeners */
    initListeners() {
        window.addEventListener('keyup', this.handleKeyUp);
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('message', this.handleMessage);
    }

    onSave() {
        this.setState({
            saving: !this.state.saving
        });
    }

    resetCanvasZoom() {
        this.setState({
            canvasScale: MIN_SCALE,
            STAGE_SIZE: MIN_STAGE_SIZE,
        });
    }

    onScaleChangeValue(val) {

        let
            newStageSize = this.state.STAGE_SIZE + (this.state.STAGE_SIZE / 100) * (val * 10),
            newCanvasScale = val * 10 + this.state.canvasScale;

        if (newCanvasScale <= MIN_SCALE) {
            newCanvasScale = MIN_SCALE;
            newStageSize = MIN_STAGE_SIZE;
        } else if (newCanvasScale >= MAX_SCALE) {
            newCanvasScale = MAX_SCALE;
            newStageSize = MAX_STAGE_SIZE;
        }
        // console.log(newCanvasScale)

        this.setState({
            canvasScale: newCanvasScale,
            STAGE_SIZE: newStageSize,
        });


        setTimeout(() => {
            let canvasWrap = document.getElementById('canvas').getBoundingClientRect()
            let canvas = document.querySelectorAll('.konvajs-content')[0].getBoundingClientRect()

            document.getElementById('canvas').scrollBy((canvas.width - canvasWrap.width) / 2 - document.getElementById('canvas').scrollLeft, (canvas.height - canvasWrap.height) / 2 - document.getElementById('canvas').scrollTop)
        }, 0)

    }

    render() {
        const {showTextPopup} = this.props;
        const {mobile} = this.state;
        return (
            <div className="designer-container">
                {(this.props.pages[this.props.activePage]?.multiface_elements_admin_only === 1 && this.props.mode !== "ADMIN" && parseInt(params.robot) !== 1) ? <>
                    <div className={"faceCropMultifaceOverlay"}>
                        <img key="...loading" src={loadingImage} alt={'...loading'}/>
                    </div>
                </> : null}

                <Menu canvasScale={this.state.canvasScale}
                      resetCanvasZoom={this.resetCanvasZoom.bind(this)}
                      showMobilePreview={this.showMobilePreview.bind(this)}
                      onSave={this.onSave.bind(this)}
                      onScaleChangeValue={this.onScaleChangeValue.bind(this)}/>

                <div className="designer-content">
                    <div className={clsx("designer-block", this.props.mode === "ADMIN" && 'designer-admin-mode')}>
                        {
                            this.props.pages.length > 0 && (!this.state.showProductPreview || !this.state.mobile) ?
                                <Tools uploaderUrl={this.state.uploaderUrl}
                                       deleteSelected={this.handleDeleteSelectedEl.bind(this)}/> : null
                        }
                        <Canvas canvasScale={this.state.canvasScale} STAGE_SIZE={this.state.STAGE_SIZE}
                                saving={this.state.saving}
                                onSave={this.onSave.bind(this)}/>
                    </div>
                    <QuickEditLabelsPopup/>
                    <TextEditPopup/>
                    <TextAddPopup/>
                    {this.props.isShowImageCropPopup ? <ImageCropPopup/> : null}
                    {this.props.isShowFaceCropPopup ? <FaceCropPopup/> : null}
                    {this.props.isShowBackgroundChangePopup ? <BackgroundChangePopup/> : null}
                    {this.props.isShowTextChangePopup ? <TextChangePopup/> : null}
                    {this.props.isShowOrderPreviewPopup ? <OrderPreviewPopup /> : null}
                    {this.props.isShowImageChangePopup ?
                        <ImageChangePopup uploaderUrl={this.state.uploaderUrl}/> : null}
                    {this.props.showImageUploadForm ?
                        <ImageChangePopup uploaderUrl={this.state.uploaderUrl}/> : null}
                    {/*this.state.mobile &&*/}
                    {this.state.showProductPreview && !this.props.isCustomMaskEditing.status && (this.props.pages[this.props.activePage].multiface_elements_admin_only !== 1 || this.props.mode === "ADMIN") ?
                        <ProductPreview closeMobilePreview={this.closeMobilePreview.bind(this)}
                                        showProductPreview={this.state.showProductPreview}/> : null}
                    {/**/}


                    {(this.props.pages.length > 0 && !(showTextPopup && mobile) && !(this.props.showBackgroundsForm && mobile) && !this.props.isCustomMaskEditing.status && !this.props.isBorderLimitEditing.status && (this.props.pages[this.props.activePage].multiface_elements_admin_only !== 1 || this.props.mode === "ADMIN")) && this.props.mode === "ADMIN" ? (
                        <div className="properties-column adminPanel">
                            {((this.props.selectedElement && this.props.selectedElement.length > 0) || !this.state.mobile && !this.props.isShowOrderPreviewPopup) ?
                                <PropertiesResolver/> : null}
                        </div>
                    ) : null}

                    {this.props.isCustomMaskEditing.status || this.props.isBorderLimitEditing.status ?
                        <div className="properties-column adminPanel">
                            <div
                                className={"designer-right-panel"}>
                                <PropertiesMaskResolver/>
                            </div>
                        </div> : null}


                </div>
            </div>
        )
            ;
    }
}

const mapStateToProps = state => {
    return {
        isBorderLimitEditing: state.glb.isBorderLimitEditing,
        showBackgroundsForm: state.tol.showBackgroundsForm,
        showTextPopup: state.tol.showTextPopup,
        showTextEditPopup: state.tol.showTextEditPopup,
        isShowImageCropPopup: state.tol.showImageCropPopup,
        isShowFaceCropPopup: state.tol.showFaceCropPopup,
        isShowBackgroundChangePopup: state.tol.showBackgroundChangePopup,
        isShowTextChangePopup: state.tol.showTextChangePopup,
        isShowOrderPreviewPopup: state.tol.showOrderPreviewPopup,
        isShowImageChangePopup: state.tol.showImageChangePopup,
        pages: state.glb.pages,
        uploadedImages: state.glb.uploadedImages,
        mode: state.glb.mode,
        isCustomMaskEditing: state.glb.isCustomMaskEditing,
        activePage: state.glb.activePage,
        canvasElements: state.cnv.canvasElements,
        getCopiedCanvasElement: state.cnv.copiedCanvasElement,
        activeCanvasElement: state.cnv.activeCanvasElement,
        stage: state.glb.stage,
        selectedElement: state.glb.selectedElement,
        renderers: state.prv.renderers,
        designId: state.glb.designId,
        previewUrl: state.glb.previewUrl,
        sessionId: state.glb.sessionId,
        productId: state.glb.productId,
        layerId: state.glb.layerId,
        previews: state.prv.previews,
        showImageUploadForm: state.tol.showImageUploadForm,
        activeElement: state.glb.activeElement,
        showQuickEditLabelsPopup: state.tol.showQuickEditLabelsPopup,
        showTextForm: state.tol.showTextForm,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setStageObject: (stage) => dispatch(actionCreators.setStage(stage)),
        refreshUploadedImages: (params) => dispatch(actionCreators.refreshUploadedImages(params)),
        onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
        deleteSelectedElement: (data) => dispatch(actionCreators.deleteSelectedElement(data)),
        onDesignerInit: (initParams) => dispatch(actionCreators.initDesigner(initParams)),
        onSaveLayer: (data) => dispatch(actionCreators.saveConfiguration(data)),
        onEditTextElement: (data) => dispatch(actionCreators.editElementAttrs(data)),
        addElementToPage: (canvasElement) => dispatch(actionCreators.addElementToPage(canvasElement)),
        setCopiedCanvasElement: (element) => {
            dispatch(actionCreators.copiedCanvasElement(element));
        },
        generatePreview: (previewData) => dispatch(actionCreators.generatePreview(previewData)),
        setPreviewUpdateStatus: (data) => {
            dispatch(actionCreators.setPreviewUpdateStatus(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Designer);
